html {
    font-family: 'Source Sans Pro,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif';
    -webkit-font-smoothing: antialiased;
}

div {
    display: block;
}

input {
    text-rendering: auto;
    color: initial;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    -webkit-appearance: textfield;
    background-color: white;
    -webkit-rtl-ordering: logical;
    cursor: text;
    margin: 0em;
    font: 400 11px system-ui;
    padding: 1px;
    border-width: 2px;
    border-style: inset;
    border-color: initial;
    border-image: initial;
}

.main {
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    align-content: stretch;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.chatsList {
    box-sizing: border-box;
    height: 100%;
    min-width: 320px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 25%;
    border-left: none;
}

.block {
    width: 100%;
    height: 100%;
    display: block;
}

.main_block {
    width: 100%;
    display: block;
}

.msg_body {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    background-color: rgb(255, 255, 255);
    border-color: rgb(221, 226, 230);
}

.list {
    position: relative;
    height: calc(100% - 52px);
    z-index: 0;
}

.list_container {
    box-sizing: border-box;
    max-height: 100%;
    margin: 0px;
    padding: 10px;
}

.list_body {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    background-color: rgb(243, 247, 249);
    border-color: rgb(203, 212, 222);
}

.list_header {
    height: 52px;
}

.list_item {
    position: relative;
}

.list_item a {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    color: rgb(66, 77, 87);
    padding: 14px;
    text-decoration: none;
    transition: color 0.2s ease-in-out 0s;
}

.list_item a:hover {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    color: #4384f5;
    padding: 14px;
    text-decoration: none;
    transition: color 0.2s ease-in-out 0s;
}

.list_app_icon {
    margin-right: 12px;
    color: rgb(67, 132, 245);
    display: inline-block;
    transform: translate(0, 0);
    text-rendering: auto;
    font-size: inherit;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.list_main {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 4px;
    cursor: pointer;
    transform: rotate(-90deg);
    padding: 10px;
    transition: transform 0.2s ease-in-out 0s;
}

.list_item_view {
    display: block;
    -webkit-box-align: center;
    align-items: center;
    width: 12px;
    height: 8px;
    line-height: 8px;
}

.header_body {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    height: 52px;
    position: relative;
    z-index: 2;
    text-align: center;
    box-sizing: border-box;
    background-color: rgb(243, 247, 249);
    border-bottom: 1px solid rgb(203, 212, 222);
}

.absolute_header_text {
    position: absolute;
    left: 0px;
    max-width: 100%;
    height: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 1;
}

.absolute_header_text_red {
    position: absolute;
    background-color: red;
    left: 0px;
    max-width: 100%;
    height: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 1;
}

.header_text_red {
    font-size: 18px;
    color: rgb(241, 243, 245);
    justify-content: center;
    font-weight: bold;
    background-color: red;
    height: 52px;
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0px 16px;
}

.header_text {
    font-size: 18px;
    color: rgb(66, 77, 87);
    height: 52px;
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    max-width: 100%;
    box-sizing: border-box;
    padding: 0px 16px;
}

.absolute_header_button {
    position: absolute;
    right: 0px;
    height: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 1;
}

.absolute_button {
    padding-right: 16px;
}

.absolute_button button {
    font-size: 14px;
    padding: 0 16px;
    min-width: 32px;
    min-height: 32px;
    line-height: 32px;
    background-color: #4384f5;
    color: #fff;
    border-color: #4384f5;
    border-radius: 4px;
}

.msg_header {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    height: 52px;
    position: relative;
    z-index: 2;
    text-align: center;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid rgb(221, 226, 230);
}

.chats {
    box-sizing: border-box;
    height: 100%;
    min-width: 0px;
    -webkit-box-flex: 2;
    flex-grow: 2;
    flex-shrink: 1;
    flex-basis: 50%;
    border-left: 1px solid rgb(203, 212, 222);
}

.main_body {
    box-sizing: border-box;
    height: 100%;
    min-width: 0px;
    -webkit-box-flex: 3;
    flex-grow: 3;
    flex-shrink: 1;
    flex-basis: 75%;
    z-index: 1;
    border-left: 1px solid rgb(203, 212, 222);
}

.content_body {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.detail_view {
    width: 100%;
    height: 700px;
    overflow: scroll;
}

.detail_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
}


.main_detail_view {
    display: flex;
    min-height: 31px;
    border-bottom: 1px solid rgb(221, 226, 230);
}

.detail_count {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 14px;
    color: rgba(66, 77, 87, 0.8);
    flex: 1 1 0%;
    padding: 0px 10px;
}

.detail_content_body {
    display: flex;
    width: 100%;
    flex: 1 1 0%;
    overflow: hidden;
}

.detail_content_view {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.detail_header {
    border-collapse: separate;
    width: 100%;
    font-size: 15px;
    color: rgb(66, 77, 87);
    table-layout: auto;
    display: table-header-group;
}

.detail_header table {
    border-collapse: separate;
    width: 100%;
    font-size: 15px;
    color: rgb(66, 77, 87);
    table-layout: auto;
}

.detail_header table thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}

.detail_header table thead tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}


.detail_header table thead tr td {
    border-bottom: 1px solid rgb(221, 226, 230);
    position: relative;
    text-align: left;
    vertical-align: middle;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 14px;
    font-weight: normal;
    color: rgba(66, 77, 87, 0.6);
    width: 200px;
    padding: 10px;
}

.detail_body {
    flex: 1 1 0%;
    overflow: auto;
}

.detail_body table {
    border-collapse: separate;
    width: 100%;
    font-size: 15px;
    color: rgb(66, 77, 87);
    table-layout: auto;
}

.detail_body table tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}

.detail_body table tbody tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    transition: background-color 0.25s ease 0s;
}

.detail_body table tbody tr td {
    border-bottom: 1px solid rgb(221, 226, 230);
    font-family: 'Source Sans Pro', 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
    position: relative;
    text-align: left;
    vertical-align: middle;
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
    color: rgb(66, 77, 87);
    width: 200px;
    padding: 10px;
}

.name_content {
    cursor: pointer;
    line-height: 1.33;
    vertical-align: middle;
    word-break: break-word;
    overflow-wrap: break-word;
    width: 200px;
    border-bottom: 1px solid rgb(221, 226, 230);
    padding: 8px 10px;
}

.name_content_list {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.image_content {
    margin-right: 8px;
}

.image_view {
    position: relative;
    font-weight: normal;
    text-align: center;
    box-sizing: border-box;
    opacity: 1;
    font-size: 13px;
    line-height: 30px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    transition: background-color 0.5s ease 0s;
}

.image_view span {
    display: block;
    color: rgb(255, 255, 255);
    overflow: hidden;
    border-radius: 50%;
    background: rgb(103, 172, 162);
}

.name_data_list {
    display: flex;
    flex-direction: column;
}

.name_data_list p {
    display: inline-block;
    max-width: 160px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-wrap: normal;
    overflow: hidden;
}

.subject_content {
    cursor: pointer;
    vertical-align: middle;
    border-bottom: 1px solid rgb(221, 226, 230);
    padding: 8px 10px;
    width: 1px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-wrap: normal;
    flex: 1 1 0%;
    font-size: 16px;
}

.one {
    height: 20px;
    margin: -20px 0px 0px 178px;
}

.assign_list_view {
    position: absolute;
    will-change: transform;
    top: 0px;
    left: 0px;
    transform: translate3d(575px, 240px, 0px);
    display: block;
    z-index: 2100;
}

.main_assign_list {
    min-width: 340px;
    color: rgb(33, 37, 41);
    background-clip: padding-box;
    font-weight: 400;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 2px 5px 20px 0px;
    position: relative;
    width: 300px;
    border-radius: 4px;
    padding: 10px 11px;
}

.main_assign_list ul {
    max-height: 224px;
    margin: 0;
    padding: 0;
    overflow-y: auto;
}

.main_assign_list ul li {
    position: relative;
    list-style: none;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    padding: 8px 10px;
    -webkit-box-align: center;
    align-items: center;
}

.main_assign_list ul li:hover {
    background-color: #e1e9ec;
}

.main_assign_view {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100% - 20px);
    width: 100%;
}

.main_assign_inner_view {
    width: 100%;
    text-overflow: ellipsis;
    -webkit-box-align: center;
    align-items: center;
    white-space: nowrap;
    display: flex;
    line-height: 1.3;
    vertical-align: middle;
    overflow: hidden;
}

.main_assign_inner_view img {
    width: 30px;
    height: 30px;
    margin-right: 6px;
    border-radius: 50%;
}

.main_assign_content {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 30px);
    overflow: hidden;
    flex-direction: column;
    margin-left: 5px;
}

.name_text {
    color: #424d59;
    font-size: 15px;
}

.email_text {
    color: #424d59;
    font-size: 13px;
    opacity: 0.5;
}

.assigned_content {
    cursor: pointer;
    vertical-align: middle;
    border-bottom: 1px solid rgb(221, 226, 230);
    padding: 8px 10px;
    width: 1px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-wrap: normal;
    flex: 1 1 0%;
    font-size: 16px;
}

.status_content {
    cursor: pointer;
    line-height: 1.33;
    vertical-align: middle;
    word-break: break-word;
    overflow-wrap: break-word;
    width: 70px;
    padding: 8px 10px;
}

.status_highlight_open {
    color: #4384f5;
}

.status_style_open {
    display: inline-block;
    height: 21px;
    width: fit-content;
    line-height: 20px;
    color: rgb(255, 255, 255);
    background-color: rgb(67, 132, 245);
    font-size: 13px;
    vertical-align: middle;
    border-radius: 20px;
    padding: 0px 7px;
}

.status_highlight_close {
    color: rgb(44, 176, 106);
}

.status_style_close {
    display: inline-block;
    height: 21px;
    width: fit-content;
    line-height: 20px;
    color: rgb(255, 255, 255);
    background-color: rgb(44, 176, 106);
    font-size: 13px;
    vertical-align: middle;
    border-radius: 20px;
    padding: 0px 7px;
}

.content {
    display: block;
}

.content h2 {
    color: #424d57;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: 1px solid #dde2e6;
    display: flex;
    align-items: flex-end;
}

.intro {
    color: rgba(66, 77, 87, 0.8);
    margin-bottom: 20px;
}

.form {
    margin-bottom: 30px;
    font-size: 100%;
    vertical-align: baseline;
    margin: 0px;
    padding: 0px;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
}

.form input {
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    width: 160px;
    font-size: 14px;
    margin-right: 3px;
    line-height: 17px;
    color: rgba(66, 77, 87, 0.8);
    box-sizing: border-box;
    padding: 5px 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(221, 226, 230);
    border-image: initial;
    border-radius: 4px;
    outline: none;
}

.form input:focus {
    border-color: rgb(27, 141, 241);
}

.form input:active {
    border-color: rgb(27, 141, 241);
}

.form textarea {
    float: left;
    width: 460px;
    height: 81px;
    max-width: 570px;
    min-width: 460px;
    min-height: 81px;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 15px;
    line-height: 1.5;
    color: rgba(66, 77, 87, 0.8);
    border: 1px solid #dde2e6;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
}

.form button {
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    position: relative;
    display: inline-block;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    padding: 0px;
    margin-left: 10px;
    vertical-align: top;
    color: rgb(255, 255, 255);
    border-radius: 3px;
    background-color: rgb(67, 132, 245);
    border-radius: 4px;
}

.form button span {
    line-height: 28px;
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    font-size: 12px;
    letter-spacing: 0.02em;
    padding: 0px 30px;
}

.tag_list {
    display: block;
    margin: 15px;
    padding: 0px;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
}

.tag_list_header {
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    position: relative;
    width: 100%;
    font-size: 14px;
}

.view {
    height: 20px;
    padding: 10px 20px 10px 20px;
}

.text {
    float: left;
    width: 200px;
}

.text span {
    color: #fff;
    font-size: 12px;
    margin-right: 3px;
    line-height: 20px;
    text-shadow: none;
    padding: 0px 10px 0px 10px;
    background: #ff9800;
    word-break: break-all;
    display: inline-flex;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.count {
    width: 110px;
    display: inline-block;
    color: rgb(66, 77, 87);
}

.tag {
    position: relative;
    width: 100%;
    border-top: 1px solid #dde2e6;
}

.tag_view {
    padding: 20px;
    display: table;
    width: initial;
}

.field {
    float: left;
    margin: 0 24px 24px 0;
}

.submit {
    float: left;
    margin: 0;
}

.submit button {
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
    position: relative;
    display: inline-block;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    border: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 4px;
    background-color: #4384f5;
}

.submit button span {
    display: block;
    padding: 12px 33px 12px 33px;
    font-size: 15px;
    line-height: 17px;
    color: #ffffff;
    letter-spacing: 0.2px;
}

.field input {
    width: 460px;
    padding: 9px 8px;
    font-size: 15px;
    line-height: 21px;
    color: rgba(66, 77, 87, 0.8);
    border: 1px solid #dde2e6;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
}

.field p {
    margin-top: 8px;
    line-height: 17px;
    font-size: 15px;
    color: rgba(66, 77, 87, 0.8);
}

.response {
    clear: both;
}

.response_list {
    min-width: 600px;
}

.response_item {
    position: relative;
    width: 100%;
    border-top: 1px solid #ddd;
    margin-bottom: 20px;
}

.response_item .view {
    padding: 20px;
    line-height: 20px;
    display: inline-block;
    flex-direction: row;
}

.response_item .view .text {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
    color: rgba(66, 77, 87, 0.8);
    word-wrap: break-word;
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
}

.response_item .view .tags {
    font-size: 13px;
    margin-top: 20px;
    margin-right: 270px;
}

.response_item .view .tags span {
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
    position: relative;
    margin-right: 5px;
    font-size: 13px;
    color: rgba(66, 77, 87, 0.8);
    font-variant: small-caps;
}

.response_item .view .tags .shortcut {
    position: relative;
    width: auto;
    max-width: 100%;
    overflow-wrap: break-word;
    padding: 3px 7px;
    margin: 2px;
    font-size: 15px;
    color: #424d57;
    line-height: 21px;
    border: 1px solid #d9dadb;
    border-radius: 4px;
    background-color: #ffffff;
    text-shadow: none;
}

.response_item .view .tags .shortcut span {
    font-size: 18px;
    line-height: 21px;
    color: #4384f5;
}

/* Add Ticket */

.add_content {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.content_block {
    width: 100%;
    height: 100%;
    display: block;
}

.add_content_body {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    background-color: rgb(243, 247, 249);
    border-color: rgb(221, 226, 230);
}

.add_content_header {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    height: 52px;
    position: relative;
    z-index: 2;
    text-align: center;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid rgb(221, 226, 230);
}

.absolute_back_button {
    position: absolute;
    left: 0px;
    max-width: 100%;
    height: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 1;
}

.absolute_back_button a {
    font-size: 16px;
    cursor: pointer;
    height: 100%;
    color: rgb(66, 77, 87);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    margin-left: 17px;
}

.back_button {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 16px;
    height: 16px;
    line-height: 16px;
}

.absolute_back_button a span {
    display: inline-block;
    font-size: 14px;
    margin-left: 9px;
}

.absolute_header {
    font-size: 18px;
    font-weight: 400;
    color: rgb(66, 77, 87);
    height: 52px;
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    position: absolute;
    box-sizing: border-box;
    width: calc(100% - 160px);
    left: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0px 80px;
}

/* Ticket body */

.add_body {
    position: relative;
    height: calc(100% - 52px);
    z-index: 0;
}

.main_add_body {
    height: 100%;
    overflow: auto;
}

.form_body {
    width: 500px;
    margin: 50px auto 170px;
}

.form_body span {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #d64646;
    display: block;
}

.lc_text_field {
    margin-bottom: 20px;
}

.lc_field_label {
    font-family: 'Source Sans Pro,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif';
    font-size: 16px;
    color: #424d59;
    display: block;
    line-height: 20px;
    margin-bottom: 4px;
}

.lc_text_field input {
    width: 100%;
    border: 1px solid #bcc6d0;
    border-radius: 4px;
    line-height: 20px;
    padding: 8px 10px;
    font-size: 15px;
    color: #424d59;
    box-sizing: border-box;
}

.lc_text_field input:focus {
    border-color: #d64646;
}

.lc_text_field span {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: #d64646;
    display: block;
    margin: 2px 0 0;
}

.lc_text_field span {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: #d64646;
    display: block;
    margin: 2px 0 0;
}

.row {
    display: flex;
    margin-bottom: 20px;
}

.lc_req_field {
    margin-right: 10px;
    flex: 1 1 0%;
}

.lc_req_field input {
    width: 100%;
    border: 1px solid #bcc6d0;
    border-radius: 4px;
    line-height: 20px;
    padding: 8px 10px;
    font-size: 15px;
    color: #424d59;
    box-sizing: border-box;
    -webkit-transition: border .25s ease-in-out;
    transition: border .25s ease-in-out;
    will-change: border-color;

}

.lc_em_field {
    margin-top: 24px;
    flex: 1 1 0%;
}

.lc_em_field input {
    width: 100%;
    border: 1px solid #bcc6d0;
    border-radius: 4px;
    line-height: 20px;
    padding: 8px 10px;
    font-size: 15px;
    color: #424d59;
    box-sizing: border-box;
    -webkit-transition: border .25s ease-in-out;
    transition: border .25s ease-in-out;
    will-change: border-color;
}

.lc_assign_field {
    margin-bottom: 30px;
}

.lc_assign_label {
    display: flex;
    flex-direction: row;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
    color: rgb(66, 77, 89);
}

.lc_assign_req_field {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(66, 77, 89);
    font-size: 14px;
    cursor: pointer;
    padding: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(188, 198, 208);
    border-image: initial;
    border-radius: 4px;
    background: rgb(255, 255, 255);
}

.lc_assign_req_img {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.lc_assign_req_img img {
    display: block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
}

.lc_assign_req_search {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    color: rgb(66, 77, 87);
    line-height: 20px;
    min-height: 40px;
    flex: 1 1 0%;
    margin: 0px 10px;
}

.lc_assign_req_search span {
    white-space: pre-wrap;
    word-break: break-word;
    color: rgb(66, 77, 87);
    line-height: 20px;
    font-size: 14px;
}

.lc_msg_field {
    position: relative;
    background: rgb(255, 255, 255);
    border-radius: 8px;
}

.lc_msg_row_field {
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(188, 198, 208);
    border-image: initial;
    border-radius: 8px;
    transition: background-color 0.2s ease 0s;
}

.lc_msg_field_input {
    padding: 10px 12px 4px;
}

.lc_msg_field_input area {
    color: rgb(66, 77, 87);
    font-size: 15px;
    line-height: 22px;
    resize: none;
    width: 100%;
    white-space: pre-wrap;
    background: transparent;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    margin: 0px;
    outline: none;
    padding: 0px;
}

.lc_msg_field_tag {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.lc_msg_field_tag_view {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    align-self: flex-end;
}

.lc_msg_field_button_view {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    margin-right: 8px;
    margin-bottom: 8px;
}

.lc_msg_field_button_view button {
    height: 32px;
    line-height: 32px;
    min-height: 32px;
    background-color: #4384f5;
    color: #fff;
    border-color: #4384f5;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;
    -webkit-transition-property: opacity, border, color, background-color, box-shadow;
    transition-property: opacity, border, color, background-color, box-shadow;
    -webkit-transition-duration: .2s;
    transition-duration: .2s;
    -webkit-transition-timing-function: cubic-bezier(.64, 0, .35, 1);
    transition-timing-function: cubic-bezier(.64, 0, .35, 1);
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 600;
    display: -webkit-inline-box;
    display: inline-flex;
    font-size: 15px;
    padding: 0 16px;
}

.update_status_view {
    font-size: 14px;
    margin-right: 8px;
}

.update_status_view span {
    margin-right: 8px;
    color: rgba(66, 77, 87, 0.8);
}

.update_status_view button {
    height: 32px;
    line-height: 32px;
    min-height: 32px;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;
    -webkit-transition-property: opacity, border, color, background-color, box-shadow;
    transition-property: opacity, border, color, background-color, box-shadow;
    -webkit-transition-duration: .2s;
    transition-duration: .2s;
    -webkit-transition-timing-function: cubic-bezier(.64, 0, .35, 1);
    transition-timing-function: cubic-bezier(.64, 0, .35, 1);
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 600;
    display: -webkit-inline-box;
    display: inline-flex;
    background-color: #fff;
    color: #424d59;
    border: 1px solid #bcc6d0;
    font-size: 15px;
    padding: 0 16px;
    min-width: 36px;
}

.status_list {
    position: absolute;
    z-index: 20;
    will-change: transform;
    top: 50%;
    right: 17%;
    transform: translate3d(150px, -76px, 0px);
    background-color: #fff;
    box-shadow: 0 1px 10px 0 rgba(66, 77, 87, .3);
    border-radius: 4px;
    min-width: 168px;
    max-width: 336px;
}

.list_view {
    display: block;
    -webkit-box-align: center;
    align-items: center;
    width: 12px;
    height: 12px;
    line-height: 12px;
    transform: rotate(90deg);
    margin: 2px 0px 0px 8px;
}

.status_list_ul {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 500px;
}

.status_list_ul li {
    list-style: none;
    padding: 10px;
    cursor: pointer;
    line-height: 1.3;
    font-size: 14px;
    color: #424d59;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
}

.status_list_ul li:hover {
    list-style: none;
    padding: 10px;
    cursor: pointer;
    line-height: 1.3;
    font-size: 14px;
    background-color: #e1e9ec;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
}


.status_list_li {
    max-width: calc(100% - 20px);
    display: -webkit-box;
    display: flex;
}

.status_list_li_content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
}

.canned_responses {
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    display: inline-flex;
    height: 16px;
    -webkit-box-pack: center;
    justify-content: center;
    margin-left: 10px;
    margin-bottom: 10px;
    width: 16px;
}

.canned_responses_icon {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 18px;
    height: 18px;
    line-height: 18px;
}

.canned_responses input {
    display: none;
    -webkit-box-align: center;
    align-items: center;
    width: 18px;
    height: 18px;
    line-height: 18px;
}

.file_text {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin: 6px;
    color: rgb(66, 77, 87);
    font-size: 14px;
    border-radius: 10px;
    background: rgb(243, 247, 249);
}

.file_text_span {
    flex: 1 1 0%;
    padding: 7px;
}

.remove_span {
    cursor: pointer;
    margin-left: auto;
    padding: 7px;
}


@media (max-width: 705px) {
    .css-1m3pr0w {
        border-left: none;
        border-right: none;
        border-radius: 0px;
        margin: 0px;
    }

    .lc_msg_field_input {
        padding: 4px;
    }

    .css-lc_msg_field_tag_view {
        align-self: center;
    }

    .canned_responses {
        height: 24px;
        width: 24px;
    }
}

/* Update Body */

.update_content_body {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    background-color: rgb(255, 255, 255);
    border-color: rgb(221, 226, 230);
}

.main_update_body {
    display: flex;
    flex-direction: column;
    height: 74%;
    overflow: auto;
}

.main_sendbox_body {
    margin: 10px 10px;
}

.box_main_view {
    position: relative;
    background: rgb(255, 255, 255);
    border-radius: 8px;
}

.box_view {
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(188, 198, 208);
    border-image: initial;
    border-radius: 8px;
    transition: background-color 0.2s ease 0s;
}

.update_body {
    width: 100%;
    overflow: auto;
}

.update_inner_body {
    max-width: 800px;
    margin: 0px auto;
}

.update_absolute_body {
    margin: 40px 60px;
}

.update_bottom_body {
    margin: 40px 60px;
}

.update_message_body_right {
    font-size: 15px;
    line-height: 1.33;
    overflow-wrap: break-word;
    background: rgb(0, 122, 255);
    color: rgb(255, 255, 255);
    border-radius: 8px;
    margin-top: 10px;
    padding: 12px;
    overflow: auto;
}

.update_message_body_left {
    font-size: 15px;
    line-height: 1.33;
    overflow-wrap: break-word;
    background: rgb(237, 240, 242);
    border-radius: 8px;
    margin-top: 10px;
    padding: 12px;
    overflow: auto;
}

.update_body_header {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 1.43;
}

.update_name_header {
    display: flex;
    font-weight: 900;
    color: rgba(66, 77, 87, 0.6);
    flex: 1 1 0%;
}

.update_date_header {
    color: rgba(66, 77, 87, 0.6);
}

.update_bottom_line {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 1.43;
}

.bottom_assigned {
    padding-right: 20px;
    overflow-wrap: break-word;
    color: rgba(66, 77, 87, 0.6);
    flex: 1 1 0%;
    overflow: auto;
}

.bottom_status {
    padding-right: 20px;
    overflow-wrap: break-word;
    color: rgba(66, 77, 87, 0.6);
    flex: 1 1 0%;
    overflow: auto;
}
