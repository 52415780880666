/* clinic flow ui changes */
.Input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}
.inputSelect {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    font-size: 15px;
    -webkit-appearance:none;
}
.checkboxStyle{
    margin-right: 5px;
    width: 15px;
    height: 15px;
    cursor: pointer;

}
/* clinic flow ui changes end */

.top_bar{
    display: flex;
    height:60px;
    
}
.top_bar_reg{
    display: flex;
    height:60px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.logo{
    width:257.53px;
    height:36.81px;
}
.header_doctor_name{
    margin-top: 14px;
    padding-top: 8px;
    font-weight: 700;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
    border-right: rgb(203, 212, 222) 1px solid;
}
.header_status{
    padding-top: 22px;
    font-weight: 700;
    font-size: 14px;
    padding-left: 15px;
}

.notAcceptingChat{
    color:rgb(226, 12, 12);
}

.main {
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    align-content: stretch;
    width: 100%;
    height: calc(100% - 60px);
    -webkit-font-smoothing: antialiased;
    background-color: #F1F2F6;
    flex-direction: column;
}
.headerText{
    font-size:20px;
    padding-top: 16px;
    font-family: 'HarmoniaSansStd-Bold';
    color: #88499e;
}

.socialButtons{
    display: flex;
    height: 100%;
    justify-content: space-between;
}
.mainRegistration {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    background-position:center center;
    background-repeat:no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    max-width: 100%;
    height: auto;
    max-height: 100%;
}

.Auth,.AuthSocial{
    background-color: #ffffff;
    justify-content: center;
    border-radius: 20px;
    margin-right: 50px;
    margin-top: 10px;
    margin-left: 50px;
    width:793px;
    height: fit-content;
    flex-direction: column;
    display: flex;
    padding: 10px 30px;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .mainRegistration {
        background-image: unset !important;
        background-color: white;
    }
    .regHeaderText{
        font-size: 24px;
        font-family: 'HarmoniaSansStd-Regular';
    }
    .subHeaderText{
        font-size: 20px;
    }
    .socialButtons{
        flex-direction: column;
    }
    .Auth{
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
        border-radius: 0px;
        border-width: 0px;
        opacity: 1;
    }
    .top_bar_reg{
        padding-left: 30px;
    }
    .regFormInput{
        font-size: 28px !important;
    }
    .regFormInputFirst{
        margin-bottom: 15px;
        margin-right: 0px !important;
    }
    .regFormRowTop{
        flex-direction: column !important;
    }
    .confirmButton{
        font-size: 18px !important;
    }
}
.spinner{
    margin-top: calc(30% - 5em);
}
.regFormInput{
    font-size: 28px;
    width: -webkit-fill-available;
    align-items: baseline;
    display: flex;
    border: 1px #979797 solid;
    border-radius: 7px;
    font-size: 28px;
    padding: 5px 10px;
}
.regFormInputFirst{
    font-size: 28px;
    width: -webkit-fill-available;
    align-items: baseline;
    display: flex;
    border: 1px #979797 solid;
    border-radius: 7px;
    margin-right: 10px;
    font-size: 28px;
    padding: 5px 10px;
}
.emaillogo{
    width:40px;
    height:30px;
    padding-right: 10px;
}
.fblogo,.googlelogo{
    width:40px;
    height:40px;
    padding-right: 10px;
}
.regHeaderText{
    font-size: 32px;
    font-family: 'HarmoniaSansStd-SemiBd';
    color: #000000;
    margin-bottom: 15px;
}
.regForm{
    display: flex;
    flex-direction: column;
}

.regFormRow{
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    justify-content: space-between;
    border: 1px #979797 solid;
    border-radius: 7px;
    padding: 5px 10px;
}
.regFormRowTerms{
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    justify-content: space-between;
    border-radius: 7px;
    font-family: 'HarmoniaSansStd-Regular';
}
.mainFont{
    font-family: 'HarmoniaSansStd-Regular';
}

.regFormRowTop{
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    justify-content: space-between;
}
.confirmButton,.confirmButtonFb,.confirmButtonAlt{
    display: flex;
    flex-direction: row;
    font-size: 24px;
    justify-content: center;
    height: 62px;
    background-color: white;
    color: #894A9E;
    border-radius: 14px;
    margin-top: 20px;
    align-items: center;
    cursor: pointer;
    font-family: 'HarmoniaSansStd-Regular';
    border: 1px #894A9E solid;
    width: -webkit-fill-available;
}
.confirmButton{
    margin-top: 15px !important;
}

.confirmButtonAlt{
    background-color: #894a9e !important;
    color: white !important;
}

.confirmButtonFb{
    margin-right: 15px;
}
.inputDigits{
    border-radius: 12px;
    width: 55px;
    height: 60px;
    text-align: center;
    font-size: 24px;
    border: 1px #979797 solid;
    margin-right: 10px;
}
.inputField{
    font-size: 28px ;
}
.subHeaderText{
    font-size:24px;
    font-family: 'HarmoniaSansStd-Bold';
    color: black;
}
.subHeaderText2{
    font-size:24px;
    font-family: 'HarmoniaSansStd-Regular';
    color: black;
    display: flex;
    padding-bottom: 22px;
}
.iframeStyle{
    width: 100%;
    height: -webkit-fill-available;
    z-index: 1;
}
.iframeCloseButton{
    z-index: 2;
    font-size: 18px;
    position: absolute;
    top: 20px;
    left: 8px;
    font-family: 'HarmoniaSansStd-Bold';
    cursor: pointer;
}
.termsButton{
    text-decoration: underline;
    margin-left: 5px;
    color: black;
}
.loginDiv{
    align-self: flex-end;
    margin-top: 15px;
    color: #454245;
    font-size: 18px;
    font-family: 'HarmoniaSansStd-Regular';
}
.loginButton{
    font-size: 28px;
    color: #894A9E;
    text-decoration: underline;
}
.termsDiv{
    display: flex;
    width: 100%;
    justify-content: center;
}
.termsInnerDiv{
    width: 90%;
}
.AuthSocial{
    height: 239px;
    width:557px;
}
.crossicon{
    margin-top: 31px;
    padding-bottom: 11px;
    align-self: flex-end;
}
.disableBorderBottom{
    border-bottom: none !important;
}
.img {
    background-size: cover;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
}
.head_buttons{
    display: flex;
    justify-content: flex-end;
}
.batch_upload_button{
    font-size: 17px;
    font-family: 'HarmoniaSansStd-SemiBd';
    color:#894A9E;
    background-color: #FFFFFF;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    margin-right: 12px;
    border-radius: 8px;
    cursor: pointer;
    border: 0.5px #894A9E solid;
}

.add_patient_button{
    font-size: 17px;
    font-family: 'HarmoniaSansStd-SemiBd';
    color:#FFFFFF;
    background-color: #894A9E;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    margin-right: 12px;
    border-radius: 8px;
    cursor: pointer;
}
.delete_patient_button{
    font-size: 17px;
    font-family: 'HarmoniaSansStd-SemiBd';
    color:#FFFFFF;
    background-color: #E02020;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    margin-right: 12px;
    border-radius: 8px;
    cursor: pointer;
}
.patients_table{
    width: calc(100% - 30px);
    background-color: #ffffff;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 8px;
}

.top_row{
    background-color: #E3E3EA;
    padding-top: 13px;
    padding-bottom: 13px;
    display: flex;
}
.top_row_attr{
    font-family: 'HarmoniaSansStd-SemiBd';
    font-size: 17px;
    color: #000000;
    padding-right: 20px;
    width: 15.5%;
}
.top_row_attr_big{
    font-family: 'HarmoniaSansStd-SemiBd';
    font-size: 17px;
    color: #000000;
    padding-left: 20px;
    padding-right: 20px;
    width: 19%;
}
.top_row_attr_start{
    min-width: 50px;
}
.top_row_attr_end{
    min-width: 105px;
}
.patient_row, .selected_patient_row{
    width:100%;
    height: 41px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #979797;
}
.selected_patient_row{
    background-color: #E3E3EA;
}


.table_row{
    display: flex;
    flex-direction: row;
    width: calc(100% - 155px);
    justify-content: space-between;
    padding-top: 11px;
}
.selected_table_row{
    display: flex;
    flex-direction: row;
    width: calc(100% - 155px);
    justify-content: space-between;
    padding-top: 5px;
}
.checkboxes{
    width: 50px;
    display: flex;
    justify-content: center;
    padding-top: 14px !important;
}
.action_field{
    width: 105px;
    display: flex;
    justify-content: center;
}

.modal,.batchUploadModal,.addPatientModal,.scheduleModal {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 70%;
    
    padding: 30px 0px 35px 0px;
    left: 15%;
    top: 0%;
    max-height: 100%;
    overflow: scroll;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    display: flex;
    flex-direction: column;
}
.scheduleModal {
    width:unset;
    min-width: 600px;
}
.timeslotsScroll{
    height: 350px;
    overflow: scroll;
}
@media (min-width: 600px) {
    .modal,.batchUploadModal {
        width: 600px;
    left: calc(50% - 300px);
    top: calc(50% - 172px);
    }
    .addPatientModal{
        width: 700px;
        left: calc(50% - 350px);
        top: calc(50% - 300px);
        padding-left: 8px;
        padding-right: 8px;
    }
    .scheduleModal{
        top: calc(50% - 300px);
        padding-left: 8px;
        padding-right: 8px;
    }
}


.overlay{
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: #898989;
    opacity: 0.4;
    overflow-y: hidden;
    transition: 0.1s;
}
.hide_overlay{
    height: 0;
    width: 100%;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: #898989;
    overflow-y: hidden;
    transition: 0.1s;
}
.addpatientRadioButton{
    justify-content: center;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.addpatientinviteText{
    justify-content: center;
    display: flex;
    align-items: center;
    padding-top: 20px;
    text-align: center;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 15px;
}
.table_column{
    font-size: 18px;
    width: 15.5%;
    font-family: 'HarmoniaSansStd-SemiBd';
}
.table_column_big{
    font-size: 18px;
    width: 19%;
    font-family: 'HarmoniaSansStd-SemiBd';
}

.popUpMain {
    flex: 1;
    margin-top: 20px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
}
.popUpText {
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    margin-right: 17px;
    font-weight: 700;
}
.inputfield{
    display: flex;
    width: 100%;
    height: 80px;
    padding-top: 15px;
    border-bottom: 1px solid #979797;
}
.input,.genderInput {
    width: 450px;
    height: 18px; 
    padding: 15px;
    margin-right: 10px;
    border-radius: 3px;
    border: 1px solid #999999;
    font-size: 16px;
    background: #ffffff;
}
.filterInput{
    width: 450px;
    height: 18px; 
    padding: 15px;
    margin-right: 10px;
    border-radius: 3px;
    border: 1px solid #999999;
    font-size: 16px;
    background: #ffffff;
}
.genderInput{
    height: 55px;
    width: 483px;
}
.row_input{
    width:127px;
    height:30px;
    font-size: 17px;
    border-radius: 4px;
    background: #ffffff;
    border: 1px solid #999999;
}
.insurance_row_input{
    width:168px;
    height:30px;
    font-size: 17px;
    border-radius: 4px;
    background: #ffffff;
    border: 1px solid #999999;
}
.email_row_input{
    width:215px;
    height:30px;
    font-size: 17px;
    border-radius: 4px;
    background: #ffffff;
    border: 1px solid #999999;
}
.saveButton {
    flex: 1;
    background-color: #894A9E;
    color: #ffffff;
    padding: 10px 10px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    margin: 10px;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
}
.form_small_input {
    text-align: center;
    font-size: 18px;
    padding: 0px 10px;
    border-radius: 5px;
    border: 1px solid #111111;
    height: 50px;
    width: 26%;
    font-family: 'HarmoniaSansStd';
}
.cancelButton {
    flex: 1;
    color: #894A9E;
    padding: 10px 10px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid #894A9E !important;
    margin: 10px;
    text-align: center;
}
.saveButtonSmall{
    background-color: #894A9E;
    color: #ffffff;
    border-radius: 5px;
    font-size: 8px;
    text-align: center;
    width: 62px;
    height: 15px;
    padding-top:3px;
    margin-bottom: 1px;
    cursor: pointer;
}
.reg_blue{
color: #894A9E;
}
.reg_red{
    color: #E02020;
}
.viewArchivediv{
    padding: 5px 15px;
    border: 1px solid grey;
    max-height: 28px;
    width: 134px;
    border-radius: 4px;
    cursor: pointer;
    color: black;
    text-decoration:none;
}
.viewArchivedivBig{
    padding: 5px 15px;
    border: 1px solid grey;
    max-height: 28px;
    width: 150px;
    border-radius: 4px;
}
.cancelButtonSmall{
    background-color: white;
    color: #222222;
    border-radius: 5px;
    font-size: 8px;
    text-align: center;
    width: 62px;
    height: 15px;
    padding-top:3px;
    cursor: pointer;
}
.cancelButtonSmallWithBorder{
    background-color: white;
    color: #222222;
    border-radius: 5px;
    font-size: 8px;
    text-align: center;
    width: 62px;
    height: 15px;
    padding-top:3px;
    cursor: pointer;
    border: 0.5px solid grey;
}
.actionButtons{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.batchUploadText1 {
    padding-left: 106px;
    padding-top: 10px;
    padding-bottom: 20px;
    margin-right: 10px;
}
.batchUploadText2 {
    padding-left: 106px;
    margin-right: 10px;
}

.batchUploadErrorText{
    padding-left: 106px;
    margin-right: 10px;
    padding-top: 10px;
    color: red;
}
.batchUploadSuccessText{
    padding-left: 106px;
    margin-right: 10px;
    padding-top: 10px;
    color: #894A9E;
}
.errorText{
    margin-right: 10px;
    padding-top: 10px;
    color: red;
}
.sentEmailText{
    margin-right: 10px;
    padding-top: 10px;
    color: black;
}

.buttonView {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-left: 100px;
}
.addPatientButtonView{
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-left: 50px;
    margin-right: 50px;
}
.actioniconImg{
    height:28px;
    width:23px;
    padding-top: 6px;
}

.scheduleFollowCalender{
    display: flex;
}
.scheduleDoctorSection{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.doctorRadioButtonsRow{
    margin-top: 40px;
}
.scheduleDoctorText{
    font-family: 'HarmoniaSansStd-Regular';
    font-size: 18px;
    color: #000000;
}

.dateFiltersRadio{
    margin-right: 5px;
    cursor: pointer;
}

.dateFilterRows{
    margin-bottom: 10px;
    font-size: 18px;
    font-family: 'HarmoniaSansStd-Regular';
}

.slotSection{
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px #979797 solid;
    margin-left: 20px;
    text-align: center;
    padding-bottom: 20px;
}

.slotSectionHeaderText{    
    font-family: 'HarmoniaSansStd-Bold';
}

.slotSectionButton{
    margin-top: 10px;
    border: 1px #894A9E solid;
    text-align: center;
    border-radius: 5px;
    padding: 10px 40px;
    color: #894A9E;
    background-color:white;
    cursor: pointer;
}
.selectedSlotSectionButton{
    margin-top: 10px;
    border: 1px #894A9E solid;
    text-align: center;
    border-radius: 5px;
    padding: 10px 40px;
    color: white;
    background-color:#894A9E ;
    cursor: pointer;
}

.calenderSection{
    width: 351px;
    padding-left: 20px;
    padding-right: 30px;
}

.timeSlotsSection{
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}
.timeSlotDate{
    color: #979797;
    margin-bottom: 15px;
}
.timeSlotsSectionButton{
    color: #894A9E;
    border: 1px #894A9E solid;
    padding: 10px 60px;
    text-align: center;
    margin-top: 10px;
    border-radius: 5px;
    background-color:white;
    cursor: pointer;
}
.selectedTimeSlotsSectionButton{
    color: white;
    border: 1px #894A9E solid;
    padding: 10px 60px;
    text-align: center;
    margin-top: 10px;
    border-radius: 5px;
    background-color:#894A9E ;
    cursor: pointer;
}
.loading_loader {
    width: 50px;
    height: 50px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 6px solid rgba(136, 74, 157, 0.6);
    position: relative;
    border-width: 4px;
    border-color: rgba(136, 74, 157, 0.6);
    border-top-color: #ffffff;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}
.loading_loader_sm {
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 6px solid rgba(136, 74, 157, 0.6);
    position: relative;
    border-width: 4px;
    border-color: rgba(136, 74, 157, 0.6);
    border-top-color: #ffffff;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }