.twilioButton {

    /* border: 1px solid #bcc6d0; */
    /* height: 34px;
    /* min-height: 32px; */
    /* min-width: 32px; */
    /* width: 120px; */
    /* background-color: #884a9d; */
    /* color: #fff; */
    /* border-radius: 4px; */
    font-weight: 700;
    background-color: white;
    border: none;
    /* font-size: 15px; */
    /* padding: 0 16px; */
    /* text-align: center; */
    /* margin-left: 20px; */
    /* position: relative;*/
}
