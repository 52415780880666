.content {
    left: 64px;
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    overflow: scroll;
}

@media screen and (max-width: 140px) {
    .content {
        left: 30%

    }
}



@media screen and (max-width: 500px) {
    .content {
        left: 30%;
        overflow: hidden;

    }
}