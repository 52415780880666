.Input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.Label {
    font-weight: bold;
    display: block;
    margin-bottom: 8px;
    font-family: 'HarmoniaSansStd';
}

.InputElement {
    outline: none;
    border: 0.5px solid #111111;
    background-color: white;
    font-size: 15px;
    padding: 10px 10px;
    height: 35px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    font-weight: bold;
    border-radius: 5px;
    
    font-family: 'HarmoniaSansStd';
}

.ClinicInputElement {
    outline: none;
    border: 0.5px solid #111111;
    background-color: white;
    font-size: 15px;
    padding: 10px 10px;
    height: 35px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    font-weight: bold;
    border-radius: 5px;
    font-family: 'HarmoniaSansStd';
}

.ClinicInputElement:focus {
    outline: none;
    background-color: #ffffff;
    color: #85479f;
}

.InputElement:focus {
    outline: none;
    background-color: #ffffff;
    color: #85479f;
}

.Invalid {
    border: 1px solid #85479f;
}