.tail_datetime_calendar {
/*   width: 245px;
  height: auto;
  margin: 15px;
  padding: 0;
  display: block;
  overflow: hidden;
  position: absolute;
  border-collapse: separate;
  font-family: "Open Sans", Calibri, Arial, sans-serif;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3125);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3125);
  border-radius: 3px;
  -webkit-border-radius: 3px; */
}
.tail_datetime_calendar:after {
  /* clear: both;
  content: "";
  display: block;
  font-size: 0;
  visibility: hidden; */
}
.tail_datetime_calendar.calendar-static {
  margin-left: auto;
  margin-right: auto;
}
.tail_datetime_calendar .calendar_navi {
  /* width: 100%;
  margin: 0;
  padding: 0;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 3px 3px 0 0;
  -webkit-border-radius: 3px 3px 0 0; */
  display: flex;
  justify-content: space-between;
}
.calendar_button{
  width:10px;
  color:black;
}
.tail_datetime_calendar .calendar_navi span {
  cursor: pointer;
  margin: 0;
  padding: 0;
  display: table-cell;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  background-repeat: no-repeat;
  background-position: center center;
}
.arrowSegment{
  width: 80px;
  display: flex;
  justify-content: space-between;
  padding-right: 30px;

}
.tail_datetime_calendar .calendar_navi span:first-child,
.tail_datetime_calendar .calendar_navi span:last-child {
  
  padding: 0 0 5px 0;
  font-size: 17px;
  color: black;
  font-family: 'HarmoniaSansStd-SemiBd';
}
.tail_datetime_calendar .calendar_navi span:hover {
  background-color: rgba(0, 0, 0, 0.15);
}
.tail_datetime_calendar .calendar_navi {
}
.button_prev{
  /*background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgNiAxNiI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iT\TYgMkwwIDhsNiA2VjJ6Ii8+PC9zdmc+);
  */
  padding:0px !important;  
}
.button_next{
  padding:0px !important;  
  /*background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgNiAxNiI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iT\TAgMTRsNi02LTYtNnYxMnoiLz48L3N2Zz4=);
  */
}
.tail_datetime_calendar .calendar_navi span.button-next {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgNiAxNiI+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iT\TAgMTRsNi02LTYtNnYxMnoiLz48L3N2Zz4=);
}

.tail_datetime_calendar .calendar_navi span.button-check {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkP\SJNMTIgNWwtOCA4LTQtNCAxLjUtMS41TDQgMTBsNi41LTYuNUwxMiA1eiIvPjwvc3ZnPg==);
}
.tail_datetime_calendar .calendar_navi span.button-close {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkP\SJNNy40OCA4bDMuNzUgMy43NS0xLjQ4IDEuNDhMNiA5LjQ4bC0zLjc1IDMuNzUtMS40OC0xLjQ4TDQuNTIgOCAuNzcgNC4y\NWwxLjQ4LTEuNDhMNiA2LjUybDMuNzUtMy43NSAxLjQ4IDEuNDhMNy40OCA4eiIvPjwvc3ZnPg==);
}
.tail_datetime_calendar .calendar_date {
  margin: 0;
  padding: 0;
  display: block;
}
.tail_datetime_calendar .calendar_date table {
  width: 100%;
  margin: 0;
  padding: 0;
  border-spacing: 0;
  border-collapse: collapse;
}
.tail_datetime_calendar .calendar_date table thead tr > *,
.tail_datetime_calendar .calendar_date table tbody tr > * {
  width: 55px;
  height: 55px;
  padding: 0;
  font-size: 12px;
  text-align: left;
  font-weight: normal;
  line-height: 55px;
  border: 0;
}
.tail_datetime_calendar .calendar_date table tbody tr > * {
  color: #334455;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  background-color: #ffffff;
  padding-left: 5px;
}
.tail_datetime_calendar .calendar_date table tbody tr > * > span {
  color:inherit;
  z-index: 10;
  position: relative;
}
/* Select a Day */
.tail_datetime_calendar .calendar_date table tbody tr > *::after {
  /* top: 12px;
  left: -6px;
  width: 29px;
  height: 29px;
  content: "";
  display: block;
  position: absolute; */
  /* border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 50%;
  -webkit-border-radius: 50%; */

}
.tail_datetime_calendar .calendar_date table tbody tr > *.today {
  color: #3296c8;
}
.tail_datetime_calendar .calendar_date table tbody tr > *.today:after {
  border-color: #3296c8;
}
.tail_datetime_calendar .calendar_date table tbody tr > *:hover {
  /* color: #cd283c;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  border-color: #cd283c;
  width: 29px;
  height: 29px; */
}
.hoverDate{

}
.selectedDate{
  color: #894A9E;
}
.tail_datetime_calendar .calendar_date table tbody tr > *:hover:after {
  border-color: #cd283c;
}
.tail_datetime_calendar .calendar_date table tbody tr > *.empty,
.tail_datetime_calendar .calendar_date table tbody tr > *.disable {
  color: #8899aa;
  cursor: not-allowed;
  background-color: #efefef;
}
.tail_datetime_calendar .calendar_date table tbody tr > *.empty:hover:after,
.tail_datetime_calendar .calendar_date table tbody tr > *.disable:hover:after {
  content: "";
  display: none;
}
.tail_datetime_calendar .calendar_date table tbody tr > *.current span {
  color: #fff;
}
.tail_datetime_calendar .calendar_date table tbody tr > *.current:after {
  border-color: #3296c8;
  background-color: #3296c8;
}
/* Select A Month */
.tail_datetime_calendar .calendar_date table.calendar-month tbody tr > * {
  width: 81.66666666666667px;
  padding: 5px;
  line-height: 25px;
}
.tail_datetime_calendar .calendar_date table.calendar-month tbody tr > * span {
  display: block;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 3px;
  -webkit-border-radius: 3px;
}
.tail_datetime_calendar
  .calendar_date
  table.calendar-month
  tbody
  tr
  > *:hover
  span {
  border-color: #d0d0d0;
  box-shadow: 0 1px 0 0 #efefef;
  -webkit-box-shadow: 0 1px 0 0 #efefef;
}
/* Select a Time */
.tail_datetime_calendar .calendar-time {
  width: 100%;
  margin: 0;
  padding: 0;
  display: table;
  text-align: center;
  background-color: #ffffff;
  border-radius: 0 0 3px 3px;
  -webkit-border-radius: 0 0 3px 3px;
}
.tail_datetime_calendar .calendar_date + .calendar-time {
  border-top: 1px solid #d0d0d0;
  background-color: #f8f8f8;
}
.tail_datetime_calendar .calendar-time .calendar-field {
  width: 33.3333333333%;
  padding: 10px 0;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: top;
}
.tail_datetime_calendar .calendar-time .calendar-field:first-child {
  text-align: right;
}
.tail_datetime_calendar .calendar-time .calendar-field:first-child:after {
  top: 12px;
  right: -10px;
  content: ":";
  position: absolute;
}
.tail_datetime_calendar .calendar-time .calendar-field:last-child {
  text-align: left;
}
.tail_datetime_calendar .calendar-time .calendar-field:last-child:after {
  top: 12px;
  left: -10px;
  content: ":";
  position: absolute;
}
.tail_datetime_calendar .calendar-time .calendar-field input[type="number"] {
  width: 100%;
  max-width: 50px;
  margin: 0;
  padding: 3px 4px;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: #d0d0d0;
  background-color: #ffffff;
  border-radius: 3px;
  -webkit-border-radius: 3px;
}
.tail_datetime_calendar
  .calendar-time
  .calendar-field
  input[type="number"]:hover {
  border-color: #a0a0a0;
}
.tail_datetime_calendar
  .calendar-time
  .calendar-field
  input[type="number"]:focus {
  border-color: #3296c8;
}
.tail_datetime_calendar .calendar-time .calendar-field label {
  color: #778899;
  margin: 5px 0 0 0;
  padding: 0;
  display: block;
  font-size: 12px;
  line-height: 12px;
}