.main {
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    align-content: stretch;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.add_content {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.content_block {
    width: 100%;
    height: 100%;
    display: block;
}

.add_content_body {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    background-color: rgb(248, 248, 250);
    border-color: rgb(221, 226, 230);
}

.add_content_header {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    height: 52px;
    position: relative;
    z-index: 2;
    text-align: center;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid rgb(221, 226, 230);
}

.absolute_change_password{
    display: flex;
    flex-direction: column;
    height: 100%;
    color: rgb(255, 255, 255);
    cursor: pointer;
    padding: 8px;
    border-radius: 6px;
    background-color: rgb(67, 132, 245);
    text-align: center;
}

.update_password_tag {
    clear: left;
    padding-bottom: 5px;
}

.update_password_tag input{
    width: 460px;
    padding: 9px 8px;
    font-size: 15px;
    line-height: 21px;
    color: rgba(66, 77, 87, 0.8);
    border: 1px solid #dde2e6;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
}

.update_password_field {
    float: left;
    margin: 0 24px 24px 0;
}

.update_password_form {
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    transition: all 200ms linear;
    margin: 32px 0 4px;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    align-items: center;
}

.update_password_submit {
    float: left;
    margin: 0;
    position: relative;
    flex-direction: row;
    display: flex;
}

.update_password_submit span{
    display: block;
    text-align: left;
    color: #999;
    margin-top: 10px;
    margin-right: 8px;
}

.password_button {
    margin-top: 10px;
    border-bottom: 1px solid rgb(188, 198, 208);
    cursor: pointer;
}

.update_password {
    position: relative;
    width: 100%;
    border-top: 1px solid #ddd;
    margin-bottom: 20px;
}

.update_password .view {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
}

.update_password .view .text{
    margin-bottom: 20px;
    font-size: 14px;
    flex: 1;
    color: rgba(66, 77, 87, 0.8);
    word-wrap: break-word;
    display: contents;
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
}

.update_password .view .tags{
    font-size: 13px;
    margin-top: 20px;
    flex: 1;
    margin-right: 270px;
    display: inline-block;
}

.update_password .view .tags span{
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
    position: relative;
    margin-right: 5px;
    flex: 1;
    font-size: 13px;
    color: rgba(66, 77, 87, 0.8);
    font-variant: small-caps;
}

.update_password .view .tags .shortcut{
    position: relative;
    max-width: 100%;
    overflow-wrap: break-word;
    padding: 3px 7px;
    margin: 2px;
    font-size: 15px;
    color: #424d57;
    line-height: 21px;
    border: 1px solid #d9dadb;
    border-radius: 4px;
    background-color: #ffffff;
    text-shadow: none;
    display: inline;
}

.update_password .view .tags .shortcut span{
    font-size: 18px;
    line-height: 21px;
    color: #4384f5;
}

.update_password .view .links{
    position: absolute;
    bottom: 0px;
    right: 185px;
}

.update_password .view .links span{
    margin-left: 10px;
    color: #4384f5;
}

.update_password .view .links span:hover{
    text-decoration: underline;
}

.update_password_main{
    width:100%;
}

.error_msg{
    color: #FF0000;
}

.success_msg{
    color: #428bca;
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 16px;

}

.update_password_header{
    color: rgb(66, 77, 87);
    padding: 11px 20px;
    display: block;
    margin-block-start: 0.83em;
    margin-inline-start: 0px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    font-family: 'Source Sans Pro', 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
}

.absolute_back_button {
    position: absolute;
    left: 0px;
    max-width: 100%;
    height: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    z-index: 1;
}

.absolute_back_button a{
    font-size: 16px;
    cursor: pointer;
    height: 100%;
    color: rgb(66, 77, 87);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    margin-left: 17px;
}

.back_button {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 16px;
    height: 16px;
    line-height: 16px;
}

.absolute_back_button a span{
    display: inline-block;
    font-size: 14px;
    margin-left: 9px;
}

.absolute_header {
    font-size: 18px;
    font-weight: 400;
    color: rgb(66, 77, 87);
    height: 52px;
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    position: absolute;
    box-sizing: border-box;
    width: calc(100% - 160px);
    left: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0px 80px;
}

.add_body {
    position: relative;
    height: calc(100% - 52px);
    z-index: 0;
}

.main_add_body {
    width: 100%;
    height: 100%;
    background-color: rgb(248, 248, 250);
    position: relative;
    overflow: auto;
}

.form_body {
    box-sizing: border-box;
    width: 720px;
    position: relative;
    background: rgb(255, 255, 255);
    padding: 68px;
    border-radius: 8px;
    margin: 100px auto;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(221, 226, 230);
    border-image: initial;
}

.image_body {
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: -50px;
    left: calc(50% - 50px);
    border-radius: 50px;
    background: rgb(243, 247, 249);
    border-width: 2px;
    border-style: solid;
    border-color: rgb(196, 206, 214);
    border-image: initial;
}

.image_header {
    display: block;
    background-size: cover;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 1000px;
}

.image_header_hover {
    opacity: 0;
    position: absolute;
    z-index: 1050;
    height: 100%;
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    color: rgb(255, 255, 255);
    background: rgba(0, 0, 0, 0.3);
    border-radius: 100%;
    transition: opacity 0.1s ease-in 0s;
}

.image_header_hover:hover{
    opacity: 1;
}

form {
    display: block;
}

h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    font-family: 'Source Sans Pro', 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
}

.form_content {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
}

fieldset {
    width: 100%;
    margin-bottom: 13px;
    border-style: none;
}

.content_field {
    position: relative;
    max-width: 420px;
    font-size: 24px;
    padding: 10px 0px 20px;
}

.content_field label{
    font-size: 24px;
    pointer-events: none;
    position: absolute;
    top: 10px;
    left: 0px;
    text-align: center;
    width: 100%;
    color: rgba(66, 77, 87, 0.8);
    transition: all 0.3s ease 0s;
}

.content_field label.floated{
    top: 47px;
    font-size: 14px;
}

.content_field label.floatedText{
    top: 165px;
    font-size: 14px;
}


.content_field input{
    border-top-style: initial;
    border-right-style: initial;
    border-left-style: initial;
    border-top-color: initial;
    border-right-color: initial;
    border-left-color: initial;
    width: 100%;
    line-height: 30px;
    text-align: center;
    color: rgb(66, 77, 87);
    border-width: 0px 0px 1px;
    border-image: initial;
    border-bottom: 1px solid rgb(188, 198, 208);
    outline: none;
    transition: all 0.3s ease 0s;
}

.content_field input.font_size {
    font-size: 24px;
}

.content_field input.sub_font_size {
    font-size: 16px;
}
.select_input{
    width: 100%;
    text-align: center;
    height: 35px;
    color: rgb(66, 77, 87);
}

.content_field textarea.sub_font_size {
    border-bottom: 1px solid #bdc6d0;
    height: 150px;
}

.lock {
    display: block;
    -webkit-box-align: center;
    align-items: center;
    width: 16px;
    height: 16px;
    line-height: 16px;
    position: absolute;
    right: 0px;
    bottom: 27px;
    fill: rgba(66, 77, 87, 0.6);
}

.chat_limit_view {
    position: relative;
    color: rgb(66, 77, 87);
    box-sizing: border-box;
    width: 100%;
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 40px;
    border-bottom: 1px solid rgb(221, 226, 230);
    padding: 11px 20px;
}

.chat_limit_box {
    color: rgba(66, 77, 87, 0.8);
    padding: 10px 20px;
    width: 100%;
    font-size: 18px;
    color: rgba(66, 77, 87, 0.6);
    box-sizing: border-box;
    border-bottom: 1px solid rgb(221, 226, 230);
}

.chat_limit_box_span {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    width: 90px;
}

.chat_limit_box_input {
    line-height: 42px;
    height: 42px;
    color: rgba(66, 77, 87, 0.8);
    text-align: right;
    max-width: 100%;
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(188, 198, 208);
    border-image: initial;
    padding: 0px 40px 0px 10px;
}

.add_count {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 50%;
    width: 30px;
    cursor: pointer;
    border-left: 1px solid rgb(188, 198, 208);
    border-bottom: 0.5px solid rgb(188, 198, 208);
}

.remove_count {
    top: auto;
    bottom: 0px;
    position: absolute;
    right: 0px;
    height: 50%;
    width: 30px;
    cursor: pointer;
    border-left: 1px solid rgb(188, 198, 208);
    border-top: 0.5px solid rgb(188, 198, 208);
}

.span_svg {
    margin-left: 5px;
}

.message_view {
    margin-top: 20px;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    line-height: 16px;
    color: #d64646;
    display: block;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.save_button_view {
    -webkit-font-smoothing: antialiased;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(66, 77, 87);
    min-height: 100px;
    box-sizing: border-box;
    background: white;
    padding: 29px 0px;
}

.cancel_button {
    -webkit-font-smoothing: antialiased;
    font-size: 15px;
    padding: 0 24px;
    min-width: 42px;
    min-height: 42px;
    line-height: 42px;
    background-color: #fff;
    color: #424d59;
    border: 1px solid #bcc6d0;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;
    -webkit-transition-property: opacity,border,color,background-color,box-shadow;
    transition-property: opacity,border,color,background-color,box-shadow;
    -webkit-transition-duration: .2s;
    transition-duration: .2s;
    -webkit-transition-timing-function: cubic-bezier(.64,0,.35,1);
    transition-timing-function: cubic-bezier(.64,0,.35,1);
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 600;
    display: -webkit-inline-box;
    display: inline-flex;
}

.save_button {
    margin-left: 20px;
    font-size: 15px;
    padding: 0 24px;
    min-width: 42px;
    min-height: 42px;
    line-height: 42px;
    background-color: #4384f5;
    color: #fff;
    border-color: #4384f5;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-decoration: none;
    -webkit-transition-property: opacity,border,color,background-color,box-shadow;
    transition-property: opacity,border,color,background-color,box-shadow;
    -webkit-transition-duration: .2s;
    transition-duration: .2s;
    -webkit-transition-timing-function: cubic-bezier(.64,0,.35,1);
    transition-timing-function: cubic-bezier(.64,0,.35,1);
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 600;
    display: -webkit-inline-box;
    display: inline-flex;
}

.inputStyle, .hidden {
    display: none;
}

.label_style {
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    padding-top: 20px;
    padding-bottom: 15px;
    color: rgba(0, 0, 0, 0.7);
    font-size: 18px;
}

.span_style{
    padding-right: 20px;
}

.license_container {
    display: flex;
    max-width: 420px;
    height: 300px;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
    font-size: 16px;

}
