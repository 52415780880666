html {
    font-family: 'Source Sans Pro', 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
}

div {
    display: block;
}

ol, ul {
    list-style: none;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
}

.load_more{
    text-align: "center";
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #884a9d;
    align-items: center;
    justify-content: center;
    justify-self: center;
    padding-left: 150px;
    color:white;
}
input {
    text-rendering: auto;
    color: initial;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    -webkit-appearance: textfield;
    background-color: white;
    -webkit-rtl-ordering: logical;
    cursor: text;
    margin: 0em;
    font: 400 11px system-ui;
    padding: 1px;
    border-width: 2px;
    border-style: inset;
    border-color: initial;
    border-image: initial;
}

.load_more{
    text-align: "center";
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #884a9d;
    align-items: center;
    justify-content: center;
    justify-self: center;
    padding-left: 150px;
    color:white;
}

.main {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: calc(100% - 60px);
    background-color: #F1F2F6;
}

.msg_header_default{
    font-size: 12px;
    /* overflow-y: scroll; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: relative;
    height: 88px;
    z-index: 2;
    text-align: center;
    /* box-sizing: border-box; */
    background-color: #E3E3EA;
    border-radius: 6px;
    margin-top: 15px;
    margin-right: 8px;
    margin-left: 8px;
    margin-bottom: 15px;
    justify-content: center !important;
}

.selected_list_view {
    display: flex;
    padding: 15px 15px;
    margin: 5px 5px 5px 8px;
    background-color: #884a9d;
    border-radius: 10px;
    cursor: pointer;
}

.list_img_style {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.selected_list_img {
    text-align: center;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 25px;
    width: 25px;
    transition: background-color 0.5s ease 0s;
}

.list_item {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    width: 68%;
    margin-left: 10px;
}

.item_name{
    color: #55626D;
    font-size: 16px;
    font-family: 'HarmoniaSansStd-Regular';
}

.selected_item_name {
    margin-bottom: 5px;
    color: #ffffff;
    font-size: 16px;
    font-family: 'HarmoniaSansStd-Bold';
}

.patient_name{
    color: #424D57;
    font-size: 16px;
    font-family: 'HarmoniaSansStd-Bold';
}

.list_item_second {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    width: 32%;
    justify-content: space-around;
    align-items: flex-end;
}

.time_style{
    font-size: 12px;
    color:#4B5762;
    font-family: 'HarmoniaSansStd-Regular';
    text-align: right;
}

.selected_user_img{
    display: block;
    color: rgb(255, 255, 255);
    overflow: hidden;
    border-radius: 50%;
    background: rgb(88, 101, 142);
}
.arc_list_view{
    display: flex;
    padding: 6px 9px;
    border-radius: 10px;
    background-color: #FFFFFF;
    margin: 5px 5px 5px 8px;
    cursor: pointer;
}

.list_view{
    box-sizing: border-box;
    height: 100%;
    min-width: 320px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 25%;
    border-left: none;
}

.block {
    width: 100%;
    height: 100%;
    display: block;
}

.main_block {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    background-color: #F1F2F6;
    border-color: rgb(203, 212, 222);
}

.main_header {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    height: 52px;
    position: relative;
    z-index: 2;
    text-align: center;
    box-sizing: border-box;
    background-color: rgb(243, 247, 249);
    border-bottom: 1px solid rgb(203, 212, 222);
}

.header_heading_view {
    color: rgb(66, 77, 87);
    height: 52px;
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    position: absolute;
    box-sizing: border-box;
    left: 0px;
    text-overflow: ellipsis;
    width: calc(100% - 40px);
    font-size: 20px;
    text-align: left;
    font-weight: 700;
    padding-left: 16px;
    overflow: hidden;
    margin: 0px 40px 0px 0px;
}

.header_heading_view_red {
    color: rgb(238, 242, 245);
    background-color: red;
    height: 52px;
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    position: absolute;
    box-sizing: border-box;
    left: 0px;
    text-overflow: ellipsis;
    width: calc(100%);
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    padding-left: 16px;
    overflow: hidden;
    margin: 0px 0px 0px 0px;
}

.list_body_view {
    height: 100%;
    z-index: 0;
    position: static;
}

.list_body_main {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    background-color: #F1F2F6;
}

.list_body_box {
    box-sizing: border-box;
    font-size: 12px;
    line-height: 15px;
    color: rgba(66, 77, 87, 0.8);
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 10px -6px;
    padding: 10px 7px 10px 7px;
}

.input_view {
    position: relative;
    margin-bottom: 10px;
    margin-top: 8px;
    display: flex;
}

.input_view:hover {
    outline: none;
    border-color: #884a9d;
    transition: border 0.2s ease-in-out 0s;
}

.input_style {
    width: 100%;
    font-size: 14px;
    color: rgb(66, 77, 87);
    text-align: left;
    box-sizing: border-box;
    padding: 0px 20px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(221, 226, 230);
    border-image: initial;
    padding-top: 6px;
    padding-bottom: 6px;
}

.input_style:hover {
    border-color: rgb(160, 166, 171);
}

.search_button{
    font-size: 18px;
    font-family: 'HarmoniaSansStd-Bold';
    color: #000000;
    align-self: flex-end;
    cursor: pointer;
}

.input_style:focus {
    width: 100%;
    font-size: 14px;
    color: rgb(66, 77, 87);
    text-align: left;
    padding: 0px 20px;
    border-radius: 4px;
    border-width: 0.1px;
    border-color: #884a9d;
    border-image: initial;
    padding-top: 6px;
    padding-bottom: 6px;
}

.search_sub_section{
    display: flex;
    justify-content: space-between;
}

.count_view {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.list_count {
    display: flex;
}

.no_loading {
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 1;
}

.no_loading_view {
    width: 56px;
    height: 56px;
    display: flex;
}

.loading_loader {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 50%;
    border: 6px solid rgba(136, 74, 157, 0.6);
    position: relative;
    border-width: 4px;
    border-color: rgba(136, 74, 157, 0.6);
    border-top-color: #884a9d;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.ul_list {
    overflow-y: auto;
    box-sizing: border-box;
    display: block;
    position: relative;
    list-style: none;
    flex: 1 1 0%;
}

.no_text {
    justify-content: center;
    align-items: center;
    display: flex;
    flex: 1;
    text-align: center;
    font-size: 16px;
    color: '#ffff'
}

.ul_list li {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    padding: 10px 25px 10px;
}

.ul_list li:hover{
    background-color: rgb(225, 233, 236);
    transition: background-color 0.1s ease-in-out 0s;
}

.li_main_view {
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    cursor: pointer;
    color: rgb(66, 77, 87);
    padding: 10px 6px;
    border-bottom: 1px solid rgb(221, 226, 230);
}

.li_main_view_selected {
    width: 100%;
    box-sizing: border-box;
    font-size: 14px;
    cursor: pointer;
    color: rgb(66, 77, 87);
    padding: 10px 6px;
    border-bottom: 1px solid rgb(221, 226, 230);
    background-color: rgb(225, 233, 236);
}

.li_selected_mark {
    width: 4px;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    display: block;
    content: "";
    background-color: #884a9d;
    transition: width 0.25s ease 0s;
}

.li_header {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: flex-start;
}

.li_header_name {
    line-height: 20px;
    flex: 1 1 0%;
    overflow: hidden;
}

.li_header_name p{
    font-size: 13px;
    height: 20px;
    color: rgba(66, 77, 87, 0.6);
}

.header_name_view {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.name_text {
    text-overflow: ellipsis;
    flex: 1 1 0%;
    overflow: hidden;
}

.header_name_view p{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
    height: 20px;
    color: rgba(66, 77, 87, 0.6);
}

.li_header_time {
    color: rgba(66, 77, 87, 0.6);
    font-size: 12px;
    margin-left: 10px;
    line-height: 20px;
}

.msg_box {
    display: flex;
}

.msg_main_box {
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 20px;
    height: 20px;
    overflow: hidden;
    color: rgb(66, 77, 87);
    font-size: 14px;
}

.msg_main_box span {
    color: rgba(78, 86, 101, 0.5);
    white-space: nowrap;
    line-height: 20px;
    font-family: 'Source Sans Pro', 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
}

/* body view*/

.main_body {
    box-sizing: border-box;
    height: 100%;
    min-width: 320px;
    -webkit-box-flex: 2;
    flex-grow: 2;
    flex-shrink: 1;
    flex-basis: 50%;
}

.body_header {
    display: flex;
    width: 100%;
    z-index: 2;
}

.body_header_text {
    font-size: 18px;
    font-weight: 400;
    color: rgb(66, 77, 87);
    height: 52px;
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    position: absolute;
    box-sizing: border-box;
    width: calc(100% - 160px);
    left: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0px 80px;
}

.message_time_left {
    display: flex;
    flex:1;
}

.message_time_right {
    margin-right: 45px;
    display: block;
    max-width: 100%;
}

.timestamp_span_left{
    font-size: 12px;
    text-align: right;
    width: 100%;
}

.timestamp_span_right{
    margin-right: 10px;
    font-size: 12px;
}

.main_body_view {
    height: calc(100% - 118px);
    z-index: 0;
    background-color: rgb(255, 255, 255);
    /* position: static; */
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 8px;
}

.main_body_container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.tag_list_view {
    flex: 0 0 auto;
    padding: 3px 6px;
}

.tag_view {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center
}

.tag_view span{
    font-family: "Source Sans Pro", sans-serif;
    color: rgb(66, 77, 87);
    display: inline-flex;
    font-size: 14px;
    line-height: 24px;
    word-break: break-all;
    -webkit-box-align: center;
    align-items: center;
    vertical-align: middle;
    background: rgb(255, 210, 133);
    border-radius: 3px;
    margin: 3px;
    padding: 0px 6px;
}

.tag_name {
    max-width: 170px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.main_body_content {
    overflow-y: scroll;
    height: 100%;
    background-color: transparent;
    box-sizing: border-box;
    padding: 12px;
}

.main_body_content_header {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    height: 21px;
}

.header_line {
    height: 1px;
    background-color: rgb(221, 226, 230);
    -webkit-box-flex: 1;
    flex-grow: 1;
    min-width: 8px;
}

.header_text {
    overflow-wrap: break-word;
    font-size: 14px;
    color: rgb(122, 130, 137);
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 80%;
    line-height: 1.5;
    padding: 0px 20px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.header_text_top {
    font-size: 20px;
    font-weight: 400;
    color: #424D57;
    height: 52px;
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    position: absolute;
    box-sizing: border-box;
    width: calc(100% - 20px);
    left: 0px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0px 10px;
    font-family: 'HarmoniaSansStd-Bold';

}

.msg_body {
    margin: 0.5em 0px;
}

.msg_name {
    display: flex;
    flex-direction: row;
    margin: 0px 0px 0px 40px;
}

.msg_name_text {
    max-width: 50%;
    font-size: 13px;
    line-height: 1em;
    padding-bottom: 6px;
    overflow-wrap: break-word;
    color: rgba(66, 77, 87, 0.8);
}

.msg_left_body {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
}

.msg_left_img {
    background-color: #884a9d;
    color: rgb(255, 255, 255);
    user-select: none;
    margin: 2px 0.3em 0px 0px;
    text-align: center;
    text-transform: uppercase;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 50%;
}

.msg_text_body {
    display: flex;
    margin-bottom: 1em;
    max-width: 75%;
}

.msg_text_container {
    width: 100%;
    overflow: visible;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
    flex-shrink: 1;
}

.msg_bubble_view {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
}

.msg_bubble_container {
    overflow: hidden;
}

.msg_bubble_content {
    display: flex;
    align-items: flex-start;
    font-size: 0.9em;
    max-width: 100%;
    flex-direction: row;
    margin: 2px;
}

.msg_bubble_content1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
}

.msg_bubble_content2 {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    overflow-wrap: break-word;
    width: 100%;
    flex-direction: row;
}

.msg_bubble_content_row {
    min-width: 0px;
    width: 100%;
}

.msg_bubble_content_view {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 0.1em;
    background-color: rgb(242, 243, 244);
    color: rgb(66, 77, 87);
    font-size: 15px;
    line-height: 1.5em;
    word-break: break-word;
    border-top-left-radius: 1.4em;
    border-top-right-radius: 1.4em;
    border-bottom-right-radius: 1.4em;
    border-bottom-left-radius: 0.3em;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.05);
    border-image: initial;
}

.msg_bubble_container_content {
    white-space: pre-line;
    overflow-wrap: break-word;
    max-width: 100%;
    padding: 10px 20px 12px;
}

.msg_right_name {
    display: flex;
    flex-direction: row-reverse;
    margin: 0px 40px 0px 0px;
}

.msg_right_body {
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
}

.msg_right_img {
    background-color: rgb(132, 91, 91);
    color: rgb(255, 255, 255);
    user-select: none;
    margin: 2px 0px 0px 0.3em;
    text-align: center;
    text-transform: uppercase;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 50%;
}

.msg_right_container {
    display: flex;
    margin-bottom: 1em;
    flex-direction: row-reverse;
}

.msg_right_content {
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
    flex-shrink: 1;
}

.msg_right_content1 {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.msg_right_content2 {
    overflow: hidden;
}

.msg_right_bubble_view {
    display: flex;
    align-items: flex-start;
    font-size: 0.9em;
    max-width: 100%;
    flex-direction: row-reverse;
    margin: 2px;
}

.msg_right_bubble_container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow: hidden;
}

.msg_right_bubble_container_content {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    overflow-wrap: break-word;
    width: 100%;
    flex-direction: row-reverse;
}

.msg_bubble_right {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 0.1em;
    background-color: #884a9d;
    color: rgb(255, 255, 255);
    font-size: 15px;
    line-height: 1.5em;
    word-break: break-word;
    border-top-left-radius: 1.4em;
    border-top-right-radius: 1.4em;
    border-bottom-right-radius: 0.3em;
    border-bottom-left-radius: 1.4em;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.05);
    border-image: initial;
}

/*-------------------- Archive User Detail Section ----------------------*/

.container_view {
    box-sizing: border-box;
    height: 100%;
    /* min-width: 320px; */
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 25%;
    padding-right: 8px;
    padding-top: 15px;
    overflow-y: auto;
}


.list {
    position: relative;
    opacity: 1;
    transition: max-height 0.2s ease 0s, opacity 0.2s ease 0s;
}

.list_text {
    margin-top: 10px;
    justify-content: center;
    font-size: 14px;
    text-align: center;
    margin-bottom: 8px;
    display: flex;
    border-radius: 8px;
    align-items: center;
    background-color: white;
    padding-top: 6px;
    padding-bottom: 6px;
}

.container_content {
    width: 100%;
    height: 100%;
    display: block;
}

.main_container {
    height: 100%;
    display: flex;
    position: relative;
    padding-right: 8px;
}

.main_content_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #F1F2F6;
    flex: 1 0 auto;
    padding-left: 8px;
}

.header_container {
    display: flex;
    padding: 0px 15px;
    border-bottom: 1px solid rgb(221, 226, 230);
    flex: 0 0 auto;
}

.header_container span {
    width: 20px;
    height: 20px;
    padding: 13px 8px 9px;
    border-bottom: 5px solid #884a9d;
}

.header_container span div{
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 20px;
    height: 20px;
    line-height: 20px;
}

.header_view {
    display: none;
    padding: 8px;
}

.header_view_text{
    position: fixed;
    top: 23px;
    right: 15px;
    font-weight: 700;
    font-size: 14px;
}

.header_view_text span{
    line-height: 20px;
}

.detailed_view {
    height: 100%;
    overflow: auto;
}

.detailed_view_container {
    padding: 14px 8px 100px;
}

.detailed_container {
    font-size: 20px;
    line-height: 24px;
    color: rgb(66, 77, 87);
    font-weight: 600;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: relative;
    height: 35px;
    margin: 0px 12px 10px;
}

.user_detail_view {
    background-color: rgb(255, 255, 255);
    margin-top: 218px    ;
    margin-bottom: 8px;
    font-size: 15px;
    line-height: 16px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(221, 226, 230);
    border-image: initial;
    border-radius: 8px;
    padding: 18px 18px 10px;
    overflow: hidden;
}

.user_cred_view {
    display: block;
    -webkit-box-align: center;
    align-items: center;
}

.user_main_view {
    margin-bottom: 15px;
}

.user_content_view {
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    line-height: 18px;
    margin-bottom: 8px;
}

.user_image_view {
    min-width: 40px;
    min-height: 40px;
    margin: 4px 0px;
}

.image_view {
    position: relative;
    font-weight: normal;
    text-align: center;
    box-sizing: border-box;
    opacity: 1;
    font-size: 16px;
    line-height: 40px;
    height: 40px;
    border-radius: 50%;
    transition: background-color 0.5s ease 0s;
}

.image_view span {
    display: block;
    color: rgb(255, 255, 255);
    overflow: hidden;
    border-radius: 50%;
    background: rgb(84, 128, 87);
}

.user_email_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-direction: column;
    margin-left: 10px;
    color: rgb(66, 77, 87);
    flex: 1 1 0%;
    padding: 4px 0px;
}

.user_email_detail span {
    white-space: pre-wrap;
    word-break: break-word;
}

.span_bold {
    font-weight: bold;
}

.other_detail_view {
    display: flex;
    flex-direction: column;
    color: rgb(66, 77, 87);
}

.top_bar{
    display: flex;
    height:60px;
}

.header_doctor_name{
    margin-top: 14px;
    padding-top: 8px;
    font-weight: 700;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
    /* border-right: rgb(203, 212, 222) 1px solid; */
}

.header_status{
    padding-top: 22px;
    font-weight: 700;
    font-size: 14px;
    padding-left: 15px;
}
.notAcceptingChat{
    color:rgb(226, 12, 12);
}
.openchatlist{
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    border-radius: 6px;
    border-width: 1px;
    text-align: left;
    padding-left: 13px;
    padding-right: 13px;
    height:43px;
    background-color:#E3E3EA;
    color: #424D57;
    font-size: 16px;
}
.openchatlistSection{
    display: flex;
    flex-direction: row;
}
.search_box_section{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.search_box{
    display: flex;
    justify-content: space-between;
    margin:5px;
    margin-top: 15px;
    margin-bottom:15px;
    border-radius: 6px;
    border-width: 1px;
    text-align: left;
    padding-left: 13px;
    padding-right: 13px;
    height:88px;
    background-color:#E3E3EA;
    color: #424D57;
    font-size: 16px;
}
.subListTitleIcon{
    padding-left:5px;
    margin-bottom:2px;
    transition: all 0.4s ease;
    align-self: center;
}
.subListTitleIconRotate{
    padding-left:5px;
    margin-top:2px;
    transition: all 0.4s ease;
    transform: rotateZ(-90deg);
    align-self: center;
}
.subListTitle{
    font-size: 18px;
    font-weight: 700;
    padding-left:10px;
    font-family: 'HarmoniaSansStd-Bold';
    color:#000000;
    align-self: center;
}

.loadMoreFollowUp {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: #ffffff;
    font-size: 14px;
    line-height: 34px;
    font-weight: 600;
    align-self: center;
    color: rgb(66, 77, 87);
    user-select: none;
    cursor: pointer;
    margin-bottom: 1px;
    border-radius: 8px;
    padding: 10px 0px 10px 10px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.expandable_section {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: #ffffff;
    font-size: 14px;
    line-height: 34px;
    font-weight: 600;
    align-self: center;
    color: rgb(66, 77, 87);
    margin-bottom: 1px;
    border-radius: 8px;
    padding: 10px 0px 10px 10px;
    margin-top: 8px;
    margin-bottom: 8px;
}
.general_info_section{
    display: flex;
    flex-direction: column;
}
.header_attr{
    font-size: 18px;
    font-weight: 700;
    font-family: 'HarmoniaSansStd-Bold';
    color:#000000;
}
.header_attr_value{
    font-size: 18px;
    font-family: 'HarmoniaSansStd-Regular';
    color:#000000;
    font-weight: 400;
}

.location_view_text {
    margin-bottom: 8px;
    display: flex;
    align-items: flex-start;
    margin-right: 10px;
    font-size: 16px;
    color:rgb(66, 77, 87);
}

.time_view {
    margin-bottom: 8px;
    display: flex;
    align-items: flex-start;
    margin-right: 10px;
    margin-left: 5px;
}

.session_text {
    margin-bottom: 8px;
    display: flex;
    align-items: flex-start;
    margin-right: 10px;
    margin-left: 5px;
    font-size: 13px;
    color: #884a9d;
}

.time_margin_view{
    margin-right: 8px;
}

.icon_view {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 16px;
    height: 16px;
    line-height: 16px;
}

.time_text {
    line-height: 18px;
}

.healthDetails{
    height: 20%;
    width: 100%;
    background-color:white;
}

.nameOfPatient{
    font-size: 20px;
    margin-left: 1%;
    padding-top: 2%;
    font-weight: bold;
}

.sleepDiv {
    margin-top: 4%;
    margin-left: 30%;
    font-size: 8px;
    padding-top: 5%;
}
.periodDiv {
    margin-top: 8%;
    margin-left: 20%;
    font-size: 8px;
    padding-top: 5%;
}

.periodDiv2 {
    margin-left: 20%;
    font-size: 8px;
    padding-top: 1%;
}

.heartDiv {
    font-size: 50%;
}

.heightDiv {
    margin-top: 20%;
    margin-left: 30%;
    font-size: 8px;
}

.sleepDiv2 {
    margin-left: 30%;
    font-size: 8px;
}
.mainHealth{
    flex-direction: column;
}

.sleepImage{
    width: 30px;
    height: 18px;
    padding-top: 20px;
    padding-left: 5px;
}

.periodImage{
    width: 17px;
    height: 25px;
    padding-top: 20px;
    padding-left: 5px;
}

.heartImage{
    width: 30px;
    height: 25px;
    margin-top: 30%;
    margin-left: 40%;
}

.weightImage{
    width: 25px;
    height: 25px;
    margin-left: 30%;
}

.runImage{
    width: 16px;
    height: 30px;
    margin-left: 95%;
    margin-top: 55%;
}

.heightImage{
    width: 20px;
    height: 35px;
    padding-left: 5px;
}

.leftbox {
    float:left;
    background:white;
    width:40.5%;
    height:200px;
}

.leftbox1 {
    float:left;
    background:white;
    width:5%;
    height:1px;
    padding-top: 5%;
}

.leftbox2 {
    float:left;
    background:white;
    width:5%;
    margin-top: 15%;
    height:1px;
}

.middlebox{
    float:left;
    background:white;
    width:19%;
    height:200px;
}

.rightbox{
    display: flex;
    flex-direction: column;
    float:right;
    background:white;
    width:40.5%;
    height:200px;
}

.rightbox2{
    display: flex;
    flex-direction: row ;
    margin-left: -2%;
}

.rightbox1{
    display: flex;
    margin-top: 15%;
    flex-direction: column ;
}

.rightbox3{
    display: flex;
    margin-top: 15%;
    margin-left: 20%;
    flex-direction: column ;
}

.body{
    height: 80%;
    margin-top: 25%;
    margin-bottom: 5%;
}

.sleepDivValue {
    margin-left: 30%;
    font-size: 12px;
    color:black;
    font-weight: 700;
}

.periodDivValue {
    margin-left: 20%;
    font-size: 12px;
    color:black;
    font-weight: 500;
}

.bpmValue {
    margin-left: 30px;
    font-size: 12px;
    color: black;
    font-weight: 700;
}
.stepsValue {
    font-size: 12px;
    color:black;
    font-weight: 500;
}

.heightDivValue {
    margin-left: 30%;
    font-size: 12px;
    color:black;
    font-weight: 500;
}
