
.generalInformation{
    height: 20%;
    width: 100%;
}
.openchatlist{
    display: flex;
    justify-content: space-between;
    margin-bottom:8px;
    border-radius: 6px;
    border-width: 1px;
    text-align: left;
    padding-left: 13px;
    padding-right: 13px;
    height:43px;
    background-color:#E3E3EA;
    color: #424D57;
    font-size: 16px;
}

.openchatlistSection{
    display: flex;
    flex-direction: row;
}

.subListTitleIcon{
    padding-left:5px;
    margin-bottom:2px;
    transition: all 0.4s ease;
    align-self: center;
}
.subListTitleIconRotate{
    padding-left:5px;
    margin-top:2px;
    transition: all 0.4s ease;
    transform: rotateZ(-90deg);
    align-self: center;
}
.subListTitle{
    font-size: 18px;
    font-weight: 700;
    padding-left:10px;
    font-family: 'HarmoniaSansStd-Bold';
    color:#000000;
    align-self: center;
}

.expandable_section {

    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: #ffffff;
    font-size: 14px;
    line-height: 34px;
    font-weight: 600;
    align-self: center;
    color: rgb(66, 77, 87);
    margin-bottom: 1px;
    border-radius: 8px;
    padding: 10px 0px 10px 10px;
    margin-top: 8px;
    margin-bottom: 8px;
}
.currentChat {

    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: #ffffff;
    font-size: 14px;
    line-height: 44px;
    font-weight: bold;
    color: rgb(66, 77, 87);
    user-select: none;
    cursor: pointer;
    margin-bottom: 1px;
    border-radius: 8px;
    padding: 4px 12px 4px 10px;
}
.selected_currentChat {

    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: #4384f5;
    font-size: 14px;
    line-height: 44px;
    font-weight: bold;
    color: rgb(252, 252, 250);
    user-select: none;
    cursor: pointer;
    margin-bottom: 1px;
    border-radius: 8px;
    padding: 4px 12px 4px 10px;
}
.list_unread_style {
    position: relative;
    font-weight: normal;
    text-align: center;
    font-size: 14px;
    line-height: 25px;
    width: 41px;
    padding-left: 5%;
    transition: background-color 0.5s ease 0s;
}
.unread_msg_style{
    display: block;
    color: rgb(255, 255, 255);
    overflow: hidden;
    border-radius: 50%;
    background:#4384F5;
}

.list {
    position: relative;
    opacity: 1;
    transition: max-height 0.2s ease 0s, opacity 0.2s ease 0s;

}

.spacer{
    padding-top: 3%;
}

.list_text {
    margin-top: 10px;
    justify-content: center;
    font-size: 14px;
    text-align: center;
    margin-bottom: 8px;
    display: flex;
    border-radius: 8px;
    align-items: center;
    background-color: white;
    padding-top: 6px;
    padding-bottom: 6px;
}


.list_container {
    padding-top: 10px;
}
.item_container {
    display: flex;
    padding: 15px 15px;
    background-color: #ffffff;
    border-radius: 10px;
}
.selected_item_container {
    display: flex;
    padding: 15px 15px;
    background-color:#4384f5;
    border-radius: 10px;
}
.item_content_view {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: space-between;
    width: 100%;
}

.item_name {
    float: left;
    color:black;
    font-size: 16px;
    font-family: 'HarmoniaSansStd-Regular';
}
.selected_item_name {
    float: left;
    color:white;
    font-size: 16px;

    font-weight: bold;
    font-family: 'HarmoniaSansStd-Regular';
}

.first_item_name {
    float: left;
    color: black;
    font-size: 16px;
    margin-top: 8px;
    padding-bottom: 10px;
    font-family: 'HarmoniaSansStd-Regular';
}
.first_selected_item_name {
    float: left;
    color:white;
    font-size: 16px;
    margin-top: 8px;

    font-weight: bold;
    font-family: 'HarmoniaSansStd-Regular';
}

.categoryTab{
    display: flex;
    flex-direction: column;
    padding-right: 20px;
}
.loadMore {
    justify-content: center;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: #ffffff;
    font-size: 14px;
    line-height: 44px;
    font-weight: bold;
    align-self: center;
    color: rgb(66, 77, 87);
    user-select: none;
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: 8px;
    padding-bottom: 4px;
    padding-top: 4px;
}

.header_attr{
    font-size: 18px;
    font-weight: 700;
    font-family: 'HarmoniaSansStd-Bold';
    color:#000000;
}

.response_item {
    margin-top: 5px;
    overflow-y: auto;
    height: 380px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 12px;
    border-radius: 10px;
    display: none;
}

.response_item_selected {
    margin-top: 5px;
    overflow-y: auto;
    height: 380px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 12px;
    border-radius: 10px;
}
