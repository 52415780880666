html {
    font-family: 'HarmoniaSansStd-Regular';
}

.main {
    box-sizing: border-box;
    height: 100%;
    min-width: 320px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 25%;
}
.generalInformation{
    height: 20%;
    width: 100%;
}
.healthDetails{
    height: 20%;
    width: 100%;
    background-color:white;

}
.openchatlist{
    display: flex;
    justify-content: space-between;
    margin-bottom:8px;
    border-radius: 6px;
    border-width: 1px;
    text-align: left;
    padding-left: 13px;
    padding-right: 13px;
    height:43px;
    background-color:#E3E3EA;
    color: #424D57;
    font-size: 16px;
}
.openchatlistSection{
    display: flex;
    flex-direction: row;
}
.subListTitleIcon{
    padding-left:5px;
    margin-bottom:2px;
    transition: all 0.4s ease;
    align-self: center;
}
.subListTitleIconRotate{
    padding-left:5px;
    margin-top:2px;
    transition: all 0.4s ease;
    transform: rotateZ(-90deg);
    align-self: center;
}
.subListTitle{
    font-size: 18px;
    font-weight: 700;
    padding-left:10px;
    font-family: 'HarmoniaSansStd-Bold';
    color:#000000;
    align-self: center;
}
.SOAP_list{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65px;
    background-color: white;
    border-radius: 8px;
    margin-bottom: 10px;

}
.list, .SOAP_list{
    position: relative;
    opacity: 1;
    transition: max-height 0.2s ease 0s, opacity 0.2s ease 0s;
}
.SOAP_list_text{
    background-color: #884a9d;
    color: white;
    width: 96px;
    height: 21px;
    border-radius: 4px;
    margin-top: 10px;
    justify-content: center;
    font-size: 12px;
    text-align: center;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: "HarmoniaSansStd-Regular";
}
.SOAP_div{
    display: flex;
    flex-direction: column;
}
.SOAP_buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.SOAP_clear_button{
    background-color:#EBEBEB;
    color: black;
    width: 96px;
    height: 21px;
    border-radius: 4px;
    margin-top: 10px;
    justify-content: center;
    font-size: 12px;
    text-align: center;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: "HarmoniaSansStd-Regular";
}
.SOAP_heading{
    font-size: 18px;
    font-family: 'HarmoniaSansStd-Bold';
    color: #000000;
}
.SOAP_textarea{
    border: #E3E3EA 1px solid;
    border-radius: 2px;
    font-size: 16px;
    font-family: "HarmoniaSansStd-Regular";
    color: black;
    min-height: 111px;
    padding: 2px 10px;
    width: calc(100% - 20px);
}
.nameOfPatient{
    font-size: 20px;
    margin-left: 1%;
    padding-top: 2%;

    font-weight: bold;
}
.sleepDiv {
    margin-top: 4%;
    margin-left: 35%;
    font-size: 8px;
    padding-top: 5%;
}

.periodDiv {
    margin-top: 8%;
    margin-left: 25%;
    font-size: 8px;
    padding-top: 5%;
}

.periodDiv2 {
    margin-left: 25%;
    font-size: 8px;
    padding-top: 1%;
}

.heartDiv {
    font-size: 50%;
}

.weightDiv {
    font-size: 50%;
    padding-left: 20px;
    margin-top:-15%
}

.heightDiv {
    margin-top: 14%;
    margin-left: 26%;
    font-size: 8px;
}

.sleepDiv2 {
    margin-left: 35%;
    font-size: 8px;
}

.mainHealth{
    flex-direction: column;
}

.sleepImage{
    width: 30px;
    padding-top: 10px;
    padding-left: 5px;
    margin-top: 100%;
}

.periodImage{
    width: 22px;
    padding-top: 10px;
    padding-left: 5px;
    margin-top: 100%;
}

.heartImage{
    width: 30px;
    height: 25px;
    margin-top: 40%;
    margin-left: 70%;
}

.weightImage{
    width: 25px;
    height: 25px;
    display:flex;
    align-self: flex-end;
    margin-top: -40%;
}
.naWeightImage{
    width: 25px;
    height: 25px;
    margin-top: -30%;
    margin-left: 60%
}

.runImage{
    width: 16px;
    height: 30px;
    margin-left: 150%;
    margin-top: 40%;
}

.heightImage{
    width: 20px;
    height: 30px;
    padding-left: 5px;
    padding-bottom: 5px;

}

.leftbox {
    float:left;
    background:white;
    width:40.5%;
    height:200px;
}

.leftbox1 {
    float:left;
    background:white;
    width:5%;
    height:1px;
    padding-top: 5%;
}

.leftbox2 {
    float:left;
    background:white;
    width:5%;
    margin-top: 15%;
    height:1px;
}

.middlebox{
    float:left;
    background:white;
    width:19%;
    height:200px;
}

.rightbox{
    display: flex;
    flex-direction: column;
    float:right;
    background:white;
    width:40.5%;
    height:200px;
}

.rightbox2{
    display: flex;
    flex-direction: row ;
    margin-left: -2%;

}
.rightbox1{
    display: flex;
    margin-top: 15%;
    flex-direction: column ;
}

.rightbox3{
    display: flex;
    margin-top: 15%;
    margin-left: 20%;
    flex-direction: column ;
}
.rightbox4{
    display: flex;
    margin-top: 15%;
    flex-direction: column ;
}


.body{
    height: 80%;
    margin-top: 25%;
    margin-bottom: 5%;
}

.sleepDivValue {
    margin-left: 35%;
    font-size: 12px;
    color:black;
    font-weight: 700;
}

.periodDivValue {
    margin-left: 25%;
    font-size: 12px;
    color:black;
    font-weight: 500;
}

.bpmValue {
    margin-left: 30px;
    font-size: 12px;
    color:black;
    font-weight: 700;
}

.stepsValue {
    font-size: 12px;
    color:black;
    font-weight: 500;
}

.weightValue {
    font-size: 12px;
    color:black;
    font-weight: 500;
    padding-left: 20px;
    margin-right: 1px;
}

.heightDivValue {
    margin-left: 26%;
    font-size: 12px;
    color:black;
    font-weight: 500;
}

.container {
    width: 100%;
    height: 100%;
    display: block;
}

.user_detail_view {
    background-color: rgb(255, 255, 255);
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 15px;
    line-height: 16px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(221, 226, 230);
    border-image: initial;
    border-radius: 8px;
    padding: 18px 18px 10px;
    overflow: hidden;
}

.user_cred_view {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 14px;
}

.user_content_view {
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    line-height: 18px;
}

.user_image_view {
    min-width: 40px;
    min-height: 40px;
    margin: 4px 0px;
}

.image_view {
    position: relative;
    font-weight: normal;
    text-align: center;
    box-sizing: border-box;
    opacity: 1;
    font-size: 16px;
    line-height: 40px;
    height: 40px;
    border-radius: 50%;
    transition: background-color 0.5s ease 0s;
}

.image_view span {
    display: block;
    color: rgb(255, 255, 255);
    overflow: hidden;
    border-radius: 50%;
    background: rgb(84, 128, 87);
}

.user_email_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-direction: column;
    margin-left: 10px;
    color: rgb(66, 77, 87);
    flex: 1 1 0%;
    padding: 4px 0px;
}

.user_email_detail span {
    white-space: pre-wrap;
    word-break: break-word;
}

.span_bold {
    font-weight: bold;
}

.other_detail_view {
    display: flex;
    flex-direction: column;
    color: rgb(66, 77, 87);
}

.time_view {
    margin-top: 8px;
    display: flex;
    align-items: flex-start;
}

.time_margin_view{
    margin-right: 8px;
}

.icon_view {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 16px;
    height: 16px;
    line-height: 16px;
}

.time_text {
    line-height: 18px;
}

.user_main_view {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 14px;
}

.content_container {
    height: 100%;
    display: flex;
    position: relative;
}

.main_content_container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #F1F2F6;
    flex: 1 0 auto;
}

.header {
    display: flex;
    padding: 0px 15px;
    border-bottom: 1px solid rgb(221, 226, 230);
    flex: 0 0 auto;
}

.header_view {
    display: none;
    padding: 8px;
}

.header_body {
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
    padding: 18px 8px 8px;
}

.header span {
    width: 20px;
    height: 29px;
    padding: 13px 8px 9px;
}

.header span div{
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 20px;
    height: 20px;
    line-height: 20px;
}

.header_cross {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 18px;
    height: 18px;
    line-height: 18px;
}

.detailed_view {
    height: 100%;
    overflow: auto;
}

.detailed_view_container {
    padding: 15px 10px 100px;
}

.detailed_container {
    font-size: 20px;
    line-height: 24px;
    color: rgb(66, 77, 87);
    font-weight: 600;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: relative;
    height: 35px;
    margin: 0px 12px 10px;
}

.videoText{
    align-self: center;
    padding-left: 100px;
}

.videoContainer{
    font-size: 12px;
    background-color: green;
    color: white;
    font-weight: 600;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    position: relative;
    height: 20px;
    border-radius: 5%;
}

.spacer{
    padding-top: 3%;
}
.general_info_view{
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: #E3E3EA;
    font-size: 18px;
    line-height: 24px;
    color: rgb(66, 77, 87);
    user-select: none;
    cursor: pointer;
    margin-bottom: 1px;
    border-radius: 8px;
    padding: 4px 0px 4px 0px;
    font-weight: 700;
    font-family: 'HarmoniaSansStd-Bold';
    height: 43px;
}
.list_view {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: #E3E3EA;
    font-size: 18px;
    line-height: 24px;
    color: rgb(66, 77, 87);
    user-select: none;
    margin-top: 215px;
    cursor: pointer;
    margin-bottom: 1px;
    border-radius: 8px;
    padding: 4px 0px 4px 0px;
    font-weight: 700;
    font-family: 'HarmoniaSansStd-Bold';
    height: 43px;

}
.followUp{
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: #ffffff;
    font-size: 14px;
    line-height: 24px;
    color: rgb(66, 77, 87);
    user-select: none;
    margin-top: 15px;
    cursor: pointer;
    margin-bottom: 1px;
    border-radius: 8px;
    padding: 4px 12px 4px 34px;
    border: 0.5px solid rgb(15, 15, 15);
}
.followUp1{
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: #ffffff;
    font-size: 14px;
    line-height: 24px;
    color: rgb(66, 77, 87);
    user-select: none;
    margin-top: 15px;
    cursor: pointer;
    margin-bottom: 10px;
    border-radius: 8px;
    padding: 4px 12px 4px 34px;
    border: 0.5px solid rgb(15, 15, 15);
}
.list_view_span {
    position: absolute;
    top: 22%;
    left: 12px;
    width: 12px;
    height: 5px;
    transform: rotate(0deg) translateY(-50%);
    transform-origin: center top;
    transition: transform 0.2s ease-in-out 0s;
}
.currentChat {

    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: #ffffff;
    font-size: 14px;
    line-height: 44px;
    font-weight: bold;
    color: rgb(66, 77, 87);
    user-select: none;
    cursor: pointer;
    margin-bottom: 1px;
    border-radius: 8px;
    padding: 4px 12px 4px 10px;
}
.selected_currentChat {

    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: #4384f5;
    font-size: 14px;
    line-height: 44px;
    font-weight: bold;
    color: rgb(252, 252, 250);
    user-select: none;
    cursor: pointer;
    margin-bottom: 1px;
    border-radius: 8px;
    padding: 4px 12px 4px 10px;
}
.categoryTab{
    display: flex;
    flex-direction: column;
    padding-right: 20px;
}
.loadMore {
    justify-content: center;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: #ffffff;
    font-size: 14px;
    line-height: 44px;
    font-weight: bold;
    align-self: center;
    color: rgb(66, 77, 87);
    user-select: none;
    cursor: pointer;
    margin-bottom: 1px;
    border-radius: 8px;
    padding-bottom: 4px;
    padding-top: 4px;
}
.radioButton{
    margin-top: -10px;
}
.radioButton1{
    margin-left: 25px;
}
.followUpInput {
    width: 220px;
    padding: 0px;
    font-size: 14px;
    margin-right: 10px;
    border: 1px solid #999999;
    overflow: visible;
    padding-left: 5px;
}
.general_info_section{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 10px;
}
.header_attr{
    font-size: 18px;
    font-weight: 700;
    font-family: 'HarmoniaSansStd-Bold';
    color:#000000;
}
.header_attr_link{
    font-size: 18px;
    font-family: 'HarmoniaSansStd';
    color:#1E90FF	;
}
.header_attr_value{
    font-size: 18px;
    font-family: 'HarmoniaSansStd-Regular';
    color:#000000;
    font-weight: 400;
}
.loadMoreFollowUp {

    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: #ffffff;
    font-size: 14px;
    line-height: 34px;
    font-weight: 600;
    align-self: center;
    color: rgb(66, 77, 87);
    user-select: none;
    cursor: pointer;
    margin-bottom: 1px;
    border-radius: 8px;
    padding: 10px 0px 10px 10px;
    margin-top: 8px;
    margin-bottom: 8px;
}
.addressSegment{
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 16px;
}
.expandable_section {

    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: #ffffff;
    font-size: 14px;
    line-height: 34px;
    font-weight: 600;
    align-self: center;
    color: rgb(66, 77, 87);
    margin-bottom: 1px;
    border-radius: 8px;
    padding: 10px 0px 10px 10px;
    margin-top: 8px;
    margin-bottom: 8px;
}
.loadMoreFollowUp1 {

    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: #ffffff;
    font-size: 14px;
    line-height: 34px;
    font-weight: 600;
    align-self: center;
    color: rgb(66, 77, 87);
    user-select: none;
    cursor: pointer;
    margin-bottom: 8px;
    border-radius: 8px;
    padding: 10px 0px 10px 10px;
    margin-top: 8px;
}
.followUpText{
    margin-right: 15px;
}
.simpleButton1{
    margin-right: 5px;
    width: 90px;
    background-color: lightgrey;
    color: black;
    border-width: 0
}
.simpleButton2{
    margin-right: 5px;
    width: 90px;
    background-color: lightgrey;
    color: black;
    border-width: 0

}
.simpleButton{
    margin-right: 5px;
}
.list_unread_style {
    position: relative;
    font-weight: normal;
    text-align: center;
    font-size: 14px;
    line-height: 25px;
    width: 41px;
    padding-left: 5%;
    transition: background-color 0.5s ease 0s;
}
.unread_msg_style{
    display: block;
    color: rgb(255, 255, 255);
    overflow: hidden;
    border-radius: 50%;
    background:#4384F5;
}
.loadMoreDisabled {

    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color:rgb(187, 185, 185);
    font-size: 14px;
    line-height: 44px;
    font-weight: bold;
    align-self: center;
    color: rgb(66, 77, 87);
    user-select: none;
    cursor: pointer;
    margin-bottom: 1px;
    border-radius: 8px;
    padding: 4px 12px 4px 100px;
    border: 0.5px solid rgb(15, 15, 15);
}

.list_view_span_rotated {
    position: absolute;
    top: 50%;
    left: 6px;
    width: 12px;
    height: 8px;
    transform: rotate(-90deg) translateY(-50%);
    transform-origin: center top;
    transition: transform 0.2s ease-in-out 0s;
}

.list_view span div {
    display: block;
    -webkit-box-align: center;
    align-items: center;
    width: 12px;
    height: 8px;
    line-height: 8px;
}

.list {
    position: relative;
    opacity: 1;
    transition: max-height 0.2s ease 0s, opacity 0.2s ease 0s;
}

.list_text {
    margin-top: 10px;
    justify-content: center;
    font-size: 14px;
    text-align: center;
    margin-bottom: 8px;
    display: flex;
    border-radius: 8px;
    align-items: center;
    background-color: white;
    padding-top: 6px;
    padding-bottom: 6px;
}

.list_container {
    padding-top: 10px;
}

.item_container {
    display: flex;
    padding: 15px 15px;
    background-color: #ffffff;
    border-radius: 10px;
}
.selected_item_container {
    display: flex;
    padding: 15px 15px;
    background-color:#4384f5;
    border-radius: 10px;
}

.item_content_view {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: space-between;
    width: 100%;
}

.item_name {
    float: left;
    color:black;
    font-size: 16px;
    font-family: 'HarmoniaSansStd-Regular';
}
.selected_item_name {
    float: left;
    color:white;
    font-size: 16px;

    font-weight: bold;
    font-family: 'HarmoniaSansStd-Regular';
}
.first_item_name {
    float: left;
    color: black;
    font-size: 16px;
    margin-top: 8px;
    padding-bottom: 10px;
    font-family: 'HarmoniaSansStd-Regular';
}
.first_selected_item_name {
    float: left;
    color:white;
    font-size: 16px;
    margin-top: 8px;

    font-weight: bold;
    font-family: 'HarmoniaSansStd-Regular';
}

.item_date {
    float: right;
    color: black;
    font-size: 16px;
    font-family: 'HarmoniaSansStd-Regular';
}

.response_item {
    margin-top: 5px;
    overflow-y: auto;
    height: 380px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 12px;
    border-radius: 10px;
    display: none;
}

.response_item_selected {
    margin-top: 5px;
    overflow-y: auto;
    height: 380px;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 12px;
    border-radius: 10px;
}

.msg_body {
    margin: 0.5em 0px;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    transition: all 200ms linear;
}

.msg_name {
    display: flex;
    flex-direction: row;
    margin: 0px 0px 0px 40px;
}

.msg_name_text {
    max-width: 50%;
    font-size: 13px;
    line-height: 1em;
    padding-bottom: 6px;
    overflow-wrap: break-word;
    color: rgba(66, 77, 87, 0.8);
}

.msg_left_body {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
}

.msg_left_img {
    background-color: rgb(57, 76, 130);
    color: rgb(255, 255, 255);
    user-select: none;
    margin: 2px 0.3em 0px 0px;
    text-align: center;
    text-transform: uppercase;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 50%;
}

.msg_text_body {
    display: flex;
    margin-bottom: 1em;
    max-width: 75%;
}

.msg_text_container {
    width: 100%;
    overflow: visible;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
    flex-shrink: 1;
}

.msg_bubble_view {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
}

.msg_bubble_container {
    overflow: hidden;
}

.msg_bubble_content {
    display: flex;
    align-items: flex-start;
    font-size: 0.9em;
    max-width: 100%;
    flex-direction: row;
    margin: 2px;
}

.msg_bubble_content1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
}

.msg_bubble_content2 {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    overflow-wrap: break-word;
    width: 100%;
    flex-direction: row;
}

.msg_bubble_content_row {
    min-width: 0px;
    width: 100%;
}

.msg_bubble_content_view {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 0.1em;
    background-color: rgb(242, 243, 244);
    color: rgb(66, 77, 87);
    font-size: 15px;
    line-height: 1.5em;
    word-break: break-word;
    border-top-left-radius: 1.4em;
    border-top-right-radius: 1.4em;
    border-bottom-right-radius: 1.4em;
    border-bottom-left-radius: 0.3em;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.05);
    border-image: initial;
}

.msg_bubble_container_content {
    white-space: pre-line;
    overflow-wrap: break-word;
    max-width: 100%;
    padding: 10px 20px 12px;
}

.msg_right_name {
    display: flex;
    flex-direction: row-reverse;
    margin: 0px 40px 0px 0px;
}

.msg_right_body {
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
}

.msg_right_img {
    background-color: rgb(132, 91, 91);
    color: rgb(255, 255, 255);
    user-select: none;
    margin: 2px 0px 0px 0.3em;
    text-align: center;
    text-transform: uppercase;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 50%;
}

.msg_right_container {
    display: flex;
    margin-bottom: 1em;
    flex-direction: row-reverse;
}

.msg_right_content {
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
    flex-shrink: 1;
}

.msg_right_content1 {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.msg_right_content2 {
    overflow: hidden;
}

.msg_right_bubble_view {
    display: flex;
    align-items: flex-start;
    font-size: 0.9em;
    max-width: 100%;
    flex-direction: row-reverse;
    margin: 2px;
}

.msg_right_bubble_container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow: hidden;
}

.msg_right_bubble_container_content {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    overflow-wrap: break-word;
    width: 100%;
    flex-direction: row-reverse;
}

.msg_bubble_right {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 0.1em;
    background-color: rgb(66, 127, 225);
    color: rgb(255, 255, 255);
    font-size: 15px;
    line-height: 1.5em;
    word-break: break-word;
    border-top-left-radius: 1.4em;
    border-top-right-radius: 1.4em;
    border-bottom-right-radius: 0.3em;
    border-bottom-left-radius: 1.4em;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.05);
    border-image: initial;
}
.spinner_view {
    justify-content: center;
    position: relative;
    display: flex;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: rgb(46, 46, 46);
    padding: 7px;
    margin: 10px 10px;
    text-decoration: none;
    background-image: -webkit-linear-gradient(left,#85479f -25%,#d36797 125%)!important;
    border-radius: 25px;
    left:120px;
    top:5px
}
