.main {
    width: 100%;
    height: 100%;
    flex-direction: column;
    display: flex;
}

.header {
    width: 100%;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgb(221, 226, 230);
    height: 52px;
    text-align: center;
    display: flex;
    font-size: 18px;
    font-weight: 400;
    color: rgb(66, 77, 87);
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
}

.main_body {
    height: 54px;
    padding: 0 20px;
    border-bottom: 1px solid rgb(221, 226, 230);
    font-weight: 800;
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.7);
}

.heading {
    position: relative;
    display: flex;
    padding: 0 20px;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    height: 51px;
    color: rgb(66, 77, 87);
    cursor: pointer;
    font-weight: 600;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    background: none;
    outline: none;
}

.content {
    height: 21px;
    width: fit-content;
    line-height: 20px;
    font-size: 14px;
    vertical-align: middle;
    margin-left: 15px;
    border-radius: 20px;
}

.sub_content {
    padding: 5px 5px;
}
