
.placeHolderCard {
    height: 144px;
    border: 1px dashed #D4D4D4;
    border-radius: 5px;
    opacity: 1;
    justify-content: center;
    display: flex;
    align-items: center;
}

.filledImage {
    position: absolute;
    width: 200px;
    height: 120px;
}


.removeIcon{
    position: absolute;
    width: 24px;
    height: 24px;
    margin-left: 200px;
    margin-bottom: 118px;
}

.warningRemoveIcon{
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.placeHolderImage {
    width: 72px;
    height: 46px;
}
.copyLink{
    display: flex;
    width: 100%;
    justify-content: center;
    text-decoration: underline;
    cursor: pointer;
}
