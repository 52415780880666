@font-face {
    font-family: 'HarmoniaSansStd';
    font-style: normal;
    font-weight: 400;
    src: url('../../../assets/fonts/HarmoniaSansStd-Regular.otf'); /* IE9 Compat Modes */
    src: local('HarmoniaSansStd'), local('HarmoniaSansStd'),
         url('../../../assets/fonts/HarmoniaSansStd-Black.otf') format('embedded-opentype'), /* IE6-IE8 */
         url('../../../assets/fonts/HarmoniaSansStd-Regular.otf') format('woff2'), /* Super Modern Browsers */
         url('../../../assets/fonts/HarmoniaSansStd-Regular.otf') format('woff'), /* Modern Browsers */
         url('../../../assets/fonts/HarmoniaSansStd-Regular.otf') format('truetype'), /* Safari, Android, iOS */
         url('../../../assets/fonts/HarmoniaSansStd-Regular.otf') format('svg'); /* Legacy iOS */
}

body {
    font-family: 'HarmoniaSansStd';
}

.Modal, .ModalDoctor {
    position: fixed;
    z-index: 500;
    width: 100%;
    padding: 10px 0px 16px 0px;
    left: 15%;

    box-sizing: border-box;
    transition: all 0.3s ease-out;
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 2px 5px 20px 0px;
    border-radius: 4px;
}

.Modal{
    top: 20%;
}

.label_style{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    color: gray;
    font-weight: bold;
}

.span_style{
    padding-top: 5px
}

.Accept_chats_text{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 14px;
    color: rgb(66, 77, 87);
    cursor: pointer;
    padding: 10px 16px;
    text-decoration: none;
}

.lc_switch__input {
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
}

input[type='checkbox'], input[type='radio'] {
    margin-left: 10px;
    box-sizing: border-box;
    padding: 0;
    *height: 13px;
    *width: 13px;
}

.lc_switch__container {
    position: absolute;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.lc_switch__track {
    -webkit-box-flex: 1;
    flex: 1;
    height: 100%;
    border-radius: 24px;
    -webkit-transition-property: background-color;
    transition-property: background-color;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-timing-function: cubic-bezier(.33,0,.67,1);
    transition-timing-function: cubic-bezier(.33,0,.67,1);
}

[class^=lc-], [class^=lc-]:after, [class^=lc-]:before {
    box-sizing: inherit;
}

.lc_switch {
    position: relative;
    display: inline-block;
    outline: none;
    width: 25px;
    height: 16px;
    display: flex;
    position: absolute;
    top: 50%;
    right: 16px;
    -webkit-box-pack: center;
    justify-content: center;
    min-width: 18px;
    transform: translateY(-50%);
}

.main_view {
    width: 100%;
    padding-top: 4px;
}

.row_style{
    width:100%;
}

.border_bottom{
    padding-top: 10px;
    border-bottom: 1px solid black;
    border-color: rgb(235, 235, 235);
    font-weight: bold;
}

.btn_text {
    text-align: center;
    color: #ffffff;
    font-weight: 'bold';
    font-size: 18;
}

.icon_image_view {
    display: flex;
    margin-bottom: 4px;
    box-sizing: border-box;
    padding: 10px 16px;
    text-decoration: none;
}

.icon_image {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    flex: 0 0 40px;
    border: 1px solid #85479f;
}

.profile_detail_view {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: center;
    margin-left: 14px;
    max-width: 100%;
    overflow: hidden;
}

.profile_detail_view h3 {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    color: rgb(46, 46, 46);
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0px;
    overflow: hidden;
}

.profile_detail_view span{
    font-size: 12px;
    color: rgb(102, 102, 102);
    cursor: pointer;
}

.logout_view {
    width: 100%;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.logout_button {
    width: 90%;
    justify-content: center;
    position: relative;
    display: flex;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    line-height: 17px;
    font-family: 'HarmoniaSansStd';
    padding: 15px;
    margin: 10px 10px;
    text-decoration: none;
    background-color: #884a9d;
    border-radius: 5px;
    border-color: transparent;
}

.spinner_view {
    justify-content: center;
    position: relative;
    display: flex;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    padding: 7px;
    margin: 10px 10px;
    text-decoration: none;
    background-color: #884a9d;
}

@media (min-width: 600px) {
    .Modal, .ModalDoctor {
        position: fixed;
        white-space: nowrap;
        left: 58px;
        display: initial;
        margin-left: 7px;
        width: 230px;
        height: 193px;
    }
    .ModalDoctor {
        bottom: 50px !important;
        height: 285px !important;
    }
    .Modal {
        top: 78.5% !important;
    }
}
