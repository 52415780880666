.main {
    width: 100%;
    height: 100%;
    flex-direction: column;
    display: flex;
}

.heading {
    position: relative;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    height: 51px;
    color: rgb(66, 77, 87);
    font-weight: 700;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    background: none;
    outline: none;
}

.heading a {
    color: rgb(66, 77, 87);
    text-align: center;
    width: 100px;
    transition: color 0.25s ease 0s;
    text-decoration: none;
    outline: none !important;
}

.heading a:active,
.heading a.active {
    border-bottom: 5px solid #4384f5;
}

.header {
    width: 100%;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgb(221, 226, 230);
    height: 52px;
    text-align: center;
    display: flex;
    font-size: 18px;
    font-weight: 400;
    color: rgb(66, 77, 87);
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
}

.header_red {
    width: 100%;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-bottom: 1px solid rgb(221, 226, 230);
    background-color: red;
    height: 52px;
    text-align: center;
    display: flex;
    font-size: 18px;
    color: rgb(244, 246, 248);
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
}

.body {
    width: 100%;
    height: 100%;
    align-items: center;
}

.main_body {
    height: 54px;
    padding: 0 20px;
    border-bottom: 1px solid rgb(221, 226, 230);
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
}

.table_heading {
    position: relative;
    display: inline-block;
    color: rgba(66, 77, 87, 0.8);
    font-size: 12px;
    font-weight: 400;
    user-select: none;
    transition: padding 0.1s linear 0s;
}

.table_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
}

.table_content thead {
    display: table-header-group;
}

.table_content thead tr th:first-child {
    width: 110px;
    text-align: center;
}

.table_content thead tr th:last-child {
    text-align: center;
}

.table_heading_last {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    color: rgba(66, 77, 87, 0.8);
    font-size: 12px;
    font-weight: 400;
    user-select: none;
    transition: padding 0.1s linear 0s;
    padding: 0px 10px;
}

.table_heading_span {
    padding: 0px 10px;
}

.table_heading_span_first {
    padding: 0px 30px;
}

.availibility {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px 0px;
    margin-right: 29px;
    margin-left: -39px;

}

.table_content thead tr th {
    height: 35px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.3);
    text-transform: uppercase;
    position: relative;
    vertical-align: middle;
    text-align: left;
    padding: 0px 10px;
}

.table_column {
    text-align: center !important;
}

.table_column_name_email {
    text-align: left !important;
}

.table_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
}

.table_header {
    background-color: rgb(255, 255, 255);
    table-layout: fixed;
    width: 100%;

}

.list_view {
    height: 100%;
    overflow: auto;
}

.item {
    background-color: #ffffff;
    cursor: auto;
}

.table_view {
    position: relative;
    width: 100%;
    table-layout: fixed;
    width: 100%;
    line-height: 1.4;
}

.table_header {
    position: relative;
    display: inline-block;
    color: rgba(66, 77, 87, 0.8);
    user-select: none;
    padding-left: 18px;
}

.icon_image {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    border-width: 2px;
    border-style: solid;
    border-color: rgb(241, 246, 248);
    border-image: initial;
    border-radius: 50%;
}

.online_image_style {
    display: block;
    background-size: cover;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 1000px;
}

.offline_image_style {
    display: block;
    background-size: cover;
    background-image: url('../../assets/offline.jpeg');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 1000px;
}

.empty_list_text {
    display: flex;
    justify-content: center;

}

.online_style {
    background-color: rgb(70, 183, 118);
    border-width: 2px;
    border-style: solid;
    border-color: rgb(241, 246, 248);
    border-image: initial;
    position: absolute;
    display: block;
    box-sizing: border-box;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    right: -2px;
    top: -2px;
}

.search_button {
    cursor: pointer;
    padding: 5px 15px;
    margin-left: 5px;
    border-radius: 8px;
    font-weight: bold;
}

.search_input {
    padding: 5px 15px 5px 5px;
}

.load_more {
    position: absolute;
    right: 0;
    cursor: pointer;
    background-color: #4384f5;
    margin-bottom: 100px;
    margin-top: 10px;
    margin-right: 5px;
    padding: 5px 15px;
    border-radius: 8px;
    color: white;
}

.search_bar {
    position: absolute;
    right: 0;
    margin-right: 5px;

}

.offline_style {
    border-width: 2px;
    border-style: solid;
    border-color: rgb(241, 246, 248);
    border-image: initial;
    position: absolute;
    display: block;
    box-sizing: border-box;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    right: -2px;
    top: -2px;
}

.offline_style {
    background-color: rgb(169, 174, 184);
}

.doctor_content {
    margin-bottom: 1px;
    color: rgb(66, 77, 87);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.doctor_email {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: gray;
    font-size: 1;
    display: flex;
    margin-bottom: 1px;
    font-size: 15px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.username_text {
    font-weight: bold;
    color: rgb(66, 77, 87);
    font-size: 14px;
    text-align: left;
}

.email_text {
    font-size: 14px;
    color: rgb(66, 77, 87);
    text-align: left;
}

.ban_button {
    background-color: rgb(70, 116, 153);;
    display: inline-block;
    height: 21px;
    width: fit-content;
    line-height: 20px;
    color: rgb(255, 255, 255);
    font-size: 13px;
    vertical-align: middle;
    margin-left: 15px;
    border-radius: 5px;
    padding: 0px 10px !important;
}

.searchDivRow {
    display: flex;
    justify-content: center;
    margin-top: 20%;
    flex-direction: row;
}

.searchDivCol {
    display: flex;
    flex-direction: column;
}

.search_input_main {
    outline: none;
    border: 1px solid #ccc;
    background-color: white;
    font: inherit;
    padding: 8px 10px;
    height: 35px;
    display: block;
    width: 250px;
    box-sizing: border-box;
    border-radius: 25px;
}

.search_button_main {
    background-color: rgb(58, 52, 60);
    justify-content: center;
    text-align: center;
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    font: inherit;
    padding: 10px 20px 10px 20px;
    margin: 10px;
    font-weight: bold;
    border-radius: 25px;
    color: #ffffff;

}

.unban_button {
    background-color: rgb(141, 29, 44);
    display: inline-block;
    height: 21px;
    width: fit-content;
    line-height: 20px;
    color: rgb(255, 255, 255);
    font-size: 13px;
    vertical-align: middle;
    margin-left: 15px;
    border-radius: 5px;
    padding: 0px 10px !important;
}

.accepting_chat {
    background-color: green;
    display: inline-block;
    height: 21px;
    width: fit-content;
    line-height: 20px;
    color: rgb(255, 255, 255);
    font-size: 13px;
    vertical-align: middle;
    margin-left: 15px;
    border-radius: 20px;
    padding: 0px 10px !important;
}

.not_accepting_chat {
    background-color: rgb(245, 89, 35);
    display: inline-block;
    height: 21px;
    width: fit-content;
    line-height: 20px;
    color: rgb(255, 255, 255);
    font-size: 13px;
    vertical-align: middle;
    margin-left: 15px;
    border-radius: 20px;
    padding: 0px 7px;
}

