.main {
    width: 100%;
    height: 100%;
    flex-direction: column;
    display: flex;
}

.header {
    width: 100%;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgb(221, 226, 230);
    height: 52px;
    text-align: center;
    display: flex;
    font-size: 18px;
    font-weight: 400;
    color: rgb(66, 77, 87);
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
}

.header_red {
    width: 100%;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-bottom: 1px solid rgb(221, 226, 230);
    background-color: red;
    height: 52px;
    text-align: center;
    display: flex;
    font-size: 18px;
    color: rgb(244, 246, 248);
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
}

.body {
    width: 100%;
    height: 100%;
    align-items: center;
}

.main_body {
    height: 54px;
    padding: 0 20px;
    border-bottom: 1px solid rgb(221, 226, 230);
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
}

.heading {
    position: relative;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    height: 51px;
    color: rgb(66, 77, 87);
    cursor: pointer;
    font-weight: 700;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    background: none;
    outline: none;
}

.heading a {
    color: rgb(66, 77, 87);
    text-align: center;
    width: 100px;
    transition: color 0.25s ease 0s;
    text-decoration: none;
    outline: none !important;
}

.heading a:active,
.heading a.active {
    border-bottom: 5px solid #4384f5;
}

.table_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
}

.table_header {
    background-color: rgb(255, 255, 255);
    table-layout: fixed;
    width: 100%;

}

.table_content thead {
    display: table-header-group;
}

.table_content thead tr th:first-child {
    width: 110px;
    text-align: center;
}

.table_content thead tr th {
    height: 35px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.3);
    text-transform: uppercase;
    position: relative;
    vertical-align: middle;
    text-align: left;
    padding: 0px 10px;
}

.table_heading {
    position: relative;
    cursor: pointer;
    user-select: none;
    padding-left: 0px;
    display: block;
    height: 35px;
    font-size: 12px;
    line-height: 35px;
    color: rgba(66, 77, 87, 0.6);
    box-sizing: border-box;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-transform: uppercase;
    transition: padding 0.1s linear 0s;
    overflow: hidden;
}

.list_view {
    height: 100%;
    overflow: auto;
}

.table_view {
    position: relative;
    width: 100%;
    table-layout: fixed;
}

.table_header {
    position: relative;
    display: inline-block;
    color: rgba(66, 77, 87, 0.8);
    cursor: pointer;
    user-select: none;
    padding-left: 18px;
    transition: padding 0.1s linear 0s;
}

.tr_view {
    position: relative;
    width: 1430px;
    height: 50px;
    max-width: 1430px;
    max-height: 50px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: background-color 0.25s ease 0s;
}

.selectable {
    position: relative;
    cursor: pointer;
}

.selected {
    height: 50px;
    max-height: 50px;
    overflow: hidden;
    position: relative;
    border-bottom: 1px solid rgb(221, 226, 230);
}

.header_content {
    width: 1430px;
    height: 35px;
    max-width: 1430px;
    max-height: 35px;
    overflow: hidden;
    position: relative;
    border-bottom: 1px solid rgb(221, 226, 230);
}

.name_header {
    height: 35px;
    left: 20px;
    position: absolute;
    top: 0px;
    width: 200px;
}

.nameView {
    height: 50px;
    left: 0px;
    position: absolute;
    flex-direction: row;
    top: 0px;
    width: 200px;
}

.email_header {
    height: 35px;
    left: 200px;
    position: absolute;
    top: 0px;
    width: 200px;
}

.emailView {
    height: 50px;
    left: 200px;
    position: absolute;
    top: 0px;
    width: 200px;
}

.user_email {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    color: rgb(66, 77, 87);
}

.actions_header {
    height: 35px;
    left: 400px;
    position: absolute;
    top: 0px;
    width: 200px;
}

.actions_view {
    height: 50px;
    left: 400px;
    position: absolute;
    top: 0px;
    width: 200px;
}

.actions_sub_view {
    width: 100%;
    height: 50px;
    font-size: 14px;
    color: rgb(66, 77, 87);
}

.button {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
}

.actions_button {
    display: inline-block;
    height: 28px;
    max-width: 160px;
    font-size: 14px;
    white-space: nowrap;
    background-color: rgb(66, 77, 87);
    color: rgb(255, 255, 255);
    flex: 1 1 0%;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    border-radius: 4px;
    outline: none;
}

.owner_actions_button {
    display: inline-block;
    height: 28px;
    max-width: 160px;
    font-size: 14px;
    white-space: nowrap;
    background-color: rgb(221, 226, 230);
    color: rgb(66, 77, 87);
    flex: 1 1 0%;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    border-radius: 4px;
    outline: none;
}

.queued_button {
    display: inline-block;
    height: 28px;
    max-width: 160px;
    font-size: 14px;
    white-space: nowrap;
    background-color: rgb(70, 183, 118);
    color: rgb(255, 255, 255);
    flex: 1 1 0%;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    border-radius: 4px;
    outline: none;
}

.activity_header {
    height: 35px;
    left: 600px;
    position: absolute;
    top: 0px;
    width: 150px;
}

.chatting_header {
    height: 35px;
    left: 750px;
    position: absolute;
    top: 0px;
    width: 200px;
}

.activity_view {
    height: 50px;
    left: 600px;
    position: absolute;
    top: 0px;
    width: 150px;
}

.activity_sub_view {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    color: rgb(66, 77, 87);
}

.activity_status_icon {
    position: absolute;
    display: block;
    top: 50%;
    left: 0px;
    content: "";
    width: 8px;
    height: 8px;
    transform: translate(0px, -50%);
    border-radius: 50%;
}

.activity_list {
    position: relative;
    padding-left: 15px;
    font-size: 16px;
}

.activity_text {
    color: rgb(76, 183, 126);
}

.activity_icon_color {
    background-color: rgb(76, 183, 126);
}

.left_activity_text {
    color: rgba(66, 77, 87, 0.3);
}

.left_activity_icon_color {
    background-color: rgba(66, 77, 87, 0.3);
}

.queued_activity_text {
    color: rgba(66, 77, 87, 0.8);
}

.queued_activity_icon_color {
    background-color: rgba(66, 77, 87, 0.8);
}

.chatting_view {
    height: 50px;
    left: 750px;
    position: absolute;
    top: 0px;
    width: 200px;
}

.chatting_sub_view {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    color: rgb(66, 77, 87);
}

.chatting_icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    border-width: 2px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 50%;
}

.chatting_image {
    display: block;
    background-size: cover;
    background-image: url(//cdn.livechatinc.com/cloud/?uri=https://livechat.s3.amazonaws.com/default/avatars/female_23.jpg);
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 1000px;
}

.chatting_icon_online {
    width: 10px;
    height: 10px;
    right: -3px;
    top: -3px;
    background-color: rgb(70, 183, 118);
    border-width: 2px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
}

.chatting_name {
    padding-left: 4px;
}

.chats_count_header {
    height: 35px;
    left: 1000px;
    position: absolute;
    top: 0px;
    width: 130px;
}

.chats_main_view {
    height: 50px;
    left: 1025px;
    position: absolute;
    top: 0px;
    width: 130px;
}

.chats_count_view {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    color: rgb(66, 77, 87);
}

.chat_count_status {
    display: block;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.mainNameView {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    margin-left: 20px;
    color: rgb(66, 77, 87);
}

.subView {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
}

.content {
    position: relative;
    display: inline-block;
    width: 44px;
    line-height: 44px;
    background-color: rgb(244, 247, 249);
    box-sizing: border-box;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    border-width: 2px;
    border-style: dashed;
    border-color: rgb(196, 206, 214);
    border-image: initial;
}

.plusIcon {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 14px;
    height: 14px;
    line-height: 14px;
}

.selected_mark {
    width: 4px;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    display: block;
    content: "";
    transition: width 0.25s ease 0s;
}

.icon_image {
    position: relative;
    font-weight: normal;
    text-align: center;
    box-sizing: border-box;
    opacity: 1;
    font-size: 13px;
    line-height: 30px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transition: background-color 0.5s ease 0s;
}

.icon_image_letter {
    display: block;
    color: rgb(255, 255, 255);
    overflow: hidden;
    border-radius: 50%;
    background: rgb(116, 84, 128);
}

.online_image_style {
    display: block;
    background-size: cover;
    background-image: url('../../assets/download.png');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 1000px;
}

.offline_image_style {
    display: block;
    background-size: cover;
    background-image: url('../../assets/offline.jpeg');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 1000px;
}

.online_style {
    background-color: rgb(70, 183, 118);
    border-width: 2px;
    border-style: solid;
    border-color: rgb(241, 246, 248);
    border-image: initial;
    position: absolute;
    display: block;
    box-sizing: border-box;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    right: -2px;
    top: -2px;

}

.offline_style {
    background-color: rgb(169, 174, 184);
    border-width: 2px;
    border-style: solid;
    border-color: rgb(241, 246, 248);
    border-image: initial;
    position: absolute;
    display: block;
    box-sizing: border-box;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    right: -2px;
    top: -2px;

}

.role {
    display: inline-block;
    height: 21px;
    width: fit-content;
    line-height: 20px;
    color: rgb(255, 255, 255);
    background-color: rgb(245, 89, 35);
    font-size: 13px;
    vertical-align: middle;
    margin-left: 15px;
    border-radius: 20px;
    padding: 0px 7px;
}

.admin {
    background-color: rgb(245, 89, 35);
    display: inline-block;
    height: 21px;
    width: fit-content;
    line-height: 20px;
    color: rgb(255, 255, 255);
    font-size: 13px;
    vertical-align: middle;
    margin-left: 15px;
    border-radius: 20px;
    padding: 0px 7px;
}

.doctor_content {
    padding-left: 15px;
    font-weight: bold;
    min-width: 0px;
    text-overflow: ellipsis;
    flex: 1 1 0%;
    overflow: hidden;
}

.invite {
    font-size: 16px;
    color: rgb(67, 132, 245);
}

.Auth {
    width: 100%;
    height: 350px;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    padding: 10px;
}

.cross {
    flex: 1;
    flex-direction: row;
    color: rgba(0, 0, 0, 0.7);
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 10px;
    border-bottom: 1px solid rgb(221, 226, 230);
}

.add {
    text-align: center;
}

.close {
    float: right;
}

.error {
    font-size: 13px;
    color: #d60b59;
}
