.search_button{
  margin-left: 8px;
  border: 1px #e5e5ea solid;
  padding: 1px 5px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.header{
  margin-top: 50px;
  margin-bottom: 20px;
  margin-left: 30px;
  display: flex;
}
.stats{
  margin-bottom: 50px;
  margin-left: 30px;
  display: flex;
  flex-direction: column;

}
.container{
  display: flex;
  width: 100%;
  height: 100%;
  /*align-items: center;
  justify-content: center;*/
  min-height: 700px;
  flex-direction: column;
}

