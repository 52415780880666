.Button {
    background-image: -webkit-linear-gradient(left,#85479f -25%,#d36797 125%)!important;
    justify-content: center;
    text-align: center;
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    font: inherit;
    padding: 10px 20px 10px 20px;
    margin: 10px;
    font-weight: bold;
    border-radius: 25px;
}

.Button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.Success {
    color: #ffffff;
}

.Danger {
    color: #944317;
}