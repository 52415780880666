.title{
    outline: none;
    
    /* background-color: white; */
  
    /* box-sizing: border-box; */
    text-align: center;
    vertical-align:middle;
    justify-self: center;
    align-self: center;
    font-weight: bold;
    font-size: 15;
    color:#884a9d;
    font-family: 'HarmoniaSansStd';
}
.container {
    /* flex: 1 1; */
    padding: 10px;
    height: 45px;
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    align-items: center;
    justify-items: center;
    justify-content: space-between;
    align-content: center;
    border-bottom: 1px solid #979797;
    background-color: white;
    z-index: 1;
    position: absolute;


    left:0;
top:0;
right:0;
}
.container p{
      font-family: 'HarmoniaSansStd';
    /* width: 100%; */
    align-items: center;
    justify-content: center;
    align-content: center;
   
  
}
.headerOption{
    padding: 10px;
    height: 45px;
    width:20%;
    display: flex;
    flex-direction: row;
    color: #884a9d;
   font-size: 15px;
    align-items: center;
    justify-items: center;
}
