@font-face {
    font-family: 'HarmoniaSansStd';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/HarmoniaSansStd-Regular.otf'); /* IE9 Compat Modes */
    src: local('HarmoniaSansStd'), local('HarmoniaSansStd'),
         url('./fonts/HarmoniaSansStd-Black.otf') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/HarmoniaSansStd-Regular.otf') format('woff2'), /* Super Modern Browsers */
         url('./fonts/HarmoniaSansStd-Regular.otf') format('woff'), /* Modern Browsers */
         url('./fonts/HarmoniaSansStd-Regular.otf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/HarmoniaSansStd-Regular.otf') format('svg'); /* Legacy iOS */
}

body {
    font-family: 'HarmoniaSansStd';
}


.container {
    display: block;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.content {
    margin: 20% 0%;
    justify-content: center;
    text-align: center;
}

.form_view {
    margin: 10% 10%;
    display: block;
    flex-flow: row wrap;
}

.sub_form_view {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-flow: row wrap;
}

.ClinicSmallInputElement {
    width: 100%;
    outline: none;
    border: 0.5px solid #111111;
    background-color: white;
    font-size: 15px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    color: '#454245';
}

.ClinicSmallInputRightElement {
    width: 100%;
    outline: none;
    border: 0.5px solid #111111;
    background-color: white;
    font-size: 15px;
    padding: 10px;
    margin-left: 20px;
    box-sizing: border-box;
    border-radius: 5px;
    color: '#454245';
}

.logo {
    background-size: cover;
    height: 100%;
    width: 100%;
    background-position: center center;
}

.logo_container {
    width: 250px;
    margin-top: 50px;
    margin-bottom: 15px;
}

.main_view {
    position: 'relative';
}

.heading {
    width: 100%;
    font-size: 25px;
    font-weight: bolder;
    margin-top: 20px;
    margin-bottom: 15px;
    font-family: 'HarmoniaSansStd';
}

.center_heading {
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 40px;
    font-weight: bolder;
    font-family: 'HarmoniaSansStd';
}

.sub_heading {
    line-break: auto;
    font-size: 15px;
    font-family: 'HarmoniaSansStd';
    margin: 20px 40px;
}

.form_container {
    background-color: #ffffff;
    margin: 2% 10%;
    border-radius: 5px;
    height: 100%;
}

.form_header {
    height: 80px;
    display: flex;
    border-bottom: 1px solid #111111;
    justify-content: flex-start;
    padding-left: 30px;
    align-items: center;
    font-size: 20px;
    font-family: 'HarmoniaSansStd';
    font-weight: 1000px;
}

.form_content {
    display: flex;
}

.side_container {
    width: 22%;
    border-right: 1px solid #111111;
    justify-content: center;
    align-items: center;
}
.sidebar_container {

    width: 100%;
    height: 100%;
    background-color: rgba(185, 185, 185, 0.8);
    position:fixed;
    z-index: 10 !important;
    top: 0;

}
.sidebar_wrapper{
    overflow: scroll;
    height: inherit;
    padding-top: 65px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}
.sidebar_btn{
    /* display: flex; */
    /* flex: 1; */
    width: -webkit-fill-available;
    background-color: rgb(136, 74, 157);
    border-radius: 5px;
    border: 1px;
    color: rgb(255, 255, 255);
    font-size: 14px;
    /* margin: 40px; */
    padding-top: 6px;
    padding-bottom: 6px;
    justify-content: center;
}
.sidebar_transparent_btn{
    /* display: flex; */
    /* flex: 1; */
    width: -webkit-fill-available;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    border-width: 1px;
    border-color: #884a9d;
    color:#884a9d;
    font-size: 14px;
    /* margin: 40px; */
    justify-content: center;
    padding-top: 6px;
    padding-bottom: 6px;
}
.sidebar_items_container{

    /* position: fixed; */
    z-index: 10 !important;
    top: 0;
    /* left: 0; */
    /* shape-outside: border-box; */
    /* box-shadow: black; */
    width: 270px;
    /* max-width: 70%; */
    height: 100%;
    margin-right: 0px;
    position: fixed;
    right: 0;
    /* box-shadow: -200px 0px 20px 20px rgb(0 0 0 / 20%); */
    background-color: #ffffff;
}
.sidebar_items_wrapper{
    position: relative;
    display: flex;
    flex: 1;
    height: 100%;
}
.sidebar_logout_btn_wrapper{
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 10px;
    width: 100%;
    padding-bottom: 85px;
    /* align-self: flex-end; */
    /* justify-self: end; */
    /* justify-self: end;*/
}
.sidebar_logout_btn{
    /* width: 170px;
    background-color: rgb(255, 255, 255);
    color: rgb(136, 74, 157);
    box-shadow: none;
    place-self: center; */
    height: 45px;
    height: 40px;
    display: flex;
    /* flex: 1; */
    width: '-webkit-fill-available';
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    border: 1px;

    font-size: 15px;
    /* font-weight: ; */
    /* justify-content: space-around; */
    align-items: center;
    /* margin: 40px; */
    /* justify-content: center;*/
}
.sidebar_item{
    display: flex;

     width: 100%;
    height: 25px;
    padding: 10px;
    margin-left: 5px;
    justify-content: space-between;
    padding-right: 20px;
    font-family: 'HarmoniaSansStd';
    font-weight: bold;
    color: #884a9d;
   border-bottom: 1px solid #d1d3d1;
}
.side_item_container {
    padding: 30px 20px;
    font-size: 18px;
    font-family: 'HarmoniaSansStd';
    font-weight: 900px;
    /* border-left: 2px solid #884a9d; */
    border-bottom: 1px solid #111111;
    cursor: pointer;
}

.side_item_selected {
    padding: 30px 20px;
    font-size: 18px;
    font-family: 'HarmoniaSansStd';
    font-weight: 900px;
    border-left: 3px solid #884a9d;
    color: #884a9d;
    border-bottom: 1px solid #111111;
    cursor: pointer;
}

.main_container {
    width: 80%;
    height: 100%;
    flex-direction: row;
    overflow: scroll;
}

.main_container_header {
    font-size: 18px;
    font-weight: bold;
    font-family: 'HarmoniaSansStd';
    margin: 30px 20px;
    flex-direction: column;
}

.main_container_heading {
    font-size: 16px;
    font-weight: normal;
    font-family: 'HarmoniaSansStd';
    margin: 30px 20px;
    flex-direction: column;
}

.form_container_view {
    margin: 20px 50px;
    flex-direction: column;
}

.form_row {
    margin: 20px 0px;
    display: flex;
    align-items: center;
}

.center_view, .center_view_error{
    margin: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.center_view span{
    font-size: 20px;
    font-weight: 500;
    color: #884a9d;
    margin: 10px;
    font-family: 'HarmoniaSansStd';
}
.center_view_error span{
    font-size: 20px;
    font-weight: 500;
    color: red;
    margin: 10px;
    font-family: 'HarmoniaSansStd';
}
.form_bottom_row {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
}

.form_bottom_item {
    padding: 5px 0px;
}

.form_heading {
    font-size: 16px;
    font-weight: normal;
    font-family: 'HarmoniaSansStd';
    margin: 0px 10px;
    flex-direction: row;
    width: 100%;
    display: block;
}

.check_box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    margin-left: 27%;
    margin-bottom: 10px;
    width: 75%;
}

.span_style {
    margin-left: 10px;
    margin-top: 3px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    font-family: 'HarmoniaSansStd';
}

.checkbox_style {
    width: 20px;
    height: 20px;
}

.form_item_left {
    width: 61%;
    display: block;
}

.form_item_right {
    width: 35%;
    display: block;
}

.button_row {
    margin: 30px 17px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.label {
    width: 23%;
    margin: 0px 20px;
    font-size: 18px;
    font-weight: bold;
    font-family: 'HarmoniaSansStd';
}

.form_input {
    text-align: center;
    font-size: 18px;
    padding: 0px 10px;
    border-radius: 5px;
    border: 1px solid #111111;
    height: 50px;
    width: 70%;
    font-family: 'HarmoniaSansStd';
}
.invitePatientRadioButton{
    justify-content: center;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.marginRight5{
    margin-right: 5px;
}

.readOnly_input {
    text-align: center;
    font-size: 18px;
    padding: 0px 10px;
    border-radius: 5px;
    border: 1px solid #111111;
    height: 50px;
    width: 70%;
    color: #979797;
    background-color: #F1F2F6;
    font-family: 'HarmoniaSansStd';
}

.form_medium_input {
    text-align: center;
    font-size: 18px;
    padding: 0px 30px;
    border-radius: 5px;
    border: 1px solid #111111;
    height: 50px;
    width: 30%;
    font-family: 'HarmoniaSansStd';
}

.form_small_input {
    text-align: center;
    font-size: 18px;
    padding: 0px 10px;
    border-radius: 5px;
    border: 1px solid #111111;
    height: 50px;
    width: 19.5%;
    font-family: 'HarmoniaSansStd';
}

.form_small_adjust_input {
    text-align: center;
    font-size: 18px;
    padding: 0px 10px;
    border-radius: 5px;
    border: 1px solid #111111;
    height: 50px;
    width: 22.5%;
    font-family: 'HarmoniaSansStd';
}

.adjustable_form_input {
    margin: 0px 10px;
    font-size: 18px;
    padding: 0px 20px;
    border-radius: 5px;
    border: 1px solid #111111;
    height: 50px;
    width: 55%;
    font-family: 'HarmoniaSansStd';
}

.adjustable_form_small_input {
    margin: 0px 10px;
    font-size: 18px;
    padding: 0px 20px;
    border-radius: 5px;
    border: 1px solid #111111;
    height: 50px;
    width: 32.5%;
    font-family: 'HarmoniaSansStd';
}

.hidden_input {
    display: none;
}

.unhiglighted_button {
    width: 200px;
    height: 50px;
    background-color: #ffffff;
    color: #884a9d;
    border-radius: 10px;
    margin-right: 5px;
    border: 1px solid #884a9d;
    font-size: 20px;
    font-family: 'HarmoniaSansStd';
}

.higlighted_button {
    width: 200px;
    height: 50px;
    background-color: #884a9d;
    color: #ffffff;
    border-radius: 10px;
    margin-left: 5px;
    border: 1px solid #ffffff;
    font-size: 20px;
    font-family: 'HarmoniaSansStd';
}
.higlighted_button_centered {
    width: 200px;
    height: 50px;
    background-color: #884a9d;
    color: #ffffff;
    border-radius: 10px;
    margin-left: 5px;
    border: 1px solid #ffffff;
    font-size: 20px;
    font-family: 'HarmoniaSansStd';
    display: flex;
    justify-content: center;
}

.image_container {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #000000;
    margin-right: 20px;
}

.column_view {
    flex-direction: column;
    display: grid;
}

.column_view span {
    font-size: 12px;
    font-family: 'HarmoniaSansStd';
    margin-top: 3px;
}

.signature_container {
    width: 200px;
    height: 200px;
    border: 1px solid #000000;
    margin-right: 20px;
    margin-left:  40px;
}

.underline_password_label {
    width: 20%;
    font-size: 20px;
    font-weight: bold;
    font-family: 'HarmoniaSansStd';
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
}

.underline_label {
    font-size: 20px;
    font-weight: bold;
    font-family: 'HarmoniaSansStd';
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
}

.break_view {
    margin: 5px;
}

.input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 0.5px solid #111111;
    font-size: 15px;
    color: '#a1a1a1';
    font-family: 'HarmoniaSansStd';
}

.bottom_center_view {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.bottom_text {
    font-size: 15px;
    font-weight: 450;
    font-family: 'HarmoniaSansStd';
}

.bottom_text_button {
    color: #884a9d;
    font-size: 16px;
    font-weight: 450;
    font-family: 'HarmoniaSansStd';
}

.button_view {
    flex: 1;
}

.bottom_view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    /* align-self:flex-end;
    justify-self:flex-end; */
}

.bottom_view p {
    font-family: 'HarmoniaSansStd';
    font-size: 15px;
    font-weight: 450;
}

.button {
    /* float: right; */
    color: #ffffff;
    background: #884a9d;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 10px;
    font-size: 15px;
    font-weight: bolder;
    align-self:flex-end;
    width: 50%;
    box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 30%);
    /* justify-self:flex-end; */
    font-family: 'HarmoniaSansStd';
    border: 1px solid #884a9d;
}

.white_button {
    float: right;
    color: #884a9d;
    background: #ffffff;
    border-radius: 5px;
    padding: 15px 50px;
    margin: 10px 10px;
    font-size: 18px;
    font-weight: bolder;
    font-family: 'HarmoniaSansStd';
    border: 1px solid #884a9d;
}

.transparent_button {
    background-color: transparent;
    border-color: transparent;
    color: #884a9d;
    font-size: 15px;
    align-self:flex-end;
    font-weight: 500;
    font-family: 'HarmoniaSansStd';
}

.get_started {
    background-color: transparent;
    border-color: transparent;
    color: #884a9d;
    font-size: 15px;
    font-weight: 450;
    /* margin-left: -4px;
    margin-right: -4px; */
    font-family: 'HarmoniaSansStd';
}

.error_container {
    display: flex;
    border: 0.5px solid #111111;
    border-radius: 5px;
    margin: 10px;
    /* border-left-color: #E02021; */
    /* border-left-width: 5px; */
    /* justify-content: first baseline; */
}

.absolute_success_bar {
    display:block;
    width: 6px;
    /* height: 100%; */
    background-color: #12a12a;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.absolute_error_bar {
    display:block;
    width: 6px;
    /* height: 100%; */
    background-color: #E02021;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.error {
    flex: 1;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #454245;
    padding: 5px;
    font-family: 'HarmoniaSansStd';
}

.underline_text {
    flex: 1;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #454245;
    text-decoration: underline;
    font-family: 'HarmoniaSansStd';
}

.main {
    display: flex;
    height: 100%;
/* padding-top: 45px; */
/* margin-top:65px; */
    flex-direction: column;
    background-color: #F1F2F6;
}

.header{
    margin: 2% 10%;
    display: flex;
    height:80px;
    background-color: #111111;
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;
    padding: 0% 1%;
}

.header_doctor_name{
    margin-top: 14px;
    padding-top: 8px;
    font-weight: 700;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
    border-right: rgb(203, 212, 222) 1px solid;
    font-family: 'HarmoniaSansStd';
}

.headerText {
    font-size: 14px;
    color: #ffffff;
    margin-left: 40px;
    font-family: 'HarmoniaSansStd-Bold';
}

.sub_header {
    margin: 0% 0%;
    display: flex;
    /* height: 100%; */
    background-color: #ffffff;
    border-radius: 0px;
    align-items: center;
    justify-content: space-between;
    padding: 2% 1%;

}

.subHeaderText {
    font-size: 15px;
    color: #111111;
    margin: 10px;
    font-weight: bold;
    font-family: 'HarmoniaSansStd';
}

input[type='radio']:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #884a9d;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type=checkbox] {
    position: relative;
      cursor: pointer;
}

input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    top: 0;
    left: 0;
    /* border: 1px solid #111111; */
}

input[type=checkbox]:checked:before {
    content: "";
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    top: 0;
    left: 0;
    background-color:#884a9d;
    background-image: url("./check.png");
}

input[type=checkbox]:checked:after {
    overflow: visible;
    display: block;
    width: 5px;
    height: 5px;
    border: solid white;
    background-image: url("./check.png");
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 4px;
}

.main_body {
    width: 100%;
    height: 54px;
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    font-family: 'HarmoniaSansStd';
}

.header_bar {
    width: 100%;
    border-bottom: 1px solid #979797;
    position: relative;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(66, 77, 87);
    cursor: pointer;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    background: none;
    outline: none;
}

.header_bar span{
    color: rgb(66, 77, 87);
    text-align: center;
    width: 100%;
    transition: color 0.25s ease 0s;
    text-decoration: none;
    outline: none !important;
}

.active{
    font-size: 18px;
    font-weight: 700;
    border-bottom: 5px solid#884a9d;
    font-family: 'HarmoniaSansStd';
}

.un_active {
    font-size: 18px;
    font-weight: 500;
    border-bottom: 2px solid rgb(221, 226, 230);
    font-family: 'HarmoniaSansStd';
}

.form {
    width: 95%;
    margin: 20px 0px;
    flex-direction: column;
}

.form textarea {
    width: 100%;
    height: 100px;
    font-size: 18px;
    padding: 10px 20px;
    border: 1px solid #979797;
    border-radius: 10px;
    font-family: 'HarmoniaSansStd';
}

.form input {
    margin: 10px 0px;
    width: 100%;
    height: 30px;
    font-size: 18px;
    padding: 10px 20px;
    border: 1px solid #979797;
    border-radius: 10px;
    font-family: 'HarmoniaSansStd';
}

.inputItemLabel {
    margin: 0 10px;
    padding: 0 0;
    /* width: 90%; */
    font-size: 15px;
    color:#884a9d;
    font-weight: bold;
    font-family: 'HarmoniaSansStd';
}
.inputItem {
    margin: 5px 10px;
    padding: 8px 10px;
    /* width: 90%; */
    font-size: 15px;
    font-weight: bold;
    border: 1px solid #979797;
    border-radius: 5px;
    font-family: 'HarmoniaSansStd';
}
.readOnlyInputItem{
    margin: 5px 10px;
    padding: 8px 10px;
    /* width: 90%; */
    font-size: 15px;
    font-weight: bold;
    border: 1px solid #979797;
    border-radius: 5px;
    font-family: 'HarmoniaSansStd';
    color: #979797;
    background-color: #f1f2f6;
}
.form span {
    width: 100%;
    font-size: 18px;
    font-family: 'HarmoniaSansStd';
}

.response {
    clear: both;
}

.response_list {
    min-width: 100%;
}

.response_item {
    position: relative;
    width: 100%;
    border-top: 1px solid #979797;
    margin-bottom: 20px;
}

.response_item .view {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
}

.response_item .view span{
    position: relative;
    margin-right: 5px;
    flex: 1;
    font-size: 16px;
    color: #000000;
    font-family: 'HarmoniaSansStd';
}

.response_item .view .text{
    margin-bottom: 20px;
    font-size: 17px;
    flex: 1;
    color:#000000;
    word-wrap: break-word;
    display: contents;
    font-family: 'HarmoniaSansStd';
}

.response_item .view .tags{
    font-size: 13px;
    margin-top: 20px;
    flex: 1;
    margin-right: 270px;
    display: inline-block;
    font-family: 'HarmoniaSansStd';
}

.response_item .view .tags span{
    position: relative;
    margin-right: 5px;
    flex: 1;
    font-size: 14px;
    color: #000000;
    font-family: 'HarmoniaSansStd';
}

.response_item .view .tags .shortcut{
    position: relative;
    max-width: 100%;
    overflow-wrap: break-word;
    padding: 3px 7px;
    margin: 2px;
    font-size: 15px;
    color: #884a9d;
    line-height: 21px;
    background-color: #ffffff;
    text-shadow: none;
    display: inline;
    font-family: 'HarmoniaSansStd';
}

.response_item .view .tags .shortcut span{
    font-size: 18px;
    line-height: 21px;
    color: #884a9d;
    font-family: 'HarmoniaSansStd';
}

.response_item .view .tags .links{
    position: absolute;
    bottom: 20px;
    right: 185px;
}

.response_item .view .links span{
    font-size: 17px;
    margin-left: 10px;
    color: #884a9d;
    font-family: 'HarmoniaSansStd';
}

.response_item .view .links span:hover{
    text-decoration: underline;
}

.response_item_form {
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    transition: all 200ms linear;
    margin: 32px 0 4px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;

}

.response_item_field {
    float: left;
    margin: 0 24px 24px 0;
}

.response_item_field input{
    width: 100%;
    font-size: 14px;
    margin-right: 3px;
    line-height: 17px;
    color: rgba(66, 77, 87, 0.8);
    box-sizing: border-box;
    padding: 5px 8px;
    border-width: 1px;
    border-style: solid;
    border: 1px solid #979797;
    border-image: initial;
    border-radius: 4px;
    outline: none;
    font-family: 'HarmoniaSansStd';
}

.response_item_field textarea {
    float: left;
    width: 100%;
    height: 100px;
    max-width: 570px;
    min-width: 460px;
    min-height: 81px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 16px;
    line-height: 1.5;
    color: rgba(66, 77, 87, 0.8);
    border: 1px solid #979797;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
    font-family: 'HarmoniaSansStd';
}

.response_item_tag {
    clear: left;
}

.response_item_tag input{
    width: 80%;
    padding: 9px 8px;
    font-size: 15px;
    line-height: 21px;
    color: rgba(66, 77, 87, 0.8);
    border: 1px solid #979797;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
    font-family: 'HarmoniaSansStd';
}

.response_item_submit {
    float: left;
    margin: 0;
    position: relative;
    flex-direction: column;
}

.response_item_submit button {
    width: 170px;
    text-decoration: none;
    text-align: center;
    border: 0;
    cursor: pointer;
    border-radius: 4px;
    background-color: #884a9d;
}

.response_item_submit button span {
    padding: 0px 0px 10px;
    font-size: 15px;
    text-align: center;
    color: #ffffff;
    letter-spacing: 0.2px;
    font-family: 'HarmoniaSansStd';
}

.response_item_submit span{
    display: block;
    text-align: left;
    color: #979797;
    margin-top: 10px;
}


.text span {
    color: #fff;
    font-size: 12px;
    margin-right: 3px;
    line-height: 20px;
    text-shadow: none;
    padding: 0px 10px 0px 10px;
    background: #ff9800;
    word-break: break-all;
    display: inline-flex;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-family: 'HarmoniaSansStd';
}

.bottom_bar {
    border-top: 1px solid #979797;
}


.delete_view {
    margin-top: 100px;
    padding: 20px;
    overflow: hidden;
}

.cancel_delete_button{
    margin-left: 10px;
    text-decoration: none;
    outline: none !important;
    color: #884a9d;
    cursor: pointer;
    text-align: center;
}

.cancel_delete_button:hover {
    text-decoration: underline;
}

.confirm_remove {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: white;
    z-index: 1;
}

.wrapper {
    display: table;
    width: 100%;
    height: 100%;
}

.wrapper div{
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.wrapper div button {
    margin-right: 10px;
    color: #fff;
    background: #884a9d;
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
    position: relative;
    display: inline-block;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    border: 0;
    padding: 0;
    border-radius: 3px;
    cursor: pointer;
    font-family: 'HarmoniaSansStd';
}

.wrapper div button span {
    padding: 0 30px;
    border-radius: 3px;
    line-height: 38px;
    letter-spacing: 0.06em;
    font-size: 15px;
    text-transform: none;
    letter-spacing: 0.02em;
    font-family: 'HarmoniaSansStd';
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
}

.modalHeader {
    display: flex;
    text-align: center;
    margin: 10px 20px;
}

.modalBody {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    margin: 10px 40px;
    font-family: 'HarmoniaSansStd';
}

.modalBodyText {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin: 10px 40px;
    font-family: 'HarmoniaSansStd';
}

.modalFooter {
    text-align: center;
    margin: 20px 40px;
}

.footer {
    margin: 0% 0%;
    display: flex;
    /* height: 100%; */
    background-color: #ffffff;
    border-radius: 0px;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    position:fixed;
    height: 60px;
    z-index: 1;

left:0;
bottom:0;
right:0;

}

.footer a {
    font-size: 16px;
    font-weight: 450;
    background-color: transparent;
    border: transparent;
    padding: 5px;
    margin: 20px;
    text-align: center;
    color: #454245;
    font-family: 'HarmoniaSansStd';
}

.footer a:hover {
    font-size: 16px;
    font-weight: 450;
    background-color: transparent;
    border: transparent;
    padding: 5px;
    margin: 20px;
    text-align: center;
    color: #454245;
    text-decoration: none;
    font-family: 'HarmoniaSansStd';
}

.loading_loader_sm {
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 6px solid rgba(136, 74, 157, 0.6);
    position: relative;
    border-width: 4px;
    border-color: rgba(136, 74, 157, 0.6);
    border-top-color: white;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}



/* --------- */
.listContainer{
    display: flex;
    flex-direction: column;
    /* justify-items: normal; */
    /* width: 100%; */

    margin: 15px 0px;


}
.list{
    display: flex;
    flex-direction: row;
    /* justify-items: normal; */
    /* width: 100%; */
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;
    border-width: 1px;
    border: 1px solid #884a9d;
    background-color: #ffffff;
    box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 30%);
    margin: 10px 20px;
    padding: 10px 15px;

}
