.container {
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-font-smoothing: antialiased;
}

.heading {
    color: #000000;
}

.sub_heading {
    font-size: 16px;
    color: #000000;
}