.absolute_content {
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
}

.main {
    width: 100%;
    height: 100%;
}

.main_body {
    padding: 20px 15px;
}

.body {
    max-width: 1200px;
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    margin: 0px auto 20px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(203, 212, 222);
    border-image: initial;
    padding: 20px;
}

.body_container {
    position: relative;
}

.body_header {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    min-height: 34px;
    margin: 0px -20px;
    padding: 0px 20px 20px;
    border-bottom: 1px solid rgb(203, 212, 222);
}

.body_main_container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
}

.body_main_container_header {
    padding-right: 32px;
    margin-bottom: 16px;
    width: 25%;
    box-sizing: border-box;
}

.body_main_container_header:last-child {
    padding-right: 0px;
}

.body_title {
    display: flex;
    align-items: flex-end;
    color: rgb(66, 77, 87);
    font-size: 24px;
    font-weight: 600;
    margin-top: 4px;
    margin-bottom: 2px;
    white-space: nowrap;
}

.body_header_contanier {
    color: rgb(66, 77, 87);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: wrap;
    flex: 1 1 auto;
}

.container {
    box-sizing: border-box;
    height: 100%;
    min-width: 0px;
    -webkit-box-flex: 3;
    flex-grow: 3;
    flex-shrink: 1;
    flex-basis: 75%;
    border-left: 1px solid rgb(203, 212, 222);
}

.content {
    width: 100%;
    height: 100%;
    display: block;
}

.main_container {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    background-color: rgb(255, 255, 255);
    border-color: rgb(221, 226, 230);
}

.header {
    height: 52px;
}

.header_container {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    height: 52px;
    position: relative;
    text-align: center;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid rgb(221, 226, 230);
}

.header_content {
    color: rgb(66, 77, 87);
    height: 52px;
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    position: absolute;
    box-sizing: border-box;
    left: 0px;
    text-overflow: ellipsis;
    width: calc(100% - 40px);
    font-size: 20px;
    font-weight: 700;
    padding-left: 16px;
    overflow: hidden;
    margin: 0px 40px 0px 0px;
}

.content_body {
    position: relative;
    height: calc(100% - 52px);
    z-index: 0;
}

.content_header {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 10px -6px;
    display: flex;
    padding: 15px 20px;
}

.content_header_main {
    display: flex;
    flex: 1 1 auto;
}

.content_label {
    margin-right: 8px;
    font-size: 15px;
    line-height: 38px;
    color: rgb(66, 77, 87);
}

.content_header_body {
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    flex: 1 1 auto;
}

.content_button {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    color: rgb(67, 132, 245);
    background-color: rgb(255, 255, 255);
    opacity: 1;
    margin: 3px;
    padding: 7px 13px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(67, 132, 245);
    border-image: initial;
    border-radius: 4px;
    outline: none;
    transition: opacity 0.2s ease-in-out 0s;
}

.button_body {
    display: block;
    -webkit-box-align: center;
    align-items: center;
    width: 12px;
    height: 12px;
    line-height: 12px;
}

.content_button span {
    margin-left: 8px;
}

.date_button {
    margin: 3px;
}

.date_view {
    display: inline-flex;
}

.date_body {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 14px;
    color: rgb(255, 255, 255);
    line-height: 32px;
    background-color: rgb(89, 105, 158);
    box-shadow: none;
    cursor: pointer;
    padding: 0px 15px 0px 34px;
    border-radius: 4px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    outline: none;
}

.date_body > .icon {
    opacity: 1;
    visibility: visible;
}

.date_body > .icon {
    position: absolute;
    left: 12px;
    opacity: 1;
    top: 50%;
    transform: translateY(-50%);
}

.date_body > .title {
    max-width: 190px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.list {
    position: absolute;
    will-change: transform;
    top: 0px;
    left: 0px;
    transform: translate3d(65px, 55px, 0px);
    -webkit-font-smoothing: antialiased;
}

.list_container {
    min-width: 215px;
    color: rgb(33, 37, 41);
    background-clip: padding-box;
    font-weight: 400;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.4) 2px 5px 20px 0px;
    position: relative;
    width: 300px;
    border-radius: 4px;
    padding: 10px 11px;
}

.list_input {
    position: relative;
    color: #424d59;
    font-size: 15px;
    line-height: 1.2;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    font-family: Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, sans-serif;
}

.list_head {
    position: relative;
    border: 1px solid #bcc6d0;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    outline: 0;
}

.list_head:hover {
    border-color: #4384f5;
    transition: border .15s ease-in-out;
}

.list_head:active {
    border-color: #4384f5;
    transition: border .15s ease-in-out;
}

.list_input_container {
    height: 72px;
    padding: 8px 12px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    overflow-y: auto;
    -webkit-box-flex: 1;
    flex: 1;
    opacity: 1;
    visibility: visible;
    cursor: pointer;
}

.list_input_view {
    display: block;
    width: 285px;
    margin: 2px 3px;
    color: #424d59;
    border: none;
    background-color: transparent;
    box-sizing: border-box;
    outline: none;
    line-height: 1.2;
    cursor: pointer;
    font-size: 13px;
}

.selected_item_input_container {
    height: 72px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    overflow-y: auto;
    -webkit-box-flex: 1;
    flex: 1;
    opacity: 1;
    visibility: visible;
    cursor: pointer;
}

.selected_item {
    background-color: #59699e;
    min-width: auto;
    color: #fff;
    position: relative;
    border-radius: 5px;
    padding: 2px 6px 5px;
    line-height: normal;
    margin: 3px 3px 2px;
    cursor: default;
    overflow: hidden;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.selected_item_view {
    width: calc(100% - 22px);
    -webkit-box-flex: 1;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.selected_item_container {
    background-color: #59699e;
    color: #fff;
    border-radius: 5px;
    padding: 2px 4px 0px 10px;
}

.button_conatianer {
    margin-top: 20px;
    box-sizing: border-box;
}

.button_conatianer button {
    background-color: #4384f5;
    color: #fff;
    border-color: #4384f5;
    width: 100%;
    font-size: 15px;
    min-width: 36px;
    min-height: 36px;
    user-select: none;
    text-decoration: none;
    transition-property: opacity, border, color, background-color, box-shadow;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.64, 0, .35, 1);
    border: 1px solid #4384f5;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 600;
    display: inline-flex;
    line-height: 1.5;
    position: relative;
    padding: 6px 16px;
    box-sizing: border-box;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.close_button_container {
    margin-top: 5px;
    box-sizing: border-box;
}

.close_button_container button {
    background-color: #999999;
    color: #fff;
    border-color: #999999;
    width: 100%;
    font-size: 15px;
    min-width: 36px;
    min-height: 36px;
    user-select: none;
    text-decoration: none;
    transition-property: opacity, border, color, background-color, box-shadow;
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(.64, 0, .35, 1);
    border: 1px solid #999999;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 600;
    display: inline-flex;
    line-height: 1.5;
    position: relative;
    padding: 6px 16px;
    box-sizing: border-box;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.agent_list {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transform: translateY(0);
    position: absolute;
    top: 100%;
    width: 100%;
    margin-top: 4px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, .2);
    transform-origin: top center;
    overflow: hidden;
    z-index: 100000;
    padding: 4px 0 8px;
}

.ul_list {
    max-height: 224px;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;

}

.ul_list li {
    position: relative;
    list-style: none;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    padding: 9px 16px;
    -webkit-box-align: center;
    align-items: center;
}

.ul_list li:hover {
    background-color: #e1e9ec;
    position: relative;
    list-style: none;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    padding: 9px 16px;
    -webkit-box-align: center;
    align-items: center;
}

.li_container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100% - 20px);
    width: 100%;
}

.li_inner_container {
    width: 100%;
    text-overflow: ellipsis;
    -webkit-box-align: center;
    align-items: center;
    white-space: nowrap;
    display: flex;
    line-height: 1.3;
    vertical-align: middle;
    overflow: hidden;
}

.img_content {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    border-radius: 50%;
}

.li_info {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 30px);
    overflow: hidden;
}

.li_info span {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.bar_chat_view {
    height: 250px;
    text-align: left;
    line-height: normal;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    overflow: hidden;
    display: flex;
}

.bar_chat_container {
    width: 100%;
    height: 100%;
    text-align: left;
    line-height: normal;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

.email_style {
    font-size: 13px;
    opacity: 0.5;
}

.no_loading {
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.no_loading_view {
    width: 56px;
    height: 56px;
    display: flex;
}

.loading_loader {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 50%;
    border: 6px solid rgba(136, 74, 157, 0.6);
    position: relative;
    border-width: 4px;
    border-color: rgba(136, 74, 157, 0.6);
    border-top-color: #884a9d;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.selected_container {
    margin: 3px;
    display: inline-flex;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 14px;
    color: rgb(255, 255, 255);
    line-height: 32px;
    background-color: rgb(89, 105, 158);
    box-shadow: none;
    cursor: pointer;
    padding: 0px 15px 0px 34px;
    border-radius: 4px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    outline: none;
}

.span_icon {
    position: absolute;
    left: 12px;
    opacity: 1;
    top: 50%;
    transform: translateY(-50%);
}

.selected_container:hover > .span_icon {
    opacity: 0;
    visibility: hidden;
}

.selected_icon {
    display: block;
    -webkit-box-align: center;
    align-items: center;
    width: 16px;
    height: 16px;
    line-height: 16px;
}

.selected_item_name {
    max-width: 190px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.selected_remove {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    box-shadow: none;
    opacity: 0;
    visibility: hidden;
    padding: 3px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
}

.selected_container:hover > .selected_remove {
    opacity: 1;
    visibility: visible;
}
