.Modal {
    position: fixed;
    z-index: 1040;
    top:0px;
    background: white;
    width: 80%;
    height: auto;
    width: 500px;
    left: calc(50% - 250px);
    /* width: 80%; */
    /* left: 0; */
    align-self: center;
    justify-self: center;
    align-items: center;
    border: 2px solid #000000;
    transition: all 0.3s ease-out;
    display: flex;
    flex-direction: column;
}

@media (max-width: 500px) {
    .Modal {
        /* width: 500px; */
        position: fixed;
    z-index: 500;
    top: 0px;
    background: white;
    width: 70%;
    height: auto;
    /* width: 500px; */
    height: 400px;
    left: calc(10%);
    /* width: 80%; */
    /* left: 0; */
    align-self: center;
    justify-self: center;
    align-items: center;
    border: 2px solid #000000;
    transition: all 0.3s ease-out;
    display: flex;
    flex-direction: column;
    }
}
/* @media (min-width: 400px) {
    .Modal {
        width: 80%;
        left: 0;
        align-self: center;
        justify-self: center;
    }
} */
