.search_button{
  margin-right: 8px;
  border: 1px gray solid;
  padding: 1px 5px;
  border-radius: 6px;
}
.container{
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  min-height: 700px;
  flex-direction: column;
}

