
.canned_response_list {
    bottom: 0px;
    left: 0px;
    position: initial;
    z-index: 2000;
    box-shadow: 0 1px 10px 0 rgba(66,77,87,.3);
    background-color: #ffffff;
    border-radius: 4px;
    min-width: 168px;
    display: block;
}

.canned_response_view {
    overflow: auto;
    display: block;
    color: rgb(66, 77, 87);
    border-radius: 4px;
}

.canned_response_item {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    line-height: 22px;
    max-width: 360px;
    min-width: 90px;
    padding: 0px 10px;
    font-family: 'Source Sans Pro,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif';
    -webkit-font-smoothing: antialiased;
}

.canned_response_item:hover{
    background-color: rgb(225, 233, 236);
}


.canned_response_text {
    display: inline-block;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-wrap: normal;
    width: 100%;
    overflow: hidden;
    padding: 0px 10px;
}


.canned_response_arrow {
    display: block;
    -webkit-box-align: center;
    align-items: center;
    width: 4px;
    height: 8px;
    line-height: 8px;
    position: relative;
    top: 7px;
    margin-left: auto;
}
