.msg_body {
    margin: 0.5em 0px;
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    transition: all 200ms linear;
}

.msg_name {
    display: flex;
    flex-direction: row;
    margin: 0px 0px 0px 40px;
}

.msg_name_text {
    max-width: 50%;
    font-size: 13px;
    line-height: 1em;
    padding-bottom: 6px;
    overflow-wrap: break-word;
    color: rgba(66, 77, 87, 0.8);
}

.msg_left_body {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
}

.msg_left_img {
    background-color: rgb(57, 76, 130);
    color: rgb(255, 255, 255);
    user-select: none;
    margin: 2px 0.3em 0px 0px;
    text-align: center;
    text-transform: uppercase;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 50%;
}

.msg_text_body {
    display: flex;
    margin-bottom: 1em;
    max-width: 75%;
}

.msg_text_container {
    width: 100%;
    overflow: visible;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
    flex-shrink: 1;
}

.msg_bubble_view {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
}

.msg_bubble_container {
    overflow: hidden;
}

.msg_bubble_content {
    display: flex;
    align-items: flex-start;
    font-size: 0.9em;
    max-width: 100%;
    flex-direction: row;
    margin: 2px;
}

.msg_bubble_content1{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
}

.msg_bubble_content2 {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    overflow-wrap: break-word;
    width: 100%;
    flex-direction: row;
}

.msg_bubble_content_row {
    min-width: 0px;
    width: 100%;
}

.msg_bubble_content_view {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 0.1em;
    background-color: rgb(242, 243, 244);
    color: rgb(66, 77, 87);
    font-size: 15px;
    line-height: 1.5em;
    word-break: break-word;
    border-top-left-radius: 1.4em;
    border-top-right-radius: 1.4em;
    border-bottom-right-radius: 1.4em;
    border-bottom-left-radius: 0.3em;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.05);
    border-image: initial;
}

.msg_bubble_container_content {
    white-space: pre-line;
    overflow-wrap: break-word;
    max-width: 100%;
    padding: 10px 20px 12px;
}

.msg_right_name {
    display: flex;
    flex-direction: row-reverse;
    margin: 0px 40px 0px 0px;
}

.msg_right_body {
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
}

.msg_right_img {
    background-color: rgb(132, 91, 91);
    color: rgb(255, 255, 255);
    user-select: none;
    margin: 2px 0px 0px 0.3em;
    text-align: center;
    text-transform: uppercase;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    border-image: initial;
    border-radius: 50%;
}

.msg_right_container {
    display: flex;
    margin-bottom: 1em;
    flex-direction: row-reverse;
}

.msg_right_content {
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
    flex-shrink: 1;
}

.msg_right_content1 {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.msg_right_content2 {
    overflow: hidden;
}

.msg_right_bubble_view {
    display: flex;
    align-items: flex-start;
    font-size: 0.9em;
    max-width: 100%;
    flex-direction: row-reverse;
    margin: 2px;
}

.msg_right_bubble_container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow: hidden;
}

.msg_right_bubble_container_content {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    overflow-wrap: break-word;
    width: 100%;
    flex-direction: row-reverse;
}

.msg_bubble_right {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 0.1em;
    background-color: rgb(66, 127, 225);
    color: rgb(255, 255, 255);
    font-size: 15px;
    line-height: 1.5em;
    word-break: break-word;
    border-top-left-radius: 1.4em;
    border-top-right-radius: 1.4em;
    border-bottom-right-radius: 0.3em;
    border-bottom-left-radius: 1.4em;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.05);
    border-image: initial;
}
