.nav {
    width: 64px;
    max-width: 5%;
    height: 100%;
    background-color: rgb(58, 52, 60);
    position: fixed;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    display: block;
    box-sizing: border-box;
}

.firstNavBar {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 456px;
}

.secondNavBar {
    bottom: 0px;
    left: 0px;
    position: absolute;
    width: 100%;
}

.NavigationItems {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    list-style-image: none;
    flex-direction: column;
    align-items: center;
}

.NavigationItem {
    margin: 0;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 56px;
    font-size: 0.55em;
}

.NavigationItem a {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 56px;
    color: rgb(255, 255, 255);
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
}

.NavigationItems a:hover,
.NavigationItems a:active {
    text-decoration: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    background-color: #4E4950;
    color: #ffffff;
    width: 100%;
    height: 56px;
}

.NavigationItemContainer {
    display: block;
    -webkit-box-align: center;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    line-height: 24px;
}

.NavigationItemIcon {
    display: block;
    -webkit-box-align: center;
    align-items: center;
    width: 24px;
    height: 24px;
    line-height: 24px;
}

.NavigationItemText {
    font-size: 10px;
    flex-direction: column;
    text-align: center;
    opacity: 0.6;
    padding-top: 3px;
    display: inline-block;
    width: 100%;
    word-break: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: white;
    overflow: hidden;
}

.NavigationItemLogoutDiv {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border-width: 2px;
    border-style: solid;
    border-color: rgb(58, 52, 60);
    border-image: initial;
    border-radius: 50%;
}

.NavigationItemLogout {
    display: block;
    background-size: cover;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 1000px;
}

.profileItem {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border-width: 2px;
    border-style: solid;
    border-color: rgb(58, 52, 60);
    border-image: initial;
    border-radius: 50%;
}

.onlineIcon1 {
    background-color: rgb(70, 183, 118);
    border-width: 2px;
    border-style: solid;
    border-color: rgb(58, 52, 60);
    border-image: initial;
}

.onlineIcon2 {
    position: absolute;
    display: block;
    box-sizing: border-box;
    border-radius: 50%;
}

@media screen and (min-width: 140px) {
    .nav {
        width: 64px;
        max-width: 20%;
    }

    .NavigationItemLogout {
        height: 100%;
        width: 100%;
    }

}
