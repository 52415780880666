.top_bar{
    display: flex;
    height:60px;
    
}

.header_doctor_name{
    margin-top: 14px;
    padding-top: 8px;
    font-weight: 700;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
    border-right: rgb(203, 212, 222) 1px solid;
}
.header_status{
    padding-top: 22px;
    font-weight: 700;
    font-size: 14px;
    padding-left: 15px;
}

.notAcceptingChat{
    color:rgb(226, 12, 12);
}

.main {
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    align-content: stretch;
    width: 100%;
    height: calc(100% - 60px);
    -webkit-font-smoothing: antialiased;
    background-color: #F1F2F6;
    flex-direction: column;
    overflow: scroll;
}

.head_buttons{
    display: flex;
    justify-content: flex-end;
}

.add_patient_button{
    font-size: 17px;
    font-family: 'HarmoniaSansStd-SemiBd';
    color:#FFFFFF;
    background-color: #894A9E;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    margin-right: 35px;
    border-radius: 8px;
    cursor: pointer;
}
.top_cancel_button{
    font-size: 17px;
    font-family: 'HarmoniaSansStd-SemiBd';
    color: #894A9E;
    background-color: white;
    border: 1px solid #894A9E !important;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-right: 30px;
    padding-left: 30px;
    margin-right: 35px;
    border-radius: 8px;
    cursor: pointer;
}
.chart_label{
    padding-left: 30px;
    color: #424D57;
    font-size: 16px;

}

.patients_table{
    background-color: #ffffff;
    margin-left: 35px;
    margin-right: 35px;
    border-radius: 8px;
    margin-bottom: 50px;
}
.performance_table{
    background-color: #ffffff;
    margin-left: 35px;
    margin-right: 35px;
    border: 1px solid #979797;
    margin-bottom: 50px;

}
.performance_section{
    height:49px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #979797;
}
.performance_section_text{
    font-size: 16px;
    color: #424D57;
    font-family: 'HarmoniaSansStd-Regular';
}
.filter_section{
    height:40px;
    padding-left: 5px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #979797;
}
.filter_section_text{
    font-size: 16px;
    color: #424D57;
    padding-right: 5px;
    font-family: 'HarmoniaSansStd-Regular';
}
.filter_button{
    border-radius: 7px;
    background-color: #894A9E;
    color: white;
    padding: 6px 10px;
    font-size: 12px;
    margin-right: 10px;
    font-family: 'HarmoniaSansStd-Regular';
    cursor: pointer;
}
.dateFiltersRadio{
    margin-right: 5px;
    cursor: pointer;
}
.dateFiltersInputSection{
    display: flex;
    flex-direction: row;
    margin-left: 55px;
    margin-top: 10px;

}
.dateFiltersInputSubSection{
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    justify-content: center;
}
.dateFilterText,dateFilterSubSectionText,dateFilterSubSectionText1{
    font-size: 18px;
    font-family: 'HarmoniaSansStd-Regular';
}
.dateFilterSubSectionText{
    margin-bottom: 10px;
}
.dateFilterSubSectionText1{
    margin-bottom: 10px;
    margin-top: 35px;
}
.dateFilters{
    margin-top: 75px;
    margin-left: 45px;
}
.doctorFilters{
    margin-top: 40px;
    margin-bottom: 20px;
    margin-left: 45px;
}
.dateFilterRows{
    margin-bottom: 10px;
    font-size: 18px;
    font-family: 'HarmoniaSansStd-Regular';
}
.reportsHistoryRow{
    margin-bottom: 10px;
    font-size: 17px;
    font-family: 'HarmoniaSansStd-SemiBd';
    margin-left: 55px;
    margin-right: 55px;
}
.encounter_section{
    height:86px;
    padding-left: 5px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #979797;
}
.encounter_sub_section{
    height: 100%;
    align-items: center;
    display: flex;
    width: fit-content;
    border-right: 1px solid #979797;
    padding-right: 15px;
}
.encounter_section_text{
    font-size: 26px;
    color: #424D57;
    padding-right: 5px;
    font-family: 'HarmoniaSansStd-Regular';
}

.encounter_section_value{
    font-size: 26px;
    color: #7B1FA2;
    padding-right: 5px;
    font-family: 'HarmoniaSansStd-Bold';
}

.dataRangeModal,.responseModal,.reportHistory {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 70%;
    
    padding: 30px 0px 35px 0px;
    left: 15%;
    top: 0%;
    max-height: 100%;
    overflow: scroll;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    display: flex;
    flex-direction: column;
}
.reportHistory{
    max-height: 600px;
}
.reportsSectionScrollable{
    max-height: 400px;
    overflow: scroll;
}
@media (min-width: 600px) {
    .dataRangeModal,.reportHistory{
        width:578px;
        left: calc(50% - 289px);
        top: calc(50% - 300px);
        padding-left: 8px;
        padding-right: 8px;
    }
    .responseModal{
        width:740px;
        left: calc(50% - 370px);
        top: calc(50% - 75px);
        height: 150px;
        padding: 10px 8px;
        justify-content: center;
    }
}
.overlay{
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: #898989;
    opacity: 0.4;
    overflow-y: hidden;
    transition: 0.1s;
}
.hide_overlay{
    height: 0;
    width: 100%;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: #898989;
    overflow-y: hidden;
    transition: 0.1s;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 15px;
}
.reportHeader{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 30px;
}

.emailFeedback {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
}

.encryptText {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 15px;
    width: 50%;
}

.copyClipboardSuccess{
    text-align: center;
    color: #28a745;
}

.dateRangeInput {
    height: 18px; 
    padding: 15px;
    margin-right: 10px;
    border-radius: 3px;
    border: 1px solid #999999;
    font-size: 16px;
    background: #ffffff;
}

.saveButton {
    flex: 1;
    background-color: #894A9E;
    color: #ffffff;
    padding: 10px 10px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    margin: 10px;
    text-align: center;
    cursor: pointer;
}

.cancelButton {
    flex: 1;
    color: #894A9E;
    padding: 10px 10px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid #894A9E !important;
    margin: 10px;
    text-align: center;
    cursor: pointer;
}
.reportPopUpBottomText{
    margin-left: 55px;
    margin-top: 15px;
    font-family: 'HarmoniaSansStd-SemiBd';
    font-size: 14px;

}
.batchUploadErrorText{
    padding-left: 106px;
    margin-right: 10px;
    padding-top: 10px;
    color: red;
}

.batchUploadSuccessText{
    padding-left: 106px;
    margin-right: 10px;
    padding-top: 10px;
    color: #894A9E;
}

.addPatientButtonView{
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-left: 50px;
    margin-right: 50px;
}
.reportsButtonView{
    display: flex;
    margin-top: 5px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding-left: 50px;
    margin-right: 50px;
}
.patients_table>div{
    border-radius: 8px;
}
