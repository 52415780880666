.video_frame{
    /*width: 280px;
    height: 280px;*/
    aspect-ratio: 1.3333333333;

    margin: 10px;
}
.video_frame_remote{
    /*width: 280px;
    height: 280px;*/
    aspect-ratio: 0.5625;

    margin: 10px;
}

.muteAudioIconRemote{
    position: absolute;
    z-index: 100;
    left: 25px;
    top: 30px;
}
.video_frame_black_remote{
    /*width: 280px;
    height: 280px;*/
    aspect-ratio: 0.5625;
    background-color: #2e2e2e;
    color: white;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.video_frame_black{
    /*width: 280px;
    height: 280px;*/
    aspect-ratio: 1.3333333333;
    background-color: #2e2e2e;
    color: white;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon_image {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    flex: 0 0 40px;
}
.icon_image_small {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    flex: 0 0 40px;
}
.controlSection{
    display: flex;
    justify-content: space-around;
}
