.generalInformation{
    height: 20%;
    width: 100%;
}
.openchatlist{
    display: flex;
    justify-content: space-between;
    margin-bottom:8px;
    border-radius: 6px;
    border-width: 1px;
    text-align: left;
    padding-left: 13px;
    padding-right: 13px;
    height:43px;
    background-color:#E3E3EA;
    color: #424D57;
    font-size: 16px;
}
.openchatlistSection{
    display: flex;
    flex-direction: row;
}
.subListTitleIcon{
    padding-left:5px;
    margin-bottom:2px;
    transition: all 0.4s ease;
    align-self: center;
}
.subListTitleIconRotate{
    padding-left:5px;
    margin-top:2px;
    transition: all 0.4s ease;
    transform: rotateZ(-90deg);
    align-self: center;
}
.subListTitle{
    font-size: 18px;
    font-weight: 700;
    padding-left:10px;
    font-family: 'HarmoniaSansStd-Bold';
    color:#000000;
    align-self: center;
}
.expandable_section {

    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background-color: #ffffff;
    font-size: 14px;
    line-height: 34px;
    font-weight: 600;
    align-self: center;
    color: rgb(66, 77, 87);
    margin-bottom: 1px;
    border-radius: 8px;
    padding: 10px 0px 10px 10px;
    margin-top: 8px;
    margin-bottom: 8px;
}
.general_info_section{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 10px;

}

.importantLinks{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    line-height: normal;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px solid;
    border-radius: 6px;
    padding: 8px;
}

.warningTextColumn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    line-height: normal;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px solid;
    border-radius: 6px;
    padding: 8px;
}

.importantLinkText{
    font-size: 18px;
    font-family: 'HarmoniaSansStd';
    color: #000000;
    margin-top: 10px;
}

.header_attr{
    font-size: 18px;
    font-weight: 700;
    font-family: 'HarmoniaSansStd-Bold';
    color:#000000;
}

.header_attr_link{
    font-size: 18px;
    font-family: 'HarmoniaSansStd';
    color:#1E90FF	;
}

.header_attr_value{
    font-size: 18px;
    font-family: 'HarmoniaSansStd-Regular';
    color:#000000;
    font-weight: 400;
}

.copyConversationID{
    margin-left: 10px;
    font-family: 'HarmoniaSansStd';
    font-weight: 800;
    border: 1px solid;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}

.sickNoteDiv{
    display: flex;
    flex-direction: column;
    border: 1px solid lightgray;
    border-radius: 6px;
    padding: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
}
.list_text {
    margin-top: 10px;
    justify-content: center;
    font-size: 14px;
    text-align: center;
    margin-bottom: 8px;
    display: flex;
    border-radius: 8px;
    align-items: center;
    background-color: white;
    padding-top: 6px;
    padding-bottom: 6px;
}
.sickNoteFileName{
    overflow-wrap: break-word;
    font-family: 'HarmoniaSansStd';
    color:#D0342C;

}

.SOAP_div{
    display: flex;
    flex-direction: column;
}
.SOAP_heading{
    font-size: 18px;
    font-family: 'HarmoniaSansStd-Bold';
    color: #000000;
}
.SOAP_textarea{
    border: #E3E3EA 1px solid;
    border-radius: 5px;
    font-size: 16px;
    font-family: "HarmoniaSansStd-Regular";
    color: black;
    min-height: 111px;
    padding: 2px 10px;
    width: calc(100% - 20px);
}

.SOAPTextAreaRed{
    border: #E3E3EA 1px solid;
    border-radius: 5px;
    font-size: 16px;
    font-family: "HarmoniaSansStd-Regular";
    color: #D0342C;
    min-height: 111px;
    padding: 2px 10px;
    width: calc(100% - 20px);
}
.SOAP_buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.button {
    float: left;
    color: #ffffff;
    background: #884a9d;
    border-radius: 5px;
    margin-top: 5px;
    margin-right: 5px;
    padding: 2.5px 10px;
    font-size: 14px;
    font-weight: normal;
    font-family: 'HarmoniaSansStd';
    border: 1px solid #884a9d;
}


.button_invert {
    float: left;
    color: #884a9d;
    background: #ffffff;
    border-radius: 5px;
    margin-top: 5px;
    margin-right: 5px;
    padding: 2.5px 10px;
    font-size: 14px;
    font-weight: normal;
    font-family: 'HarmoniaSansStd';
    border: 1px solid #884a9d;
}
