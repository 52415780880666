html {
    font-family: 'Source Sans Pro', 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
}

div {
    display: block;
}

.main {
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    align-content: stretch;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.chatsList {
    box-sizing: border-box;
    height: 100%;
    min-width: 320px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 25%;
    border-left: none;
}

.block {
    width: 100%;
    height: 100%;
    display: block;
}

.main_block {
    width: 100%;
    display: block;
}

.msg_body {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    background-color: rgb(255, 255, 255);
    border-color: rgb(221, 226, 230);
}

.list {
    position: relative;
    height: calc(100% - 52px);
    z-index: 0;
}

.list_container {
    box-sizing: border-box;
    max-height: 100%;
    margin: 0px;
    padding: 10px;
}

.list_body {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    background-color: rgb(243, 247, 249);
    border-color: rgb(203, 212, 222);
}

.list_header {
    height: 52px;
}

.list_item {
    position: relative;
}

.list_item a {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    color: rgb(66, 77, 87);
    padding: 14px;
    text-decoration: none;
    transition: color 0.2s ease-in-out 0s;
}

.list_item a:hover {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 16px;
    line-height: 19px;
    color: #4384f5;
    padding: 14px;
    text-decoration: none;
    transition: color 0.2s ease-in-out 0s;
}

.list_app_icon {
    margin-right: 12px;
    color: rgb(67, 132, 245);
    display: inline-block;
    transform: translate(0, 0);
    text-rendering: auto;
    font-size: inherit;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.list_main {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 4px;
    cursor: pointer;
    transform: rotate(-90deg);
    padding: 10px;
    transition: transform 0.2s ease-in-out 0s;
}

.list_item_view {
    display: block;
    -webkit-box-align: center;
    align-items: center;
    width: 12px;
    height: 8px;
    line-height: 8px;
}

.header_body {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    height: 52px;
    position: relative;
    z-index: 2;
    text-align: center;
    box-sizing: border-box;
    background-color: rgb(243, 247, 249);
    border-bottom: 1px solid rgb(203, 212, 222);
}

.header_body_red {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    height: 52px;
    position: relative;
    z-index: 2;
    text-align: center;
    box-sizing: border-box;
    background-color: red;
    border-bottom: 1px solid rgb(203, 212, 222);
}

.header_text {
    font-size: 18px;
    font-weight: 400;
    color: rgb(66, 77, 87);
    height: 52px;
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    position: absolute;
    box-sizing: border-box;
    width: calc(100% - 20px);
    left: 0px;
    text-overflow: ellipsis;
    margin: 0px 10px;
}

.header_text_red {
    font-size: 15px;
    font-weight: bold;
    color: rgb(245, 246, 248);
    height: 52px;
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    position: absolute;
    box-sizing: border-box;
    width: calc(100% - 0px);
    left: 0px;
    text-overflow: ellipsis;
    margin: 0px 0px;
}

.msg_header {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    height: 52px;
    position: relative;
    z-index: 2;
    text-align: center;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid rgb(221, 226, 230);
}

.chats {
    box-sizing: border-box;
    height: 100%;
    min-width: 0px;
    -webkit-box-flex: 2;
    flex-grow: 2;
    flex-shrink: 1;
    flex-basis: 50%;
    border-left: 1px solid rgb(203, 212, 222);
}

.main_body {
    box-sizing: border-box;
    height: 100%;
    min-width: 0px;
    -webkit-box-flex: 3;
    flex-grow: 3;
    flex-shrink: 1;
    flex-basis: 75%;
    z-index: 1;
    border-left: 1px solid rgb(203, 212, 222);
}

.content_body {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.detail_view {
    width: 100%;
    height: 700px;
    overflow: scroll;
}

.main_detail_view {
    position: relative;
    max-width: 860px;
    width: calc(100% - 100px);
    box-sizing: border-box;
    padding: 20px 0px 80px;
    margin: 0px auto;
    overflow: scroll;
}

.detail_content {
    clear: both;
    position: relative;
    margin: 0;
    padding: 0 0 20px;
    overflow: scroll;
}

.content {
    display: block;
}

.content h2 {
    color: #424d57;
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: 1px solid #dde2e6;
    display: flex;
    align-items: flex-end;
}

.intro {
    color: rgba(66, 77, 87, 0.8);
    margin-bottom: 20px;
}

.form {
    margin-bottom: 30px;
    font-size: 100%;
    vertical-align: baseline;
    margin: 0px;
    padding: 0px;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
}

.form input {
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    width: 160px;
    font-size: 14px;
    margin-right: 3px;
    line-height: 17px;
    color: rgba(66, 77, 87, 0.8);
    box-sizing: border-box;
    padding: 5px 8px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(221, 226, 230);
    border-image: initial;
    border-radius: 4px;
    outline: none;
}

.form input:focus {
    border-color: rgb(27, 141, 241);
}

.form input:active {
    border-color: rgb(27, 141, 241);
}

.form textarea {
    float: left;
    width: 460px;
    height: 81px;
    max-width: 570px;
    min-width: 460px;
    min-height: 81px;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 15px;
    line-height: 1.5;
    color: rgba(66, 77, 87, 0.8);
    border: 1px solid #dde2e6;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
}

.form button {
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    position: relative;
    display: inline-block;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    padding: 0px;
    margin-left: 10px;
    vertical-align: top;
    color: rgb(255, 255, 255);
    border-radius: 3px;
    background-color: rgb(67, 132, 245);
    border-radius: 4px;
}

.form button span {
    line-height: 28px;
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    font-size: 12px;
    letter-spacing: 0.02em;
    padding: 0px 30px;
}

.tag_list {
    display: block;
    margin: 15px;
    padding: 0px;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
}

.tag_list_header {
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    position: relative;
    width: 100%;
    font-size: 14px;
}

.view {
    padding: 10px 20px 10px 20px;
}

.text {

}

.text span {
    color: #fff;
    font-size: 12px;
    margin-right: 3px;
    line-height: 20px;
    text-shadow: none;
    padding: 0px 10px 0px 10px;
    background: #ff9800;
    word-break: break-all;
    display: inline-flex;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.count {
    width: 110px;
    display: inline-block;
    color: rgb(66, 77, 87);
}

.tag {
    position: relative;
    width: 100%;
    border-top: 1px solid #dde2e6;
}

.tag_view {
    padding: 20px;
    display: table;
    width: initial;
}

.field {
    float: left;
    margin: 0 24px 24px 0;
}

.submit {
    float: left;
    margin: 0;
}

.submit button {
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
    position: relative;
    display: inline-block;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    border: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 4px;
    background-color: #4384f5;
}

.submit button span {
    display: block;
    padding: 12px 33px 12px 33px;
    font-size: 15px;
    line-height: 17px;
    color: #ffffff;
    letter-spacing: 0.2px;
}

.field input {
    width: 460px;
    padding: 9px 8px;
    font-size: 15px;
    line-height: 21px;
    color: rgba(66, 77, 87, 0.8);
    border: 1px solid #dde2e6;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
}

.field p {
    margin-top: 8px;
    line-height: 17px;
    font-size: 15px;
    color: rgba(66, 77, 87, 0.8);
}

.response {
    clear: both;
}

.response_list {
    min-width: 100%;
}

.response_item {
    position: relative;
    width: 100%;
    border-top: 1px solid #ddd;
    margin-bottom: 20px;
}

.response_item .view {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
}

.response_item .view .text {
    margin-bottom: 20px;
    font-size: 14px;
    flex: 1;
    color: rgba(66, 77, 87, 0.8);
    word-wrap: break-word;
    display: contents;
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
}

.response_item .view .tags {
    font-size: 13px;
    margin-top: 20px;
    flex: 1;
    margin-right: 270px;
    display: inline-block;
}

.response_item .view .tags span {
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
    position: relative;
    margin-right: 5px;
    flex: 1;
    font-size: 13px;
    color: rgba(66, 77, 87, 0.8);
    font-variant: small-caps;
}

.response_item .view .tags .shortcut {
    position: relative;
    max-width: 100%;
    overflow-wrap: break-word;
    padding: 3px 7px;
    margin: 2px;
    font-size: 15px;
    color: #424d57;
    line-height: 21px;
    border: 1px solid #d9dadb;
    border-radius: 4px;
    background-color: #ffffff;
    text-shadow: none;
    display: inline;
}

.response_item .view .tags .shortcut span {
    font-size: 18px;
    line-height: 21px;
    color: #4384f5;
}

.response_item .view .links {
    position: absolute;
    bottom: 0px;
    right: 185px;
}

.response_item .view .links span {
    margin-left: 10px;
    color: #4384f5;
}

.response_item .view .links span:hover {
    text-decoration: underline;
}

.response_item_form {
    -webkit-transition: all 200ms linear;
    -moz-transition: all 200ms linear;
    transition: all 200ms linear;
    margin: 32px 0 4px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;

}

.response_item_field {
    float: left;
    margin: 0 24px 24px 0;
}

.response_item_field textarea {
    float: left;
    width: 460px;
    height: 81px;
    max-width: 570px;
    min-width: 460px;
    min-height: 81px;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 15px;
    line-height: 1.5;
    color: rgba(66, 77, 87, 0.8);
    border: 1px solid #dde2e6;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
}

.response_item_tag {
    clear: left;
}

.response_item_tag input {
    width: 460px;
    padding: 9px 8px;
    font-size: 15px;
    line-height: 21px;
    color: rgba(66, 77, 87, 0.8);
    border: 1px solid #dde2e6;
    border-radius: 4px;
    box-sizing: border-box;
    outline: none;
}

.response_item_submit {
    float: left;
    margin: 0;
    position: relative;
    flex-direction: column;
}

.response_item_submit button {
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
    width: 170px;
    text-decoration: none;
    text-align: center;
    border: 0;
    cursor: pointer;
    border-radius: 4px;
    background-color: #4384f5;
}

.response_item_submit button span {
    padding: 0px 0px 10px;
    font-size: 15px;
    text-align: center;
    color: #ffffff;
    letter-spacing: 0.2px;
}

.response_item_submit span {
    display: block;
    text-align: left;
    color: #999;
    margin-top: 10px;
}

.cancel_button {
    margin-top: 10px;
    color: #4384f5;
    text-decoration: none;
    outline: none !important;
    cursor: pointer;
}

.delete_view {
    margin-top: 100px;
    padding: 20px;
    overflow: hidden;
}

.cancel_delete_button {
    margin-left: 10px;
    text-decoration: none;
    outline: none !important;
    color: #4384f5;
    cursor: pointer;
    text-align: center;
}

.cancel_delete_button:hover {
    text-decoration: underline;
}

.confirm_remove {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: white;
    z-index: 1;
}

.wrapper {
    display: table;
    width: 100%;
    height: 100%;
}

.wrapper div {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.wrapper div button {
    margin-right: 10px;
    color: #fff;
    background: #ea4335;
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
    position: relative;
    display: inline-block;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    border: 0;
    padding: 0;
    border-radius: 3px;
    cursor: pointer;
}

.wrapper div button span {
    padding: 0 30px;
    border-radius: 3px;
    line-height: 38px;
    font-size: 12px;
    letter-spacing: 0.06em;
    font-size: 15px;
    text-transform: none;
    letter-spacing: 0.02em;
}

