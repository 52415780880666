.dropdown {
    position: relative;
    display: inline-block;
}

.saveView {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ulList {
    list-style-type: none;
    margin: 0;
    padding: 0;
    top:50px;
    right:10px;
    width: 180px;
    background-color: white;
    font-weight:bold;
    border-radius: 5px;
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.ulList li {
    font-size: 14px;
    font-weight: lighter;
    padding: 5px 10px;
    border-bottom: 1px solid #e5e5e5;
}

.ulList li:last-child {
    border-bottom: none;
}

.ulList li:hover {
    background-color: #e5e5e5;
    font-weight: bold;
}

.ulSmallList {
    list-style-type: none;
    margin: 0;
    padding: 0;
    top:50px;
    right:10px;
    width: 115px;
    background-color: white;
    font-weight:bold;
    border-radius: 5px;
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    overflow-wrap: inherit;
}

.ulSmallList li {
    font-size: 14px;
    font-weight: lighter;
    padding: 5px 10px;
    border-bottom: 1px solid #e5e5e5;
}

.ulSmallList li:last-child {
    border-bottom: none;
}

.ulSmallList li:hover {
    background-color: #e5e5e5;
    font-weight: bold;
}


.modal {
    position: fixed;
    z-index: 1040;
    background-color: #dee3e7;
    width: 70%;
    border: 1px solid #1d1d1d;
    border-radius: 10px;
    padding: 10px 0px 35px 0px;
    left: 15%;
    top: 0%;
    max-height: 100%;
    overflow: scroll;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    display: flex;
    flex-direction: column;
}

.content {
    margin: 0px 80px;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
}

.main {
    margin-top: 20px;
    margin-left: 10px;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: row;
}

.main_with_border{
    border-bottom: 1px grey solid;
    padding-bottom: 24px;
    margin-top: 20px;
    margin-left: 10px;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: row;
}

.billingMain {
    flex: 1;
    margin-top: 20px;
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    position: relative;
}

.billingText {
    font-size: 16px;
    font-weight: 500;
    margin-right: 94px;
}

.billingCodeText {
    font-size: 16px;
    font-weight: 500;
    margin-left: 74px;
    margin-right: 20px;
}

.diagnosticMain {
    flex: 1;
    margin-top: 20px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
}

.diagnoticInput {
    width: 450px;
    padding: 15px;
    margin-right: 10px;
    border-radius: 3px;
    border: 1px solid #999999;
    font-size: 16px;
    position: relative;
    top:0px;
    left:0px;
}

.diagnoticTextArea {
    width: 450px;
    height: 100px;
    padding: 15px;
    margin-right: 10px;
    border-radius: 3px;
    border: 1px solid #999999;
    background-color: #DEE3E7;
    font-size: 16px;
    position: relative;
    top:0px;
    left:0px;
}

.symptomsText {
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px;
}

.followuptext{
    width: 186px;
    font-size: 16px;
    font-weight: 500;
}

.radioText {
    width: 175px;
    font-size: 16px;
    font-weight: 500;
}

.listView {
    position: absolute;
    top: 50px;
    left: 185px;
    width: 445px;
    max-height: 320px;
    line-height: 20px;
    background-color: #ffffff;
    border: 1px solid #999999;
    visibility: visible;
    overflow-y: scroll;
    border-radius: 5px;
    z-index: 2;
}

.listView li {
    padding: 5px 0px;
    font-size: 15px;
    color: #222222;
    cursor: pointer;
}

.input {
    width: 450px;
    height: 18px;
    padding: 15px;
    margin-right: 10px;
    border-radius: 3px;
    border: 1px solid #999999;
    font-size: 16px;
    background: #ffffff;
}

.textAreaInput {
    background-color: #ffffff;
    width: 450px;
    margin-right: 10px;
    border-radius: 3px;
    border: 1px solid #999999;
    font-size: 16px;
}

.smallInput {
    width: 130px;
    padding: 15px;
    margin-right: 10px;
    border-radius: 3px;
    border: 1px solid #999999;
    font-size: 16px;
}
.smallInputBillingUnit {
    width: 45px;
    padding: 15px;
    margin-right: 10px;
    border-radius: 3px;
    border: 1px solid #999999;
    font-size: 16px;
}
.listbox {
    width: 450;
    padding: 15;
    margin-right: 10;
    border-radius: 10,;
    border: '1px solid #999999';
    font-size: 16;
    margin: 0;
    z-index: 1;
    position: 'absolute';
    list-style: 'none';
    overflow: 'auto';
    max-height: 200;
}

.listbox li[data-focus="true"] {
    background-color: '#4a8df6';
    color: 'white';
    cursor: 'pointer';
}

.listbox li:active{
    background-color: '#2977f5';
    color: 'white';
}

.autoInput {
    width: 450px;
    padding: 10px;
    margin-right: 10px;
    border-radius: 10px;
    border: 1px solid #999999;
    font-size: 16px;
}

.innerInput {
    width: 80px;
    padding: 15px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    border: 1px solid #999999;
    font-size: 16px;
}

.mediumInput {
    width: 150px;
    padding: 15px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    border: 1px solid #999999;
    font-size: 16px;
}

.contentView {
    width: 500px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

.switchView {
    position: relative;
}

.diagnosisText {
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    margin-right: 17px;
}

.yesText {
    font-size: 16px;
    font-weight: 400;
    margin-left: 5px;
}

.noText {
    font-size: 16px;
    font-weight: 400;
    margin-right: 5px;
}

.followUpTimeText {
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
}

.followUpInput {
    width: 150px;
    padding: 15px;
    font-size: 14px;
    margin-right: 10px;
    border-radius: 10px;
    border: 1px solid #999999;
    overflow: visible;
}

.calenderView {
    width: 350px;
    max-width: 100%;
    background: white;
    border: 1px solid rgb(160, 160, 150);
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}

.buttonView {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.messageText {
    text-align: center;
    font-size: 16px;
    color: '#000000';
}

.errorText {
    text-align: center;
    font-size: 14px;
    color: #a30707;
}

.addMoreMediciensButton {
    flex: 1;
    background-color:#884a9d;
    color: #ffffff;
    padding: 10px 10px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid #000000 !important;
    margin: 10px;
}

.cancelButton {
    flex: 1;
    background-color: #ececec;
    color: #222222;
    padding: 10px 10px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid #999999 !important;
    margin: 10px;
}

.okCancelButton {
    flex: 1;
    background-color: #ececec;
    color: #222222;
    padding: 10px 70px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    border: 1px solid #999999 !important;
    margin: 10px;
}

.titleText {
    font-size: 14px;
    color: #884a9d;
}

@media (min-width: 600px) {
    .modal {
        width: 850px;
        left: calc(44.5% - 310px);
    }
}

.no_loading_view {
    width: 56px;
    height: 56px;
    display: flex;
}

.loading_loader {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 50%;
    border: 6px solid rgba(136, 74, 157, 0.6);
    position: relative;
    border-width: 4px;
    border-color: rgba(136, 74, 157, 0.6);
    border-top-color: #884a9d;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.canned_response_list {
    bottom: calc(100% - 10px);
    left: 200px;
    position: absolute;
    z-index: 2000;
    box-shadow: 0 1px 10px 0 rgba(66,77,87,.3);
    background-color: #ffffff;
    border-radius: 4px;
    min-width: 168px;
    display: block;
}

.canned_response_view {
    overflow: auto;
    display: block;
    color: rgb(66, 77, 87);
    border-radius: 4px;
}

.canned_response_item {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    line-height: 22px;
    max-width: 360px;
    min-width: 90px;
    padding: 0px 10px;
    font-family: 'Source Sans Pro,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif';
    -webkit-font-smoothing: antialiased;
}

.canned_response_item:hover{
    background-color: rgb(225, 233, 236);
}

.canned_response_text {
    display: inline-block;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-wrap: normal;
    width: 100%;
    overflow: hidden;
    padding: 0px 10px;
}

.canned_response_arrow {
    display: block;
    -webkit-box-align: center;
    align-items: center;
    width: 4px;
    height: 8px;
    line-height: 8px;
    position: relative;
    top: 7px;
    margin-left: auto;
}

.canned_response_selected_text {
    max-height: 352px;
    display: block;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.split_half{
    display: flex;
    width: 50%;
    justify-content: left;
}

.canned_response_selected_text_li {
    list-style: none;
    padding: 10px;
    cursor: pointer;
    line-height: 1.3;
    font-size: 14px;
    color: #424d59;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
}

.canned_response_selected_text_li:focus {
    background-color: #e1e9ec;
}

.canned_response_content {
    max-width: 360px;
    width: 100%;
    display: flex;
}

.canned_response_content_inner {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
}

.canned_response_content_data {
    align-items: flex-start;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    line-height: 22px;
    max-width: 360px;
    min-width: 90px;
    padding: 0px 10px;
}

.canned_response_content_text {
    white-space: pre-wrap;
}

.canned_response_back_arrow {
    padding-right: 10px;
    height: 22px;
}

.canned_response_back_arrow_view {
    display: block;
    -webkit-box-align: center;
    align-items: center;
    width: 4px;
    height: 8px;
    line-height: 8px;
    position: relative;
    top: 7px;
    transform: rotate(180deg);
    -webkit-font-smoothing: antialiased;
}

.iconContainer {
    position: absolute;
    right: 25px;
    top: calc(50% - 10px);
}

.loader {
    position: relative;
    height: 20px;
    width: 20px;
    display: inline-block;
    animation: around 5.4s infinite;
}

@keyframes around {
    0% {
      transform: rotate(0deg)
    }
    100% {
      transform: rotate(360deg)
    }
}

.loader::after, .loader::before {
    content: "";
    background: white;
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-width: 2px;
    border-color: #333 #333 transparent transparent;
    border-style: solid;
    border-radius: 20px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    animation: around 0.7s ease-in-out infinite;
}

.loader::after {
    animation: around 0.7s ease-in-out 0.1s infinite;
    background: transparent;
}
.ongoingConsultation{
    height: 0px;
}
@media only screen and (max-width: 500px) {


.content {
   margin: 0px;
   margin-top: 70px;
    padding: 0px;
}
    /* For mobile phones: */
   .modal{ position: fixed;
    z-index: 2000;
    background-color: #dee3e7;
    width: 100%;
    height: 100%;
    border: 0px solid #1d1d1d;
    border-radius: 0px;
    padding: 20px;
    top: 0;
    left: 0;
    right: 0;
    max-height: 100%;
    overflow: scroll;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
    display: flex;
    flex-direction: column;}
    .main {
        margin-top: 5px;
        margin-left: 0px;
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: baseline;
        flex-direction: column;

    }

    .main_with_border{
        border-bottom: 0px grey solid;
        padding-bottom: 0px;
        margin-top: 0px;
        margin-left: 0px;
        display: flex;
        font-size: 15px;
        position: relative;
        align-items: flex-start;
        margin: 10px 0px;
        flex-direction: column;
    }

    .input {
        width: -webkit-fill-available;
        height: 18px;
        padding: 5px 10px;
        margin-right: 0px;
        border-radius: 3px;
        border: 1px solid #979797;
        font-size: 15px;
        color: black;
        background: #ffffff;
    }
    .symptomsText {
        flex: 1;
        font-size: 15px;
        margin: 2px 0;
        font-weight: bold;
        margin-right: 10px;
        color:#884a9d;
        font-family: 'HarmoniaSansStd-Bold';
    }
    .followuptext{
        width: 100%;
        font-size: 15px;
        font-weight: bold;
        font-family: 'HarmoniaSansStd-Bold';
        margin:5px 0px

    }
    .split_half{
        display: flex;
        width: 30px;
        justify-content: left;
    }
    .smallInput {
        width: 100px;
        padding: 10px;
        margin-right: 10px;
        border-radius: 3px;
        border: 1px solid #C8C8C8;
        font-size: 15px;
    }
    .smallInputBillingUnit {
        width: 100px;
        padding: 10px;
        margin-right: 10px;
        border-radius: 3px;
        border: 1px solid #C8C8C8;
        font-size: 15px;
    }
    .billingMain {
        flex: 1;
        margin-top: 20px;
        margin-left: 0px;
        display: inline-flex;
        align-items: center;
        flex-direction: row;
        position: relative;
        width: 100%;
        justify-content: space-between;
    }

.billingText {
    font-size: 15px;
    font-weight: 0;
    margin-right: 0px;
}

.billingCodeText {
    font-size: 15px;
    font-weight: 0;
    margin-left: 0px;
    margin-right: 0px;
}
.buttonView {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 150px;
    margin: 30px 0px;
    justify-content: space-between;
}
.radioText {
    width: 175px;
    font-size: 15px;
    font-weight: normal;
}
.listView {
    position: absolute;
    top: 50px;
    left: 0px;
    width: 250px;
    max-height: 320px;
    line-height: 20px;
    background-color: #ffffff;
    border: 1px solid #999999;
    visibility: visible;
    overflow-y: scroll;
    border-radius: 5px;
    z-index: 2;
}
.symptomCheckboxLabel{
    width: 70%;
    font-size: 12px;
    font-weight: normal;
    padding: 2px 10px;
    /* background-color: bisque; */
    border-radius: 3px;
    /* padding: 5px 10px; */
    margin-right: 0px;
    border-radius: 3px;
    border: 1px solid #979797;
    /* font-size: 15px; */
    color: #5F5F5F;
    background: #ffffff;
}
.symptomCheckbox{

}
.ongoingConsultation{
    width: 100%;
    left: 0;
    right: 0;
    height: auto;
    text-align: center;
    background-color: rgb(136, 74, 157);
    color: rgb(255, 255, 255);
    position: absolute;
    top: 67px;
    /* padding: 10px; */
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    align-items: center;

}
.canned_response_list {
    bottom: 0px;
    left: 0px;
    position: initial;
    z-index: 2000;
    box-shadow: 0 1px 10px 0 rgba(66,77,87,.3);
    background-color: #ffffff;
    border-radius: 4px;
    min-width: 168px;
    display: block;
}

}
