.main {
    width: 75%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-right: 1px solid rgb(221, 226, 230);
    overflow: auto;
    -webkit-font-smoothing: antialiased;
}

.label_style{
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    padding-top: 15px;
    color: rgba(0, 0, 0, 0.7);
    font-size: 18px;
}

.span_style{
    padding-right: 20px;
}

.main_2 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-right: 1px solid rgb(221, 226, 230);
    overflow:auto
}

.main_div{
    width: 100%;
    height: 100%;
    flex-direction: row;
    display: flex;
}

.chevron{
    position: absolute;
    top: 22%;
    left: 12px;
    width: 12px;
    height: 5px;
    transform: rotate(0deg) translateY(-50%);
    transform-origin: center top;
    transition: transform 0.2s ease-in-out 0s;
}

.group_details{
    display: inline-block;
    margin-left: 7%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    color: gray;
    font-size: 15px
}

.groups_member{
    margin: 0;
    padding: 0;
    border: 0;
    width:100%;
    flex-direction: row
}

.chevron_rotated{
    position: absolute;
    top: 50%;
    left: 6px;
    width: 12px;
    height: 8px;
    transform: rotate(-90deg) translateY(-50%);
    transform-origin: center top;
    transition: transform 0.2s ease-in-out 0s;
}

.icon_group_general{
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    text-align: center;
    background-color: rgb(208, 180, 84);
    vertical-align: middle;
    border-radius: 2px;
    margin-left: 18%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    color: white;
    font-size: 12px
}

.icon_group_free{
    position: relative;
    display: inline-block;
    width: 20px;
    height: 15px;
    text-align: center;
    background-color: green;
    vertical-align: middle;
    border-radius: 2px;
    margin-left: 18%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    color: white;
    font-size: 12px
}

.additional,.del_doctor,.force_logout, .activate_doctor{
    position: relative;
    display: flex;
    align-items: center;
    background-color: rgba(221, 226, 230, 0.5);
    font-size: 14px;
    line-height: 24px;
    color: rgb(66, 77, 87);
    user-select: none;
    cursor: pointer;
    margin-bottom: 1px;
    border-radius: 4px;
    width:70%;
    margin-left: 11%;
    padding: 4px 12px 4px 20px;
    margin-top: 5%
}
.force_logout{
    margin-top: 15% !important;
    color:white;
    padding:0px;
    text-align:center;
    display: block;
}
.del_doctor{
    background-color: #a70802;
    color:white;
    padding:0px;
    text-align:center;
    width:50%;
    flex-direction: column;
    margin-left: 0px;
}
.activate_doctor{
    background-color: #4384f5;
    color:white;
    padding:0px;
    text-align:center;
    width:50%;
    flex-direction: column;
    margin-left: 0px;
}
.profile_status{
    color:gray;
}
.detail_bottom_div{
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.additional_1{
    position: relative;
    display: inline-block;
    width: 40%;
    height: 20px;
    text-align: center;
    vertical-align: middle;
    border-radius: 2px;
    margin-left: 11%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    color:gray;
    font-size: 13px
}

.chat_count{
    position: relative;
    display: inline-block;
    width: 25%;
    margin-left: 0%;
    height: 20px;
    text-align: left;
    vertical-align: middle;
    border-radius: 2px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    color:gray;
    font-size: 13px;
}

.goal_count{
    position: relative;
    display: inline-block;
    width: 15%;
    text-align: left;
    vertical-align: middle;
    border-radius: 2px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    color:gray;
    font-size: 13px;
    margin-left: 2% 
}

.chat_satisfaction{
    position: relative;
    display: inline-block;
    width: 35%;
    text-align: left;
    vertical-align: middle;
    border-radius: 2px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    color:gray;
    font-size: 13px;
    margin-left: 2%;
}

.ticket_response_time{
    position: relative;
    display: inline-block;
    width: 45%;
    text-align: left;
    vertical-align: middle;
    border-radius: 2px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    color:gray;
    font-size: 13px;
    margin-left: 2%;
}

.chat_count_status{
    display: inline-block;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    color: black;
    font-size: 13px;
    text-align: left;
}

.goal_count_status{
    display: inline-block;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    color: black;
    font-size: 13px;
    text-align: left;
}

.chat_icon{
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-left:15%;
    text-align: center;
    vertical-align: middle;
    border-radius: 2px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    color:gray;
    font-size: 13px;
    margin-top: 2%;
}

.goal_icon{
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-left:15%;
    text-align: center;
    vertical-align: middle;
    border-radius: 2px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    color:gray;
    font-size: 13px;
}

.additional_2{
    position: relative;
    display: inline-block;
    width: 40%;
    height: 20px;
    text-align: center;
    vertical-align: middle;
    border-radius: 2px;
    margin-left: 10%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    color:gray;
    font-size: 13px;
}

.additional_3{
    position: relative;
    display: inline-block;
    width: 40%;
    height: 20px;
    text-align: center;
    vertical-align: middle;
    border-radius: 2px;
    margin-left: 9%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    color:gray;
    font-size: 13px;
}

.additional_status{
    display: inline-block;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    color: black;
    font-size: 13px;
    text-align: left;
}

.groups_info{
    position: relative;
    opacity: 1;
    transition: max-height 0.2s ease 0s, opacity 0.2s ease 0s;
}

.groups_category{
    padding: 10px 16px 2px;
}

.groups{
    position: relative;
    display: flex;
    align-items: center;
    background-color: rgba(221, 226, 230, 0.5);
    font-size: 14px;
    line-height: 24px;
    color: rgb(66, 77, 87);
    user-select: none;
    cursor: pointer;
    margin-bottom: 1px;
    border-radius: 4px;
    width:70%;
    margin-left: 11%;
    padding: 4px 12px 4px 20px;
}

.arrow{
    display: block;
    -webkit-box-align: center;
    align-items: center;
    width: 12px;
    height: 8px;
    line-height: 8px;
    margin-left: 15%;  
}

.profile{
    height: 20%;
    box-sizing: border-box;
    padding: 13px 16px 100px;
    overflow: auto;
}

.detail_header{
    height: 49px;
    border-bottom: 1px solid rgb(221, 226, 230);
    flex-direction: row;
}

.profile_info{
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.avatar_container{
    width: 25%;
}

.profile_avatar{
    vertical-align: left;
    background-size: cover;
    margin-left: 4%;
    width: 70px;
    height: 70px;
    border-width: 2px;
    border-style: solid;
    border-color: rgb(241, 246, 248);
    border-image: initial;
    border-radius: 50%;
}

.profile_avatar_view {
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.profile_schema{
    width:75%;
    flex-direction: column;
}

.chevron{
    flex-direction: row;
}

.profile_name{
    padding-left: 3%;
    padding-top: 1%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    font-weight: 800;
    color: gray;
    vertical-align: middle;
    flex-direction: row;
}

.doctor_category{
    display: block;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    color: gray;
    margin-bottom: 1px;
    margin-top: 2px;
    padding-left: 3%;
    font-size: 14px;
}

.edit_icon {
    display: inline-flex;
    margin-bottom: 1px;
    float: right;
    margin-right: 4px;
}

.doctor_email{
    display: block;
    padding-left: 3%;
    margin-top: 2px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    color: gray;
    margin-bottom: 1px;
}

.detail_info{
    flex-direction: column;
    position: relative;
    height: calc(100% - 54px);
}

.detail_text{
    font-size: 18px;
    font-weight: 400;
    color: rgb(66, 77, 87);
    height: 49px;
    line-height: 49px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    text-align: center;
    box-sizing: border-box;
    width: calc(100% - 160px);
    left: 0px;
    margin: 0px 80px;
    flex-direction: row;
}

.detail{
    width: 25%;
    height: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
}

.header {
    width: 100%;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgb(221, 226, 230);
    height: 52px;
    text-align: center;
    display: flex;
    font-size: 18px;
    font-weight: 400;
    color: rgb(66, 77, 87);
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
}

.body {
    width: 100%;
    height: 100%;
    align-items: center;
}

.main_body {
    height: 54px;
    padding: 0 20px;
    border-bottom: 1px solid rgb(221, 226, 230);
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0.3px;
    white-space: nowrap;
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
}

.heading {
    position: relative;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    height: 51px;
    color: rgb(66, 77, 87);
    cursor: pointer;
    font-weight: 700;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    background: none;
    outline: none;
}

.heading a{
    color: rgb(66, 77, 87);
    text-align: center;
    width: 100px;
    transition: color 0.25s ease 0s;
    text-decoration: none;
    outline: none !important;
}

.heading a:active,
.heading a.active{
    border-bottom: 5px solid#4384f5;
}

.table_view {
    width:100%;
    border-collapse: collapse;
    border-spacing: 0;
}

.table_view tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    border-bottom: 1px solid rgb(221, 226, 230);
    cursor: pointer;
}

.table_view tbody tr:hover {
    background-color: rgba(241, 246, 248, 0.5);
}

.table_view tr td:first-child {
    width: 110px;
    text-align: center;
    font-size: 15px;
    padding: 13px 10px;
}

.table_view td {
    position: relative;
    vertical-align: middle;
}

.table_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
}

.table_header {
    background-color: rgb(255, 255, 255);
    table-layout: fixed;
    width: 100%;
    
}

.table_content thead {
    display: table-header-group;
}

.table_content thead tr th:first-child {
    width: 110px;
    text-align: center;
}

.table_content thead tr th {
    height: 35px;
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.3);
    text-transform: uppercase;
    position: relative;
    vertical-align: middle;
    text-align: left;
    padding: 0px 10px;
}

.table_heading {
    position: relative;
    display: inline-block;
    color: rgba(66, 77, 87, 0.8);
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    user-select: none;
    transition: padding 0.1s linear 0s;
}

.list_view {
    height: 100%;
    overflow: auto;
}

.table_view {
    position: relative;
    width: 100%;
    table-layout: fixed;
}

.table_header {
    position: relative;
    display: inline-block;
    color: rgba(66, 77, 87, 0.8);
    cursor: pointer;
    user-select: none;
    padding-left: 18px;
    transition: padding 0.1s linear 0s;
}

.table_view{
    width: 100%;
    line-height: 1.4;
}

.tr_view{
    position: relative;
    cursor: pointer;
    transition: background-color 0.25s ease 0s;
}

.selectable {
    position: relative;
    cursor: pointer;
}

.selected {
    background-color: rgb(241, 246, 248);
}

.item {
    background-color: #ffffff;
}

.content {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 44px;
    line-height: 44px;
    background-color: rgb(244, 247, 249);
    box-sizing: border-box;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    border-width: 2px;
    border-style: dashed;
    border-color: rgb(196, 206, 214);
    border-image: initial;
}

.plusIcon {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    width: 14px;
    height: 14px;
    line-height: 14px;
}

.selected_mark {
    width: 4px;
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    display: block;
    content: "";
    background-color: rgb(67, 132, 245);
    transition: width 0.25s ease 0s;
}

.icon_image {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 40px;
    height: 40px;
    border-width: 2px;
    border-style: solid;
    border-color: rgb(241, 246, 248);
    border-image: initial;
    border-radius: 50%;
}

.online_image_style {
    display: block;
    background-size: cover;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 1000px;
}

.offline_image_style {
    display: block;
    background-size: cover;
    background-image: url('../../assets/offline.jpeg');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 1000px;
}
    
.online_style {
    background-color: rgb(70, 183, 118);
    border-width: 2px;
    border-style: solid;
    border-color: rgb(241, 246, 248);
    border-image: initial;
    position: absolute;
    display: block;
    box-sizing: border-box;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    right: -2px;
    top: -2px;   
}

.offline_style {
    background-color: (241, 246, 248, 0.5);
    border-width: 2px;
    border-style: solid;
    border-color: rgb(241, 246, 248);
    border-image: initial;
    position: absolute;
    display: block;
    box-sizing: border-box;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    right: -2px;
    top: -2px; 
}

.offline_style {
    background-color: rgb(169, 174, 184);
    border-width: 2px;
    border-style: solid;
    border-color: rgb(241, 246, 248);
    border-image: initial;
    position: absolute;
    display: block;
    box-sizing: border-box;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    right: -2px;
    top: -2px;
    
}

.role {
    display: inline-block;
    height: 21px;
    width: fit-content;
    line-height: 20px;
    color: rgb(255, 255, 255);
    font-size: 13px;
    vertical-align: middle;
    margin-left: 15px;
    border-radius: 20px;
    padding: 0px 7px;
}

.admin_role {
    background-color: rgb(109, 91, 186);
    display: inline-block;
    height: 21px;
    width: fit-content;
    line-height: 20px;
    color: rgb(255, 255, 255);
    font-size: 13px;
    vertical-align: middle;
    margin-left: 15px;
    border-radius: 20px;
    padding: 0px 7px;
}

.admin_role_dt {
    background-color: rgb(109, 91, 186);
    height: 21px;
    width: fit-content;
    line-height: 20px;
    color: rgb(255, 255, 255);
    font-size: 13px;
    vertical-align: middle;
    border-radius: 20px;
    padding: 0px 7px;
    float: right;
}

.doctor_role {
    background-color: rgb(245, 89, 35);
    display: inline-block;
    height: 21px;
    width: fit-content;
    line-height: 20px;
    color: rgb(255, 255, 255);
    font-size: 13px;
    vertical-align: middle;
    margin-left: 15px;
    border-radius: 20px;
    padding: 0px 7px;
}

.doctor_role_dt {
    background-color: rgb(245, 89, 35);
    height: 21px;
    width: fit-content;
    line-height: 20px;
    color: rgb(255, 255, 255);
    font-size: 13px;
    vertical-align: middle;
    border-radius: 20px;
    padding: 0px 7px;
    float: right;
}

.clinic_doctor_role {
    background-image: -webkit-linear-gradient(left,#85479f -25%,#d36797 125%)!important;
    display: inline-block;
    height: 21px;
    width: fit-content;
    line-height: 20px;
    color: rgb(255, 255, 255);
    font-size: 13px;
    vertical-align: middle;
    border-radius: 20px;
    padding: 0px 7px;
}

.clinic_doctor_role_dt {
    background-image: -webkit-linear-gradient(left,#85479f -25%,#d36797 125%)!important;
    height: 21px;
    width: fit-content;
    line-height: 20px;
    color: rgb(255, 255, 255);
    font-size: 13px;
    vertical-align: middle;
    border-radius: 20px;
    padding: 0px 7px;
    float: right;
}


.doctor_content {
    margin-bottom: 1px;
    color: rgb(66, 77, 87);
    white-space: nowrap;
    text-overflow: ellipsis;
}

.doctor_email {
    font-size: 15px;
    color: rgba(66, 77, 87, 0.6);
    text-overflow: ellipsis;
    white-space: nowrap;
}

.invite {
    font-size: 16px;
    color: rgb(67, 132, 245);
}

.Auth {
    width: 100%;
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    padding: 10px;
}

.cross {
    flex: 1;
    flex-direction: row;
    color: rgba(0, 0, 0, 0.7);
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    background-color: rgb(255, 255, 255);
    box-sizing: border-box;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 10px;
    border-bottom: 1px solid rgb(221, 226, 230);
}

.add {
    text-align: center;
}

.close {
    float: right;
}

.error {
    font-size: 13px;
    color: #d60b59;
}
.hidden { 
    display:none; 
}
.success_message_div{
    display: flex;
    justify-content: center;
}
.success_message{
    padding-top: 25%;
    font-family: 'HarmoniaSansStd-Bold';
    font-weight: 600;
    color: #d60b59;
    font-size: 18px;
}



